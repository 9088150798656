import { appendUrlParams } from "utils/urlUtils";

export const GJS_PAGE_BUILDER_ROUTE_KEYS = {
  update: "gjs-sales-page-builder-update",
};

export const GJS_PAGE_BUILDER_ROUTE_IDS = {
  [GJS_PAGE_BUILDER_ROUTE_KEYS.update]:
    "/gjs-sales-page-builder/:salePageUuid/update",
};

export const query_params = {
  template_uuid: "template",
};

export const GJS_PAGE_BUILDER_ROUTE_GENERATORS = {
  [GJS_PAGE_BUILDER_ROUTE_KEYS.update]: ({ salePageUuid, templateUuid }) =>
    appendUrlParams(`/gjs-sales-page-builder/${salePageUuid}/update`, {
      [query_params.template_uuid]: templateUuid,
    }),
};

/**
 * actions that the caqn be triggered from the page builder
 */
export const page_builder_action_keys = {
  listener_added: "listener-added",
  start_init: "start-init",
  save: "save",
  save_draft: "save-draft",
  publish: "publish",
  upload_images: "upload-images",
  image_uploaded: "image-uploaded",
};

export const sales_page_builder_origin =
  process.env.REACT_APP_PG_BUILDER_ENDPOINT;
