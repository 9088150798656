import React, { FC } from "react";
import LazyLoadWrapper from "../../../../common/LazyLoadWrapper";
import { IPostShareModal } from "./PostShareModal.interfaces";

const PostShareModalBase = React.lazy(() => import("./PostShareModalBase"));

export const PostShareModal: FC<IPostShareModal> = (props) => {
  const { open } = props;

  return (
    <LazyLoadWrapper loadingCondition={open}>
      <PostShareModalBase {...props} />
    </LazyLoadWrapper>
  );
};
