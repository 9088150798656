import * as Sentry from "@sentry/react";

/**
```Typescript
{
    error?: any;
    extraErrorData?: any;
    occuredAt?: string;
    when?: string;
    severity?: "low" | "medium" | "critical" | "blocker";
    customErrorMessage?: string;
    logToPlatforms?: Array<IConsoleItem | ISentryItem>;
}
type ISentryItem = ["sentry"] | ["sentry", { forceLogStacktrace?: boolean }];
type IConsoleItem = ["console"];
```
*/
export const logError = async ({
  error,
  extraErrorData = undefined,
  occuredAt,
  when,
  severity = "medium",
  customErrorMessage = undefined,
  logToPlatforms = [],
}) => {
  const customError = customErrorMessage || {
    error,
    occuredAt,
    when,
    severity,
    extraErrorData,
  };

  let loggedToConsole = false;

  logToPlatforms.forEach(([platform, platformOptions]) => {
    if (platform === "console") {
      // eslint-disable-next-line no-console
      console.log(customError);
      loggedToConsole = true;
    }
    if (platform === "sentry") {
      // do we want to log the stacktrace instead of logging the custom object?
      // https://docs.sentry.io/platforms/javascript/guides/nextjs/usage/#capturing-errors
      if (error instanceof Error && platformOptions?.forceLogStacktrace) {
        Sentry.captureException(error);
      } else {
        // convert the customError object to a string and then to an error object
        try {
          Sentry.captureMessage(JSON.stringify(customError));
        } catch (e) {
          // if we can't convert the customError object to a string

          // then log it to console
          // eslint-disable-next-line no-console
          console.log(e);

          // if we are in development mode, then throw the error
          if (process.env.NODE_ENV !== "production") throw e;
        }
      }
    }
  });

  if (!loggedToConsole && process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log(customError);
  }
};
