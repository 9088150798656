import React, { useEffect, useState } from "react";
import { is_empty } from "../../../../../../utils/validations";
import {
  RichTextEditor,
  RichTextEditorUtils,
  Input,
  Button,
} from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useNotifications } from "utils/hooks";
import { useSelector, useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";
import OfferingSelect from "common/Components/OfferingSelect";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./styles";
import {
  prepareListingsData,
  dispatchListingData,
  handleSave,
  handleDelete,
  isNotFetching,
} from "./../helpers";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { labels, constants } from "../../constants";
import moment from "moment";
import globalConstants from "constants/constants";
import { uploadToS3 } from "features/Common/modules/S3/utils/S3.utils";
import { logError } from "utils/error";

const FaqEditor = (props) => {
  const {
    selectedRecordData,
    refetch,
    showDelete = false,
    isModal = false,
    setShowUpload = false,
    setOpenAccordion,
  } = props;
  const dispatch = useDispatch();
  const { pushNotification } = useNotifications();

  const isDesktop = useDesktopMediaQuery();
  const css = useStyles({ isDesktop, isModal });

  const {
    getEditorStateFromHtml,
    getHtmlFromEditorState,
    emptyEditorState,
    emptyHtmlWithListStyle,
    emptyHtml,
  } = RichTextEditorUtils;

  // on lazy load, limit per render
  const loadItemsDesktop = 12;
  const loadItemMobile = 6;
  const loadListLength = isDesktop ? loadItemsDesktop : loadItemMobile;

  const fetchedListings = useSelector((state) => state.schedule);

  const [editorState, setEditorState] = useState(emptyEditorState);
  const [offering, setOffering] = useState(null);
  const [question, setQuestion] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [masterList, setMasterList] = useState([]);
  const [listing, setListing] = useState([]);
  const [canSave, setCanSave] = useState(isModal);

  useEffect(() => {
    (async () => {
      setEditorState(getEditorStateFromHtml(selectedRecordData?.answer));
      setQuestion(selectedRecordData?.question);
      setOffering(
        is_empty(selectedRecordData?.listing__uuid)
          ? labels.ALL_OFFERINGS
          : selectedRecordData?.listing__uuid
      );
    })();

    return () => setMasterList([]);
  }, []);

  const {
    data: offeringOptions,
    loading,
    setLoading,
    setError,
    setHasMore,
    pageNumber,
    setData,
    lastElementRef,
  } = useInfiniteSearch();

  useEffect(() => {
    const _isNotFetching = isNotFetching(fetchedListings);
    if (_isNotFetching) {
      const allListings = prepareListingsData(
        fetchedListings,
        selectedRecordData?.listing__uuid
      );
      setMasterList(allListings);
    }
  }, [fetchedListings]);

  useEffect(() => {
    const _isNotFetching = isNotFetching(fetchedListings);
    if (_isNotFetching) {
      setLoading(true);
      setListing(masterList.slice(0, listing.length + loadListLength));
      if (masterList.length > listing.length) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [masterList, pageNumber]);

  useEffect(() => {
    setData([constants.all_offering_option, ...listing]);
    setError(false);
    setLoading(false);
  }, [listing, setData, setLoading, setError]);

  useEffect(() => {
    dispatchListingData(fetchedListings, dispatch);
  }, []);

  const handleDeleteModal = () => setOpenDelete(!openDelete);

  if (loading && is_empty(offeringOptions))
    return (
      <div className={css.loader}>
        <ExlyLoader />
      </div>
    );

  let actionButtons = [];

  if (showDelete)
    actionButtons.push(
      <IconButton onClick={handleDeleteModal} key="delete">
        <Delete />
      </IconButton>
    );

  actionButtons.push(
    <React.Fragment key="save">
      <Button
        color="primary"
        onClick={() =>
          handleSave({
            question,
            offering,
            getHtmlFromEditorState,
            editorState,
            selectedRecordData,
            pushNotification,
            refetch,
            isModal,
            setShowUpload,
            emptyHtmlWithListStyle,
            emptyHtml,
          })
        }
        fullWidth={isModal && !isDesktop}
        disabled={!canSave}
      >
        {isModal ? labels.ADD_FAQS : labels.SAVE_FAQ}
      </Button>
    </React.Fragment>
  );

  if (is_empty(offeringOptions))
    return (
      <div className={css.loader}>
        <ExlyLoader />
      </div>
    );

  const notModalEnableSave = () => !isModal && setCanSave(true);

  const onChangeOfferingSelect = (val) => {
    notModalEnableSave();
    setOffering(val);
  };

  const onChangeQuestion = (e) => {
    notModalEnableSave();
    setQuestion(e.target.value);
  };

  const onChangeEditor = (val) => {
    notModalEnableSave();
    setEditorState(val);
  };

  const handleFileUpload = async (file) => {
    try {
      const fileName = file.name;
      let key = `faq-attachments/${moment().unix()}/${fileName}`;
      const fileData = await uploadToS3({
        file,
        key,
        bucket: globalConstants.AWS_ATTACHMENT_BUCKET,
      });
      return fileData.Location;
    } catch (err) {
      logError({
        error: err,
        when: "handleFileUpload",
        occuredAt: "FaqEditor.js",
      });
    }
  };

  return (
    <div className={css.faqEditorBody}>
      <div>
        {!is_empty(offeringOptions) && (
          <OfferingSelect
            value={offering}
            placeholder="Select the listing to make the creative"
            onChange={onChangeOfferingSelect}
            options={offeringOptions}
            loading={loading}
            showStatus={true}
            lastElementRef={lastElementRef}
            mobileModalTitle="Select Offerings*"
            label="Select Offerings*"
          />
        )}
        <Input
          label="Question*"
          size="small"
          fullWidth={true}
          value={question}
          onChange={onChangeQuestion}
        />
        <div className={css.faqResizable}>
          <label className={css.label}>Answer*</label>
          <RichTextEditor
            modalTitle="Description"
            editorState={editorState}
            onEditorStateChange={onChangeEditor}
            placeholder="Write Something here..."
            toolbarPosition="top"
            minHeight={isDesktop ? "200px" : "240px"}
            imageUploadCallback={handleFileUpload}
          />
        </div>
        <div className={css.actionButtons}>{actionButtons}</div>
      </div>
      <ExlyDeleteModal
        title="Delete FAQ"
        deleteBtnText="Confirm"
        description="Are you sure you want to delete the FAQ?"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onDelete={() =>
          handleDelete({
            selectedRecordData,
            setOpenDelete,
            refetch,
            pushNotification,
            setOpenAccordion,
          })
        }
        onSecondaryBtnClick={() => setOpenDelete(false)}
        padded={false}
      />
    </div>
  );
};

export default FaqEditor;
