export {
  BRANDED_COMMUNITY_POST_CREATION_KEYS,
  POST_SHARING_TYPES,
} from "@my-scoot/exly-react-component-lib/build/esm/features/BrandedCommunity/constants/BrandedCommunity.constants";

export const BRANDED_COMMUNITY_APIS = {
  reorder_bonus_offerings: "community/reorder/elements",
  get_communities: "community/paginated/list",
  get_community_channels: "community/feed/channel/paginated/list",
};

export const BRANDED_COMMUNITY_REORDER_TYPE = {
  COMMUNITY_GROUP: 1,
  COMMUNITY_OFFERINGS: 2,
};

export const BRANDED_COMMUNITY_QUERY_PARAMS = {
  selected_section: "section",
  selected_channel_uuid: "channel",
  selected_custom_link_uuid: "cl",
  selected_post_uuid: "post",
  selected_comment: "comment",
  selected_comment_reply: "reply",
  selected_message_created_at: "msg_created_at",
};
