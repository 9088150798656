import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect, useState } from 'react';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import useStyles from './RichTextEditorModal.styles';

import { useMediaQuery } from '@material-ui/core';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import Button from '../../atoms/Button/Button';
import { IRTEVariablesModal } from '../../atoms/RichTextEditorV2/modules/RTEVariablesModal/RTEVariablesModal.interfaces';
import RichTextEditorV2, {
  RTEProps,
} from '../../atoms/RichTextEditorV2/RichTextEditorV2';
import RichTextEditorV2Utils from '../../atoms/RichTextEditorV2/RichTextEditorV2.utils';
import Modal from '../Modal/Modal';
import { Descendant } from 'slate';

const { getHtmlFromEditorState, emptyEditorState, isEmpty } =
  RichTextEditorV2Utils;

interface IRichTextEditorModal extends RTEProps {
  placeholder: string;
  placeholderWrapperClassName?: string;
  placeholderBoxClassName?: string;
  placeholderClassName?: string;
  fullScreen?: boolean;
  primaryBtnText: string;
  secondaryBtnText?: string;
  modalTitle: string;
  modalProps?: any;
  CustomFooter?: any;
  showToolbar: boolean;
  showSaveButton: boolean;
  showChipsOnReadOnly: boolean;
  showRte: boolean;
  removeStylesOnPaste: boolean;
  autoFocus?: boolean;
  variablesProps?: Partial<IRTEVariablesModal>;
  onSave?: (args: {
    onEditorStateChange: (value: Descendant[]) => void;
    setOpen: (value: boolean) => void;
    state: Descendant[];
  }) => void;
}

const RichTextEditorModal: FC<IRichTextEditorModal> = ({
  placeholderBoxClassName,
  placeholderClassName,
  fullScreen,
  primaryBtnText,
  secondaryBtnText,
  modalTitle,
  isSmallToolbar = false,
  editorState,
  onEditorStateChange,
  placeholderWrapperClassName,
  modalProps = {},
  CustomFooter,
  showToolbar = true,
  showSaveButton = true,
  showChipsOnReadOnly = false,
  showRte = false,
  removeStylesOnPaste,
  autoFocus,
  onSave: onSaveProp,
  ...restProps
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const classes = useStyles({ fullScreen, showToolbar });

  const [open, setOpen] = useState<boolean>(false);
  const [modalEditorState, setModalEditorState] = useState(editorState);

  const onOpen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setModalEditorState(editorState);
  };

  useEffect(() => {
    setModalEditorState(editorState);
  }, [editorState]);

  const onSave = () => {
    const newState = modalEditorState || emptyEditorState;

    if (onSaveProp) {
      onSaveProp({ onEditorStateChange, setOpen, state: newState });
    } else {
      onEditorStateChange?.(newState);
      setOpen(false);
    }
  };

  const htmlValue = getHtmlFromEditorState(editorState || emptyEditorState);

  const fullScreenHeader = (
    <Box className={classes.fullScreenHeader}>
      <Box className={classes.headerLeftSection}>
        <IconButton
          disableRipple
          disableFocusRipple
          component="span"
          className={classes.backArrow}
          onClick={onClose}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <Typography variant="h5" className={classes.modalTitle}>
          {modalTitle}
        </Typography>
      </Box>
      {showSaveButton && (
        <Button
          size="thin"
          color="primary"
          onClick={onSave}
          className={classes.mobileSaveBtn}
        >
          {primaryBtnText}
        </Button>
      )}
    </Box>
  );

  const RTEProps = {
    customToolbarClassName: classes.customToolbar,
    editorState: modalEditorState,
    onEditorStateChange: setModalEditorState,
    showBorder: false,
    customEditorClassName: classes.editor,
    editableWrapperClassName: classes.editable,
    showToolbar: showToolbar,
    showChipsOnReadOnly: showChipsOnReadOnly,
    removeStylesOnPaste: removeStylesOnPaste,
    autoFocus: autoFocus,
  };

  const BoxRTEProps = {
    customToolbarClassName: classes.customToolbar,
    editorState: modalEditorState,
    onEditorStateChange: setModalEditorState,
    showBorder: false,
    customEditorClassName: classes.editor,
    editableWrapperClassName: classes.editable,
    showToolbar: false,
    showChipsOnReadOnly: showChipsOnReadOnly,
    isBoxDisabled: true,
  };

  return (
    <>
      <Box
        className={classnames(
          classes.placeholderWrapper,
          placeholderWrapperClassName
        )}
      >
        {showRte ? (
          <Box
            onClick={onOpen}
            className={[
              classes.showcaseContainer,
              fullScreen ? classes.mobileBox : classes.desktopBox,
              placeholderBoxClassName,
            ].join(' ')}
          >
            <RichTextEditorV2
              {...BoxRTEProps}
              height={`130px`}
              {...restProps}
            />
          </Box>
        ) : (
          <Box
            onClick={onOpen}
            className={[
              classes.showcaseContainer,
              fullScreen ? classes.mobileBox : classes.desktopBox,
              placeholderBoxClassName,
            ].join(' ')}
            dangerouslySetInnerHTML={{
              __html: isEmpty(htmlValue)
                ? `<p class=${`${classes.placeholder} ${placeholderClassName}`}>${
                    restProps.placeholder
                  }</p>`
                : htmlValue,
            }}
          />
        )}
      </Box>
      <Modal
        title={modalTitle}
        primaryBtnText={primaryBtnText}
        secondaryBtnText={secondaryBtnText}
        open={open && isDesktop}
        onClickCross={onClose}
        onPrimaryBtnClick={onSave}
        onSecondaryBtnClick={onClose}
        footerBtnsPosition="flex-end"
        fullScreen={fullScreen}
        customHeader={fullScreen && fullScreenHeader}
        customFooter={
          CustomFooter ? (
            <CustomFooter onSave={onSave} {...restProps} />
          ) : (
            fullScreen && <></>
          )
        }
        backDropClickClose
        enableContentOverflow={true}
        modalPaperClassName={
          fullScreen ? classes.mobileModalPaper : classes.desktopModalPaper
        }
        {...modalProps}
      >
        {open ? (
          <RichTextEditorV2
            {...RTEProps}
            minHeight={fullScreen ? `calc(100vh - 60.8px - 41.5px)` : '250px'}
            {...restProps}
          />
        ) : null}
      </Modal>

      {open && !isDesktop && (
        <>
          <Box
            className={classnames(
              classes.mobileRoot,
              fullScreen && classes.mobileModalPaper
            )}
          >
            {fullScreenHeader}
            <RichTextEditorV2
              {...RTEProps}
              rootWrapperClassName={classes.mobileRootWrapper}
              minHeight={!showToolbar ? '50vh' : 'calc(100vh - 60.8px - 190px)'}
              {...restProps}
            />
            {CustomFooter && <CustomFooter onSave={onSave} {...restProps} />}
          </Box>
        </>
      )}
    </>
  );
};

export default withGenerateClassName(RichTextEditorModal);
