// using in hostsite and mobile app
export const BRANDED_COMMUNITY_REPORTING_API_KEYS = {
  uuid: "uuid",
  type: "type",
  reason: "reason",
};

// using in hostsite and mobile app
export const BRANDED_COMMUNITY_REPORT_CONTENT_TYPES = {
  HOME_FEED_POST: 1,
  HOME_FEED_POST_COMMENT: 2,
  CHANNEL_POST: 3,
  CHANNEL_POST_COMMENT: 4,
  DIRECT_CHAT: 5,
  CHANNEL_CHAT: 6,
};

// using in hostsite and mobile app
export const BRANDED_COMMUNITY_APIS = {
  report: "host/content/reporting",
};

// using in hostsite and creator tool
export const BRANDED_COMMUNITY_POST_CREATION_KEYS = {
  community_listing_uuid: "community_listing_uuid",
  post_type: "post_type",
  post_heading: "post_heading",
  post_body: "post_body",
  is_published: "is_published",
  metadata: "metadata",
  poll_data: "poll_data",
  question: "question",
  description: "description",
  question_type: "question_type",
  expiry_date: "expiry_date",
  allow_vote_change: "allow_vote_change",
  options: "options",
  option: "option",
  option_uuid: "option_uuid",
  sequence_no: "sequence_no",
  change_type: "change_type",
};

// using in branded community
export const CHANGE_STATES = { unchanged: 0, updated: 1, added: 2, deleted: 3 };

// using in all projects
export const POST_SHARING_TYPES = {
  PUBLIC: 1,
  WITHIN_COMMUNITY: 2,
  DO_NOT_SHARE: 3,
};
