import { RESOURCE_KEYS } from "utils/OrgPermissions";
import { permissionLabels } from "./Org.constants";

const permissionsConfig = [
  {
    source: RESOURCE_KEYS.SECTIONS.DASHBOARD,
    label: "Dashboard",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MANAGE_WEBPAGE,
    label: "Manage Webpage",
    isFeature: false,
    features: [
      {
        source: RESOURCE_KEYS.SUBSECTIONS.MANAGE_WEBPAGE.WEBPAGE_DETAILS,
        label: "Webpage Details",
      },
    ],
  },
  {
    source: RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS,
    label: "Create/Edit Offerings",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MY_BOOKINGS,
    label: "Bookings",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.LEADS,
    label: "My Leads ",
    features: [
      {
        source: RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS,
        label: "Webpage Leads",
      },
      {
        source: RESOURCE_KEYS.SUBSECTIONS.LEADS.AD_LEADS,
        label: "Ad Leads",
      },
    ],
  },
  {
    source: RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS,
    label: "All Customers",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.INTEGRATIONS,
    label: "Integrations",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.FEEDBACKS,
    label: "Feedback",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.AUTOMATED_MESSAGES,
    label: "Communications",
    tooltip: permissionLabels.communication_tooltip,
    features: [
      {
        label: "Customer Invoices",
        source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.CUSTOMER_COMM_INVOICES,
      },
      {
        label: "Customer Purchase Confirmation",
        source:
          RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS
            .CUSTOMER_COMM_PURCHASE_CONFIRMATION,
      },
      {
        label: "Customer Session Reminders",
        source:
          RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.CUSTOMER_COMM_REMINDERS,
      },
      {
        label: "Customer Updates",
        source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.CUSTOMER_COMM_UPDATES,
      },
      {
        label: "Weekly Summary Reports",
        source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.COMM_REPORTS,
      },
      {
        label: "Creator Purchase Confirmation",
        source:
          RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS
            .CREATOR_COMM_PURCHASE_CONFIRMATION,
      },
      {
        label: "Creator Updates",
        source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.CREATOR_COMM_UPDATES,
      },
      {
        label: "Creator Reminders",
        source: RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.CREATOR_COMM_REMINDERS,
      },
    ],
  },
  {
    source: RESOURCE_KEYS.SECTIONS.PAYMENT_LINKS,
    label: "Custom Payment Links",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.TRAFFIC_ANALYSIS,
    label: "Traffic Analysis",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.DISCOUNT_CODES,
    label: "Discount Codes",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.LIMITED_TIME_OFFER,
    label: "Limited-Time Offers",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    label: "Manage Transactions",
    features: [
      {
        source: RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
        label: "All Transactions",
      },
      {
        source:
          RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.MANAGE_SUBSCRIPTION,
        label: "Manage Subscriptions",
      },
      {
        source: RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.REFUND_HISTORY,
        label: "Refund History",
      },
    ],
  },
  {
    source: RESOURCE_KEYS.SECTIONS.EXPORT_DATA_CSC,
    label: "Download/Export data",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MY_SCHEDULE,
    label: "My Schedule",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.ATTENDANCE,
    label: "Attendance",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.CERTIFICATES,
    label: "Ceritificates",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.REPORTS,
    label: "Generate Reports",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.BLOGS,
    label: "Blog",
    isFeature: true,
    tooltip: permissionLabels.blog_tooltip,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY,
    label: "Media Library",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.MEDIA_CREATIVES,
    label: "Marketing Creatives",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS,
    label: "Email Campaigns",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS,
    label: "WhatsApp Broadcast",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.SEGMENTATION,
    label: "Segments",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE,
    label: "Zoom Attendance",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.SEO,
    label: "SEO & Accessibility",
    isFeature: true,
  },
  {
    source: RESOURCE_KEYS.SECTIONS.EXLY_PLUS,
    label: "Exly Plus",
    features: [
      {
        source: RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.REFER_A_FRIEND,
        label: "Refer A Friend",
      },
      {
        source: RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.USER_REFERRALS,
        label: "User Referrals",
      },
    ],
  },
];

export default permissionsConfig;
