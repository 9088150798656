import React, { Suspense } from "react";
import { checkIsArrayEmpty } from "../../../utils/common/dataTypes/array";
import { IPostImageProps } from "./PostImage.types";

const PostImage = React.lazy(() => import("./PostImage"));

const LazyLoadedPostImage = (props: IPostImageProps) => {
  const { showImages, images } = props;
  if (!images || checkIsArrayEmpty(images) || !showImages) return null;
  return (
    <Suspense fallback={null}>
      <PostImage {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostImage;
