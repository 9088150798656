import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  title: {
    flex: 2,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  playIcon: {
    margin: "0 20px 0 12px",
    cursor: "pointer",
    width: "20px",
    "& svg": {
      fontSize: 32,
      color: "#6c5dd3",
    },
  },
  feedback_color: {
    color: "#6c5dd3",
    fontSize: 16,
    margin: "0 20px 0 0",
  },
  notification_color: {
    color: "#6c5dd3",
    fontSize: 16,
    cursor: "pointer",
  },
  reminderIcon: {
    width: "28px",
    height: "28px",
  },
  notification_wrapper: {
    position: "relative",
    marginRight: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  redDot: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    fontSize: "13px",
    width: "19.2px",
    height: "19.2px",
    borderRadius: "50%",
    display: "block",
    float: "right",
    position: "absolute",
    right: "0",
    top: "0",
  },
  redDotMobile: {
    backgroundColor: "red",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  notificationcon: {
    display: "flex",
    margin: "0 20px 0 0",
  },
  desktopAppBar: {
    "&>div": {
      paddingRight: "16px",
    },
  },
  menuButton: {
    marginLeft: "4px",
    marginRight: 0,
  },
  mobileAppBar: {
    backgroundColor: "#4A3AB1",
    border: "none",
    height: "64px",
    color: "#fff",
    "&>div": {
      paddingRight: "14px",
      minHeight: "64px",
    },
  },
  mobileLogoWrapper: {
    position: "absolute",
    left: "72px",
    top: 0,
    bottom: 0,
    height: "64px",
    display: "flex",
    alignItems: "center",
  },
  desktopLogoWrapper: {
    position: "absolute",
    left: "52px",
    height: "64px",
    display: "flex",
    alignItems: "center",
  },
});
