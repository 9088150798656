import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@my-scoot/component-library-legacy";
import DvrIcon from "@material-ui/icons/Dvr";
import styles from "./CheckoutPageListing.module.css";
import {
  EXISTING_PAYMENT_LINKS_COLUMN_CONFIG,
  EXISTING_PAYMENT_LINKS_FILTER_CONFIG,
  EXISTING_PAYMENT_LINKS_IMAGE_ASSETS,
} from "features/PaymentLinks/modules/ExistingPaymentLinks/constants/ExistingPaymentLinks.constants";
import LinkField from "features/PaymentLinks/modules/ExistingPaymentLinks/components/LinkField/LinkField";
import Actions from "features/PaymentLinks/modules/ExistingPaymentLinks/components/Actions/Actions";
import StatusField from "features/PaymentLinks/modules/ExistingPaymentLinks/components/StatusField/StatusField";
import ExpireModal from "ui/pages/payments/DynamicLinkList/components/ExpireModal";
import CreatePaymentLinkModal from "features/PaymentLinks/modules/CreatePaymentLinkModal/CreatePaymentLinkModal";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { useRefresh } from "react-admin";
import { is_empty } from "features/Common/utils/common.utils";
import { dataProvider } from "data";
import { PAYMENT_LINK_APIS } from "features/PaymentLinks/constants/PaymentLinks.apis";
import { getUserTimezone } from "utils/AuthUtil";
import moment from "moment";
import {
  PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS,
  PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS,
} from "features/PaymentLinks/modules/PaymentLinksTransactions/constants/PaymentLinksTransactions.constants";
import { existingPaymentLinksFilterChipsFormatter } from "features/PaymentLinks/modules/ExistingPaymentLinks/utils/ExistingPaymentLinks.utils";
import EmptyListIcon from "assets/vectors/infoModal/Custom_Payment.svg";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import {
  CHECKOUT_PAGE_TYPE_OPTIONS,
  checkout_update_route_key,
  CREATE_CHECKOUT_LINK_MODAL_TITLES,
} from "./CheckoutPageListing.constants";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import { navigateTo } from "utils/urlUtils";

const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No checkout pages found!</div>
    </div>
  );
};

export const CheckoutPageListing = withComponentLibraryTheme((props) => {
  const { notify } = useNotifications();
  const refetch = useRefresh();
  const history = useHistory();
  const [showExpire, setShowExpire] = React.useState(false);
  const [isExpiringLink, startLinkExpire, stopLinkExpire] =
    useToggleState(false);
  const [selectedRecordData, setSelectedRecordData] = React.useState(null);
  const [editPaymentLinkModal, setEditPaymentLinkModal] = React.useState();
  const [openCreatePaymentLinkModal, setOpenCreatePaymentModal] =
    React.useState(false);

  const handleExpireAction = (record) => {
    setSelectedRecordData(record);
    setShowExpire(true);
  };

  const handleExpire = async (item) => {
    try {
      if (is_empty(item.dynamic_link_id)) return;
      startLinkExpire();
      const status = await dataProvider.custom_request(
        PAYMENT_LINK_APIS.update_payment_link,
        apiMethods.POST,
        {
          dynamic_link_id: item.dynamic_link_id,
          updated_values: {
            expiry_date: moment().tz(getUserTimezone()).toISOString(),
          },
        }
      );
      notify(status.message, notification_color_keys.info);
      if (isRequestSuccessful(status?.status)) {
        setShowExpire(false);
        refetch();
      }
    } catch (err) {
      logError({
        error: err,
        occuredAt:
          "src/features/CheckoutPages/modules/CheckoutPageListing/CheckoutPageListing.jsx",
        when: "handle expire function",
      });
      notify("Some error occured", notification_color_keys.error);
    } finally {
      stopLinkExpire();
    }
  };

  const handleCloseCreatePaymentLinkModal = () => {
    setOpenCreatePaymentModal(false);
  };

  const handleCreatePaymentLinkModal = () => {
    setOpenCreatePaymentModal(true);
  };

  const handleEditPaymentLink = (record) => {
    const handleClose = () => {
      setEditPaymentLinkModal();
    };

    setEditPaymentLinkModal(
      <CreatePaymentLinkModal
        record={record}
        open
        onClose={handleClose}
        createModalTitles={CREATE_CHECKOUT_LINK_MODAL_TITLES}
        linkTypeOptions={CHECKOUT_PAGE_TYPE_OPTIONS}
        editingModalTitle="Page Settings"
        modalConfig={{
          title: "Choose page type",
          pageLabel: "Page Name",
          priceLabel: "Page Price",
        }}
        isCheckoutPage
      />
    );
  };

  const handleRedirectToTransactions = () => {
    history.push(
      `/${
        PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS[
          PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS.PAYMENT_LINKS_TRANSACTIONS
        ]
      }`
    );
  };

  const handleRedirectToCheckoutPageEditDetails = (record) => {
    navigateTo(checkout_update_route_key, {
      args: {
        listingUuid: record?.listing_uuid,
        dynamicLinkUuid: record?.dynamic_link_id,
        isCheckout: true,
      },
    });
  };

  const emptyListProps = {
    page_title: "Checkout Pages",
    content_title: "No checkout pages yet",
    empty_list_icon: EmptyListIcon,
    alt: "no checkout pages yet",
    primary_cta: {
      children: "Create checkout pages",
      onClick: handleCreatePaymentLinkModal,
    },
    secondary_cta: { children: "View sample data" },
    preview_image: EXISTING_PAYMENT_LINKS_IMAGE_ASSETS.empty_state,
    children: "As soon as we have new payment, it will show up here.",
  };

  return (
    <>
      <ExlyTable
        ra_props={{
          ...props,
        }}
        noExportButton
        tableTitle={<>Manage Checkout Pages</>}
        filteredEmoptyState={<FilteredEmptyState />}
        columnConfig={EXISTING_PAYMENT_LINKS_COLUMN_CONFIG}
        tableFilters={EXISTING_PAYMENT_LINKS_FILTER_CONFIG}
        tableChipsFormatter={existingPaymentLinksFilterChipsFormatter}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "120px",
          layoutDesktopMargin: "20px 24px",
          noMobileBoxShadow: true,
          showBackCta: false,
          secondaryComponent: (
            <div className={styles.transactionsCtaContainer}>
              <Button
                startIcon={<DvrIcon />}
                onClick={handleRedirectToTransactions}
              >
                See transactions
              </Button>
              <Button onClick={handleCreatePaymentLinkModal}>
                Create a Checkout Page
              </Button>
            </div>
          ),
        }}
        primaryKey="id"
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) => <LinkField record={record} />}
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <Actions
              record={record}
              onExpire={handleExpireAction}
              onEdit={handleEditPaymentLink}
              onEditCheckout={handleRedirectToCheckoutPageEditDetails}
              actionKey="Page"
            />
          ),
        }}
        renderPrimaryColumnRightNode={(record) => (
          <StatusField record={record} />
        )}
        desktopCustomPrimaryAction={{
          renderCustomAction: (record) => (
            <Actions
              record={record}
              onExpire={handleExpireAction}
              onEdit={handleEditPaymentLink}
              onEditCheckout={handleRedirectToCheckoutPageEditDetails}
              actionKey="Page"
            />
          ),
        }}
        primaryColumnProps={{
          align: "center",
          sticky: true,
          width: "90px",
        }}
        hasMobileFooter
        footerProps={{
          primarybtn: "Transactions",
          primaryClick: handleRedirectToTransactions,
          hidePrimarybtn: false,
          // primaryBtnProps: { startIcon: <DvrIcon /> },
          hideSecondaryBtn: false,
          secondaryBtnColor: "primary",
          secondaryBtn: "Create a Checkout Page",
          secondaryClick: handleCreatePaymentLinkModal,
          classes: {
            footer: styles.footerStyles,
          },
        }}
        {...emptyListProps}
      />
      <ExpireModal
        open={showExpire}
        onClose={() => setShowExpire(false)}
        onPrimaryBtnClick={() => handleExpire(selectedRecordData)}
        onSecondaryBtnClick={() => setShowExpire(false)}
        isExpiringLink={isExpiringLink}
      />

      {editPaymentLinkModal}
      <CreatePaymentLinkModal
        open={openCreatePaymentLinkModal}
        onClose={handleCloseCreatePaymentLinkModal}
        shouldRefetchOnPaymentLinkGeneration
        createModalTitles={CREATE_CHECKOUT_LINK_MODAL_TITLES}
        linkTypeOptions={CHECKOUT_PAGE_TYPE_OPTIONS}
        editingModalTitle="Page Settings"
        modalConfig={{
          title: "Choose page type",
          pageLabel: "Page Name",
          priceLabel: "Page Price",
        }}
        isCheckoutPage
      />
    </>
  );
});
