import React from "react";
import {
  POST_TYPE_DETAILS_MAP,
  POST_TYPE_IDS_ENUM,
} from "../../../constants/BrandedCommunity/Post";
import { classNames } from "../../../utils/common/styling";
import { styles } from "./PostTypeSelector.styles";
import { IPostTypeSelectorProps } from "./PostTypeSelector.types";

const PostTypeSelector = ({
  postTypeIdsToShowList,
  onPostTypeClick,
  disablePostTypeClick,
  postTypeId,
  postMediaIsAdded,
}: IPostTypeSelectorProps) => {
  return (
    <>
      {postTypeIdsToShowList.map((typeId) => {
        if (!POST_TYPE_DETAILS_MAP[typeId]) return null;

        const disabled =
          postMediaIsAdded &&
          typeId !== postTypeId &&
          (postTypeId === POST_TYPE_IDS_ENUM.DOCUMENT ||
            postTypeId === POST_TYPE_IDS_ENUM.IMAGE);
        const postTypeClickDisabled = disablePostTypeClick || disabled;

        return (
          <span
            key={typeId}
            className={classNames(
              styles.postTypeOption,
              postTypeClickDisabled && styles["postTypeOption-disabled"],
            )}
            onClick={() => {
              if (postTypeClickDisabled) return;
              onPostTypeClick(typeId);
            }}
          >
            {POST_TYPE_DETAILS_MAP[typeId].svgIcon}
            <span className={styles.postTypeOptionLabel}>
              {POST_TYPE_DETAILS_MAP[typeId].label}
            </span>
          </span>
        );
      })}
    </>
  );
};

export default PostTypeSelector;
