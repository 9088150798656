import React, { FC, Suspense } from "react";
import { checkIsArrayEmpty } from "../../../utils/common/dataTypes/array";
import { IPostDocProps } from "../PostDoc/PostDoc.types";

const PostDocs = React.lazy(() => import("./PostDocs"));

export const LazyLoadedPostDocs: FC<IPostDocProps> = (props) => {
  const { docs, showDocs } = props;

  if (!showDocs || !docs || checkIsArrayEmpty(docs)) return null;

  return (
    <Suspense fallback={null}>
      <PostDocs {...props} />
    </Suspense>
  );
};
