import { useState } from "react";
import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IPostDoc } from "../../../components/BrandedCommunity/Post/Post.types";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { checkIsArrayEmpty } from "../../common/dataTypes/array";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";

export interface IDocSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  onChangeDoc: (props: { docUrl: string; docName: string }) => void;
  docUploading: boolean;
  setDocUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUseDocInEditPostModalReturn {
  onAddPostTypeDocClick: () => void;
  postDocProps: IEditPostModalProps["postDocProps"];
  docSelectorProps: IDocSelectorProps;
}

export const useDocInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
}: IUseMediaInEditPostModalProps): IUseDocInEditPostModalReturn => {
  const [docSelectorIsOpen, setDocSelectorIsOpen] = useState(false);
  const [docUploading, setDocUploading] = useState(false);
  const onAddPostTypeDocClick = () => {
    setDocSelectorIsOpen(true);
  };
  const onCloseDocSelector = () => {
    setDocSelectorIsOpen(false);
  };

  const docs: IPostDoc[] = postData?.metadata?.post_docs ?? [];

  const onChangeDoc: IUseDocInEditPostModalReturn["docSelectorProps"]["onChangeDoc"] =
    ({ docUrl: newDocUrl, docName: newDocName }) => {
      const postDocs = postData?.metadata?.post_docs ?? [];
      const updatePostDocs = [...postDocs];

      updatePostDocs.push({
        post_doc_name: newDocName,
        post_doc_url: newDocUrl,
      });
      setPostData({
        ...postData,
        post_type: POST_TYPE_IDS_ENUM.DOCUMENT,
        metadata: {
          ...postData?.metadata,
          post_docs: updatePostDocs,
        },
      });
      onCloseDocSelector();
    };

  const postTypeDocIsAdded =
    !checkIsArrayEmpty(docs) &&
    postData.post_type === POST_TYPE_IDS_ENUM.DOCUMENT;

  const onClose = onCloseDocSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  const handleRemoveDoc = (index: number) => {
    const postDocs = postData?.metadata?.post_docs ?? [];

    if (checkIsArrayEmpty(postDocs)) return;

    const updatePostDocs = [...postDocs];
    updatePostDocs.splice(index, 1);

    setPostData({
      ...postData,
      post_type: POST_TYPE_IDS_ENUM.DOCUMENT,
      metadata: {
        ...postData?.metadata,
        post_docs: updatePostDocs,
      },
    });

    if (checkIsArrayEmpty(updatePostDocs)) onRemovePostTypeMedia();
  };

  return {
    onAddPostTypeDocClick,

    postDocProps: {
      showDocs: postTypeDocIsAdded,
      docs,
      isEditable: !disablePostTypeDataEdit,
      onRemove: handleRemoveDoc,
      omAddMore: onAddPostTypeDocClick,
    },

    docSelectorProps: {
      isOpen: docSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      onChangeDoc,
      docUploading,
      setDocUploading,
    },
  };
};
