import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { useState } from "react";
import { IPostImage } from "../../../components/BrandedCommunity/Post/Post.types";
import { checkIsArrayEmpty } from "../../common/dataTypes/array";

export interface IImageSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  onChangeImgSrc: (newImgSrc: string) => void;
  imgUploading: boolean;
  setImgUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUseImageInEditPostModalReturn {
  onAddPostTypeImageClick: () => void;
  postImageProps: IEditPostModalProps["postImageProps"];
  imageSelectorProps: IImageSelectorProps;
}

export const useImageInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
  onAddPostTypeMediaSuccess,
}: IUseMediaInEditPostModalProps): IUseImageInEditPostModalReturn => {
  const [imageSelectorIsOpen, setImageSelectorIsOpen] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  const onAddPostTypeImageClick = () => {
    setImageSelectorIsOpen(true);
  };

  const onCloseImageSelector = () => {
    setImageSelectorIsOpen(false);
  };

  const images: IPostImage[] = postData?.metadata?.post_images ?? [];

  const onChangeImgSrc = (newImgSrc: string) => {
    const postImages = postData?.metadata?.post_images ?? [];
    const updatedImages = [...postImages];
    updatedImages.push({ url: newImgSrc });
    setPostData({
      ...postData,
      post_type: POST_TYPE_IDS_ENUM.IMAGE,
      metadata: {
        ...postData?.metadata,
        post_images: updatedImages,
      },
    });
    onAddPostTypeMediaSuccess();
    onCloseImageSelector();
  };

  const postTypeImageIsAdded =
    !checkIsArrayEmpty(images) &&
    postData.post_type === POST_TYPE_IDS_ENUM.IMAGE;

  const onClose = onCloseImageSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  const handleDeleteImage = (index: number) => {
    const postImages = postData?.metadata?.post_images;
    if (postImages) {
      const updatedImages = [...postImages];
      updatedImages.splice(index, 1);
      setPostData({
        ...postData,
        metadata: { ...postData?.metadata, post_images: updatedImages },
      });

      if (checkIsArrayEmpty(updatedImages)) onRemovePostTypeMedia();
    }
  };

  return {
    onAddPostTypeImageClick,

    postImageProps: {
      images,
      showImages: postTypeImageIsAdded,
      isEditable: !disablePostTypeDataEdit,
      onRemove: disablePostTypeDataEdit ? undefined : handleDeleteImage,
      onAddMore: onAddPostTypeImageClick,
    },

    imageSelectorProps: {
      isOpen: imageSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      onChangeImgSrc,
      imgUploading,
      setImgUploading,
    },
  };
};
