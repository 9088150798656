import { Button, IconButton } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionIcon from "@material-ui/icons/MoreHoriz";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import ExlyPopover from "common/Components/ExlyPopover";
import { IExlyPopover } from "common/Components/ExlyPopover/interfaces/IExlyPopover";
import { isPast } from "date-fns";
import React, { FC } from "react";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import ExpiredStatus from "../StatusField/components/ExpiredStatus/ExpiredStatus";
import { IActions } from "./interfaces/IActions";
import { LINK_TYPES } from "features/PaymentLinks/modules/CreatePaymentLinkModal/constants/CreatePaymentLinkModal.constants";
import { checkIsCheckoutEnabledListing } from "features/CheckoutPages/utils/checkIsCheckoutEnabledListing";
import styles from "./Actions.module.css";

const Actions: FC<IActions> = ({
  record,
  onEditCheckout,
  onEdit,
  onExpire,
  actionKey = "Link",
}) => {
  const isDesktop = useIsDesktop();

  let isExpired = !!record.expiry_date && isPast(new Date(record.expiry_date));

  if (isExpired) return isDesktop ? <ExpiredStatus /> : null;

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.PAYMENT_LINKS
  );

  if (!hasWriteAccess) return null;

  const ActionCta: IExlyPopover["AnchorComponent"] = ({ openPopover }) => {
    return isDesktop ? (
      <IconButton onClick={openPopover}>
        <ActionIcon />
      </IconButton>
    ) : (
      <div className={styles.action} onClick={openPopover}>
        Actions <ChevronRightRoundedIcon />
      </div>
    );
  };

  const PopoverComponent: IExlyPopover["PopoverComponent"] = ({
    closePopover,
  }) => {
    const formActions = [
      {
        id: 3,
        label: `${actionKey} Settings`,
        icon: SettingsOutlinedIcon,
        onClick: onEdit,
        hide: false,
      },
      {
        id: 2,
        label: `Edit ${actionKey}`,
        icon: EditIcon,
        onClick: onEditCheckout,
        hide:
          record.payment_link_type !== LINK_TYPES.CUSTOM_LINK ||
          !checkIsCheckoutEnabledListing({ type: record.listing_type }),
      },
      {
        id: 1,
        label: `Expire ${actionKey}`,
        icon: DeleteIcon,
        onClick: onExpire,
        hide: false,
      },
    ];

    return (
      <div>
        {formActions.map(({ id, label, icon: ActionIcon, onClick, hide }) => {
          if (hide) {
            return <></>;
          }
          return (
            <div key={id}>
              <Button
                className={styles.actionCta}
                fullWidth
                startIcon={<ActionIcon />}
                disabled={!hasWriteAccess}
                onClick={() => {
                  onClick(record);
                  closePopover();
                }}
              >
                {label}
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ExlyPopover
      AnchorComponent={ActionCta}
      PopoverComponent={PopoverComponent}
      forceDesktopDesign={false}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    />
  );
};

export default Actions;
