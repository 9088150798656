import React, { FC, Suspense } from "react";
import { IPollVotersModal } from "./PollVotersModal.interfaces";

const PollVotersModal = React.lazy(() => import("./PollVotersModal"));

export const LazyLoadedPollVotersModal: FC<IPollVotersModal> = (props) => {
  const { open } = props;

  if (!open) return null;

  return (
    <Suspense fallback={null}>
      <PollVotersModal {...props} />
    </Suspense>
  );
};
