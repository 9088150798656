import React from "react";

export const POST_TYPE_IDS_ENUM = {
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3,
  OFFERING: 4,
  DOCUMENT: 5,
  POLL: 6,
};

export const DEFAULT_POST_TYPE_ID = POST_TYPE_IDS_ENUM.TEXT;

export const LINK_PREVIEWS = {
  RETRY_COUNT: 5,
  RETRY_INTERVAL: 2000,
};

export const POST_TYPE_DETAILS_MAP = {
  // [post_types_map.TEXT]: {}, // Text is not shown as an option in UI
  [POST_TYPE_IDS_ENUM.IMAGE]: {
    label: "Image",
    id: POST_TYPE_IDS_ENUM.IMAGE,
    svgIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83333 12.1667H12.1667C12.3333 12.1667 12.4583 12.0903 12.5417 11.9375C12.625 11.7847 12.6111 11.6389 12.5 11.5L10.2083 8.4375C10.125 8.32639 10.0139 8.27083 9.875 8.27083C9.73611 8.27083 9.625 8.32639 9.54167 8.4375L7.375 11.3333L5.83333 9.27083C5.75 9.15972 5.63889 9.10417 5.5 9.10417C5.36111 9.10417 5.25 9.15972 5.16667 9.27083L3.5 11.5C3.38889 11.6389 3.375 11.7847 3.45833 11.9375C3.54167 12.0903 3.66667 12.1667 3.83333 12.1667ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667Z"
          fill="#58AEDF"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.VIDEO]: {
    label: "Video",
    id: POST_TYPE_IDS_ENUM.VIDEO,
    svgIcon: (
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.20768 9.91658L11.666 7.70825C11.9299 7.54159 12.0618 7.30547 12.0618 6.99992C12.0618 6.69436 11.9299 6.45825 11.666 6.29158L8.20768 4.08325C7.9299 3.88881 7.64518 3.87492 7.35352 4.04158C7.06185 4.20825 6.91602 4.45131 6.91602 4.77075V9.22908C6.91602 9.54853 7.06185 9.79158 7.35352 9.95825C7.64518 10.1249 7.9299 10.111 8.20768 9.91658ZM2.33268 13.6666C1.87435 13.6666 1.48213 13.5035 1.15602 13.1774C0.829349 12.8508 0.666016 12.4583 0.666016 11.9999V1.99992C0.666016 1.54159 0.829349 1.14936 1.15602 0.823252C1.48213 0.496585 1.87435 0.333252 2.33268 0.333252H15.666C16.1243 0.333252 16.5168 0.496585 16.8435 0.823252C17.1696 1.14936 17.3327 1.54159 17.3327 1.99992V11.9999C17.3327 12.4583 17.1696 12.8508 16.8435 13.1774C16.5168 13.5035 16.1243 13.6666 15.666 13.6666H2.33268Z"
          fill="#63BD92"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.OFFERING]: {
    label: "Offering",
    id: POST_TYPE_IDS_ENUM.OFFERING,
    svgIcon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99935 11.4584L11.3118 13.2084C11.4785 13.3334 11.6452 13.3404 11.8118 13.2292C11.9785 13.1181 12.0271 12.9654 11.9577 12.7709L11.0827 9.87508L13.3535 8.25008C13.5202 8.13897 13.5724 7.98619 13.5102 7.79175C13.4474 7.5973 13.3118 7.50008 13.1035 7.50008H10.3327L9.39518 4.45841C9.32574 4.26397 9.19379 4.16675 8.99935 4.16675C8.8049 4.16675 8.67296 4.26397 8.60352 4.45841L7.66602 7.50008H4.89518C4.68685 7.50008 4.55157 7.5973 4.48935 7.79175C4.42657 7.98619 4.47852 8.13897 4.64518 8.25008L6.91602 9.87508L6.04102 12.7709C5.97157 12.9654 6.02018 13.1181 6.18685 13.2292C6.35352 13.3404 6.52018 13.3334 6.68685 13.2084L8.99935 11.4584ZM8.99935 17.3334C7.84657 17.3334 6.76324 17.1145 5.74935 16.6767C4.73546 16.2395 3.85352 15.6459 3.10352 14.8959C2.35352 14.1459 1.7599 13.264 1.32268 12.2501C0.884905 11.2362 0.666016 10.1529 0.666016 9.00008C0.666016 7.8473 0.884905 6.76397 1.32268 5.75008C1.7599 4.73619 2.35352 3.85425 3.10352 3.10425C3.85352 2.35425 4.73546 1.76036 5.74935 1.32258C6.76324 0.885359 7.84657 0.666748 8.99935 0.666748C10.1521 0.666748 11.2355 0.885359 12.2493 1.32258C13.2632 1.76036 14.1452 2.35425 14.8952 3.10425C15.6452 3.85425 16.2388 4.73619 16.676 5.75008C17.1138 6.76397 17.3327 7.8473 17.3327 9.00008C17.3327 10.1529 17.1138 11.2362 16.676 12.2501C16.2388 13.264 15.6452 14.1459 14.8952 14.8959C14.1452 15.6459 13.2632 16.2395 12.2493 16.6767C11.2355 17.1145 10.1521 17.3334 8.99935 17.3334Z"
          fill="#8A7EDF"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.DOCUMENT]: {
    label: "Doc",
    id: POST_TYPE_IDS_ENUM.DOCUMENT,
    svgIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66667 12.1667H8.83333C9.06944 12.1667 9.2675 12.0867 9.4275 11.9267C9.58694 11.7672 9.66667 11.5694 9.66667 11.3333C9.66667 11.0972 9.58694 10.8992 9.4275 10.7392C9.2675 10.5797 9.06944 10.5 8.83333 10.5H4.66667C4.43056 10.5 4.2325 10.5797 4.0725 10.7392C3.91306 10.8992 3.83333 11.0972 3.83333 11.3333C3.83333 11.5694 3.91306 11.7672 4.0725 11.9267C4.2325 12.0867 4.43056 12.1667 4.66667 12.1667ZM4.66667 8.83333H11.3333C11.5694 8.83333 11.7672 8.75333 11.9267 8.59333C12.0867 8.43389 12.1667 8.23611 12.1667 8C12.1667 7.76389 12.0867 7.56583 11.9267 7.40583C11.7672 7.24639 11.5694 7.16667 11.3333 7.16667H4.66667C4.43056 7.16667 4.2325 7.24639 4.0725 7.40583C3.91306 7.56583 3.83333 7.76389 3.83333 8C3.83333 8.23611 3.91306 8.43389 4.0725 8.59333C4.2325 8.75333 4.43056 8.83333 4.66667 8.83333ZM4.66667 5.5H11.3333C11.5694 5.5 11.7672 5.42 11.9267 5.26C12.0867 5.10056 12.1667 4.90278 12.1667 4.66667C12.1667 4.43056 12.0867 4.2325 11.9267 4.0725C11.7672 3.91306 11.5694 3.83333 11.3333 3.83333H4.66667C4.43056 3.83333 4.2325 3.91306 4.0725 4.0725C3.91306 4.2325 3.83333 4.43056 3.83333 4.66667C3.83333 4.90278 3.91306 5.10056 4.0725 5.26C4.2325 5.42 4.43056 5.5 4.66667 5.5ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667Z"
          fill="#EBAC33"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.POLL]: {
    label: "Polls",
    id: POST_TYPE_IDS_ENUM.POLL,
    svgIcon: (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="14.9995" height="14.7417" rx="2" fill="#DF58B9" />
        <path
          d="M8.53571 3.63335C8.52516 3.12447 8.51072 2.42684 8.1917 2.18257C8.08789 2.10337 7.97483 2.08653 7.85824 2.07488C7.79218 2.06858 7.71946 2.05767 7.64322 2.04619C7.34862 2.00271 7.01479 1.9533 6.76257 2.08616C6.46446 2.24364 6.48148 2.54841 6.49666 2.81433C6.50054 2.88132 6.50443 2.95237 6.50443 3.02732C6.50443 3.15408 6.52997 3.45737 6.53219 3.47477C6.53219 3.6439 6.55958 3.96607 6.56013 3.96885L6.64322 6.60652C6.64322 7.20478 6.63304 7.81618 6.62342 8.40741C6.61065 9.18295 6.59881 9.98458 6.6075 10.7784C6.61139 11.1378 6.61527 11.5096 6.60362 11.87C6.59399 12.1774 6.63637 12.3958 6.73426 12.5381C6.88471 12.757 7.12453 12.7557 7.44707 12.7298C7.98093 12.6872 8.48519 12.6469 8.52238 11.4831C8.52923 11.2673 8.53015 11.0501 8.52941 10.8332C8.52886 10.7011 8.52738 10.569 8.52608 10.4368C8.52423 10.2644 8.52238 10.0921 8.52238 9.92055V5.467C8.52238 5.26548 8.52978 5.0647 8.53719 4.86411C8.54459 4.66204 8.55218 4.45552 8.55218 4.24716C8.55218 4.13224 8.54977 4.01659 8.54403 3.90075C8.53959 3.82247 8.53756 3.73161 8.53571 3.63335Z"
          fill="white"
        />
        <path
          d="M5.04589 6.5166C5.03534 6.16926 5.02072 5.69369 4.70188 5.52678C4.59825 5.47274 4.485 5.46127 4.36842 5.45331C4.30236 5.44906 4.22964 5.44165 4.1534 5.43388C3.85899 5.40427 3.52497 5.37041 3.27275 5.46108C2.97464 5.5686 2.99185 5.77548 3.00684 5.95812C3.01072 6.00383 3.01461 6.05231 3.01461 6.10339C3.01461 6.18999 3.04015 6.39687 3.04237 6.40872C3.04237 6.52419 3.06957 6.74402 3.07012 6.74588L3.1534 8.54602C3.1534 8.95424 3.14322 9.37153 3.1336 9.77512C3.12083 10.304 3.10898 10.8501 3.1175 11.393C3.12138 11.6382 3.12545 11.8917 3.11361 12.138C3.10399 12.3458 3.14636 12.4966 3.24426 12.594C3.39451 12.7433 3.63452 12.7424 3.95706 12.7248C4.49093 12.6957 4.99518 12.6682 5.03219 11.8737C5.03922 11.7268 5.04015 11.5786 5.03941 11.4304C5.03885 11.3401 5.03737 11.2494 5.03589 11.1591C5.03404 11.0416 5.03219 10.9243 5.03219 10.8075V7.76845C5.03219 7.63115 5.03959 7.4944 5.047 7.35746C5.0544 7.21923 5.06198 7.07804 5.06198 6.93592C5.06198 6.85727 5.05958 6.77826 5.05384 6.69943C5.04996 6.64558 5.04792 6.58359 5.04589 6.5166Z"
          fill="white"
        />
        <path
          d="M11.984 9.57868C11.9916 9.49689 11.9994 9.41307 11.9994 9.32794C11.9994 9.27983 11.997 9.23153 11.9907 9.1825C11.9868 9.15252 11.9851 9.11791 11.9831 9.08072C11.9731 8.88901 11.9563 8.56795 11.6232 8.46673C11.5247 8.43675 11.4156 8.43046 11.3031 8.42583C11.2216 8.42269 11.1402 8.41769 11.0588 8.4127C10.9035 8.40326 10.7429 8.39345 10.583 8.3966L10.5098 8.39752C10.3195 8.39882 10.155 8.39993 10.0799 8.64993C10.029 8.81962 10.0468 9.04741 10.064 9.2678C10.0721 9.37254 10.0795 9.47154 10.0786 9.55185C10.0764 9.68509 10.0806 9.82498 10.0847 9.96155C10.0878 10.0665 10.091 10.1695 10.091 10.2654C10.091 10.5019 10.0808 10.7443 10.0712 10.9786C10.0584 11.2876 10.0468 11.607 10.0551 11.9245C10.0592 12.0659 10.063 12.2116 10.0514 12.3518C10.0429 12.4579 10.069 12.5448 10.1298 12.609C10.2607 12.7465 10.5168 12.7491 10.8924 12.7371C11.4491 12.7193 11.9298 12.7042 11.9696 12.208C11.9766 12.1207 11.9777 12.033 11.977 11.9442C11.9764 11.8912 11.975 11.8381 11.9735 11.7854C11.9716 11.7175 11.9698 11.6497 11.9698 11.5822V9.81277C11.9696 9.73449 11.9768 9.65659 11.984 9.57868Z"
          fill="white"
        />
      </svg>
    ),
  },
};
