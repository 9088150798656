import { IPostProps } from "../../../../../components/BrandedCommunity/Post/Post.types";
import { useToggleState } from "../../../../../utils/common/dataTypes/boolean";

export const usePollVotersModal = (data: IPostProps["data"]) => {
  const [isOpen, open, close] = useToggleState(false);

  const {
    metadata: { poll_data: { options = [] } = {}, poll_uuid: pollUuid } = {},
  } = data;
  const totalVotes = options.reduce((total, { votes }) => total + votes, 0);
  const formattedOptions = options.map((currentOption) => {
    const { option, uuid, votes } = currentOption;
    const percentage = totalVotes ? Math.round((votes / totalVotes) * 100) : 0;
    return { option, uuid, percentage, votes };
  });

  return {
    isOpen,
    data: {
      poll_uuid: pollUuid,
      total_votes: totalVotes,
      options: formattedOptions,
    },
    open,
    close,
  };
};
