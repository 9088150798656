import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button } from "@mui/material";
import React, { MouseEventHandler, useCallback } from "react";
import { useCreatorThemeContext } from "../../../../../features/Common/modules/Theme/CreatorThemeContext";
import { COMMENT_STATUS } from "../../../../../features/Offerings/modules/RecordedContent/CommentSection/CommentSection.data";
import Popover from "../../../../common/Popover/Popover";
import { getThemedStyles, styles } from "./MoreButtonAndMenu.styles";
import { IMoreButtonAndMenuProps } from "./MoreButtonAndMenu.types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const MoreButtonAndMenu = ({
  MoreButtonComponent,
  canEdit,
  onEditClick,
  canDelete,
  onDeleteClick,
  currentCommentStatus,
  canHideUnhide,
  onHideUnhideClick,
  canViewVoters,
  onViewVotes,
  canReport,
  onReport,
  canDuplicatePost,
  onDuplicatePostClick,
  deleteCtaText = "Delete",
  reportCtaText = "Report post",
}: IMoreButtonAndMenuProps) => {
  if (
    !canEdit &&
    !canDelete &&
    !canHideUnhide &&
    !canViewVoters &&
    !canReport &&
    !canDuplicatePost
  )
    return null;
  const theme = useCreatorThemeContext();
  const themedStyles = getThemedStyles({ theme });

  const isCurrentCommentHidden = currentCommentStatus == COMMENT_STATUS.HIDDEN;

  const AnchorComponent = useCallback(
    ({ openPopover }) => (
      <MoreButtonComponent
        className={styles.moreButton}
        onClick={openPopover}
        fontSize="small"
      />
    ),
    [],
  );

  return (
    <Popover
      AnchorComponent={AnchorComponent}
      PopoverComponent={({ closePopover }) => {
        const getClickHandler =
          (callback?: () => void): MouseEventHandler =>
          (e) => {
            if (callback) callback();
            closePopover(e);
          };

        const actions = [
          {
            id: 1,
            label: "Edit",
            icon: <EditOutlinedIcon className={themedStyles.editIcon} />,
            onClick: getClickHandler(onEditClick),
            show: canEdit,
          },
          {
            id: 2,
            label: "Unhide",
            icon: isCurrentCommentHidden ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            ),
            onClick: getClickHandler(
              () =>
                onHideUnhideClick?.({
                  status: isCurrentCommentHidden
                    ? COMMENT_STATUS.SHOWN
                    : COMMENT_STATUS.HIDDEN,
                }),
            ),
            show: canHideUnhide,
          },
          {
            id: 3,
            label: "View votes",
            icon: <BarChartRoundedIcon />,
            onClick: getClickHandler(onViewVotes),
            show: canViewVoters,
            className: themedStyles.votersCta,
          },
          {
            id: 4,
            label: "Duplicate post",
            icon: <ContentCopyIcon />,
            onClick: getClickHandler(onDuplicatePostClick),
            show: canDuplicatePost,
          },
          {
            id: 5,
            label: reportCtaText,
            icon: <ReportGmailerrorredRoundedIcon />,
            onClick: getClickHandler(onReport),
            show: canReport,
            className: styles.reportCta,
          },
          {
            id: 6,
            label: deleteCtaText,
            icon: <DeleteRoundedIcon />,
            onClick: getClickHandler(onDeleteClick),
            show: canDelete,
            className: styles.deteleCta,
          },
        ].filter((cta) => cta.show);

        return (
          <div className={styles.moreMenuContainer}>
            {actions.map(({ id, icon, label, className, onClick }, index) => (
              <div key={id}>
                {index > 0 && <div className={styles.divider} />}

                <Button
                  className={className}
                  startIcon={icon}
                  onClick={onClick}
                  fullWidth
                >
                  {label}
                </Button>
              </div>
            ))}
          </div>
        );
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    />
  );
};

export default MoreButtonAndMenu;
