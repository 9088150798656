import React, { FC, Suspense } from "react";
import { IPostImageProps } from "../../../PostImage/PostImage.types";
import { checkIsArrayEmpty } from "../../../../../utils/common/dataTypes/array";

const PostImagesPicker = React.lazy(() => import("./PostImagesPicker"));

export const LazyLoadedPostImagesPicker: FC<IPostImageProps> = (props) => {
  const { showImages, images } = props;

  if (!showImages || checkIsArrayEmpty(images)) return null;

  return (
    <Suspense fallback={null}>
      <PostImagesPicker {...props} />
    </Suspense>
  );
};
