import React from "react";
import { parseISO, subDays, format } from "date-fns";
import { useParams } from "react-router-dom";

import {
  notification_color_keys,
  useNotifications,
} from "utils/hooks/useNotifications";
import {
  MAX_SESSIONS_DAYS_COUNT,
  NEW_SECTION_OPTION_VALUE,
  NONE_OPTION_SECTION_VALUE,
  column_keys,
} from "../constants/ManageRecordings.constant";
import { getUpdatedRecordingToSections } from "../utils/ManageRecordings.util";
import { api, dataProvider } from "data";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";
import { is_empty } from "utils/validations";
import { schedule_types_ids } from "constants/schedule";
import { LISTING_PARTICIPANTS_API } from "ui/pages/customers/ManageBookings/moduleAPI";
import {
  DAY_DATE_TIME_COMPLETE_FORMAT,
  DEFAULT_YEAR_MONTH_DATE_FORMAT,
} from "constants/date-fns/dateTime.constant";
import { apiMethods } from "data/api.constants";
import styles from "../ManageRecordings.module.css";

const startDate = format(
  subDays(new Date(), MAX_SESSIONS_DAYS_COUNT),
  DEFAULT_YEAR_MONTH_DATE_FORMAT
);
const endDate = format(new Date(), DEFAULT_YEAR_MONTH_DATE_FORMAT);

const useManageRecordings = () => {
  const [showViewRecordingModal, setShowViewRecordingModal] =
    React.useState(false);
  const [viewRecordingModalData, setViewRecordingModal] = React.useState({});
  const [recordToSelectedSection, setRecordToSelectedSection] = React.useState(
    {}
  );
  const [showSectionChangesModal, setShowSectionChangesModal] =
    React.useState(false);
  const [zoomRcDetails, setZoomRcDetails] = React.useState({});
  const [parentOffering, setParentOffering] = React.useState({});
  const [batches, setBatches] = React.useState([]);
  const [recordToSelectedBatch, setRecordToSelectedBatch] = React.useState({});
  const [sessions, setSessions] = React.useState([]);
  const [recordToSelectedSession, setRecordToSelectedSession] = React.useState(
    {}
  );
  const [customers, setCustomers] = React.useState([]);
  const [recordToSelectedCustomer, setRecordToSelectedCustomer] =
    React.useState({});
  const [isPageDataLoaing, setIsPageDataLoading] = React.useState(true);
  const [courseSectionOptions, setCourseSectionOptions] = React.useState([
    {
      id: 0,
      label: "-",
      value: NONE_OPTION_SECTION_VALUE,
    },
    {
      id: 1,
      label: (
        <span className={styles.create_new_section_label}>
          Create new section +
        </span>
      ),
      value: NEW_SECTION_OPTION_VALUE,
    },
  ]);

  const { listing_uuid: listingUuid } = useParams();
  const { notify } = useNotifications();

  const showBatchColumn = [schedule_types_ids.group_class].includes(
    parentOffering.type
  );

  const showSessionColumn = [
    schedule_types_ids.rolling_class,
    schedule_types_ids.webinar,
  ].includes(parentOffering.type);

  const showCustomerColumn = [
    schedule_types_ids.one_on_one_appointment,
  ].includes(parentOffering.type);

  const handleToggleShowViewRecordingModal = () => {
    setShowViewRecordingModal((prev) => !prev);
  };

  const toggleShowSaveSectionChangesModal = () =>
    setShowSectionChangesModal((prev) => !prev);

  const getZoomRcOfferingDetails = async () => {
    try {
      const zoomDetailsResponse = await dataProvider.custom_request(
        `${api.get_recordedcontent_details}${listingUuid}`
      );
      if (isRequestSuccessful(zoomDetailsResponse?.status)) {
        const { event } = zoomDetailsResponse?.data;
        setZoomRcDetails(event);
        const { categories, parent_offering: mainOfferingData } = event;
        if (!is_empty(mainOfferingData)) {
          setParentOffering(mainOfferingData);
        }
        if (!is_empty(categories)) {
          const availableCourseSections = categories.map((sectionData) => ({
            ...sectionData,
            label: sectionData.title,
            value: sectionData.uuid,
            id: sectionData.uuid,
          }));
          setCourseSectionOptions((prev) => {
            let newPrev = [...prev];
            newPrev.splice(prev.length - 1, 0, ...availableCourseSections);
            return newPrev;
          });
        }

        switch (mainOfferingData?.type) {
          case schedule_types_ids.group_class: {
            const classesDetailsResponse = await dataProvider.custom_request(
              `${api.get_listing_details}/${mainOfferingData?.uuid}`
            );
            if (isRequestSuccessful(classesDetailsResponse.status)) {
              if (!is_empty(classesDetailsResponse?.data?.event?.classes)) {
                const { classes } = classesDetailsResponse.data.event;
                const availableBatches = classes?.map((batch) => ({
                  ...batch,
                  id: batch.uuid,
                  label: batch.timings,
                  value: batch.uuid,
                }));
                setBatches([...availableBatches]);
              }
            }
            break;
          }

          // For both rolling class or webinar type
          case schedule_types_ids.rolling_class:
          case schedule_types_ids.webinar: {
            const sessionDetailsResponse = await dataProvider.custom_request(
              api.dashboard.sessions_list,
              apiMethods.GET,
              {
                listing_uuid: mainOfferingData?.uuid,
                start_date: startDate,
                end_date: endDate,
                is_booked: true,
              }
            );
            if (isRequestSuccessful(sessionDetailsResponse.status)) {
              const { sessions: sessionsList } = sessionDetailsResponse?.data;
              const availableSessions = sessionsList?.map((session) => ({
                ...session,
                id: session.slot_uid,
                label: format(
                  parseISO(session.start_datetime),
                  DAY_DATE_TIME_COMPLETE_FORMAT
                ),
                value: session.slot_uid,
              }));
              setSessions([...availableSessions]);
            }
            break;
          }

          case schedule_types_ids.one_on_one_appointment: {
            const mainListingUuid = mainOfferingData?.uuid;
            if (!mainListingUuid) {
              notify("No offering selected!", notification_color_keys.error);
              return;
            }
            const customerDetailsResponse = await dataProvider.custom_request(
              LISTING_PARTICIPANTS_API,
              apiMethods.GET,
              {
                listing_uuid: mainListingUuid,
                unique_customer: true,
                page: "all",
              }
            );
            if (isRequestSuccessful(customerDetailsResponse.status)) {
              if (!is_empty(customerDetailsResponse?.data?.participants)) {
                const { participants } = customerDetailsResponse.data;
                const availableCstomers = participants?.map((participant) => ({
                  ...participant,
                  id: participant.username,
                  label: participant.customer_name,
                  value: participant.username,
                }));
                setCustomers([...availableCstomers]);
              }
            }
            break;
          }
        }
      }
      setIsPageDataLoading(false);
    } catch (error) {
      setIsPageDataLoading(false);
      logError({
        error,
        occuredAt:
          "getZoomRcOfferingDetails in src/ui/pages/schedule/ManageOffering/ManageRecordings/hooks/useManageRecordings.js",
        when: "when offering detail api is being hit for zoom rc offering",
      });
    }
  };

  const handleSaveChnagesClick = () => {
    const updatedRecordingToSections = getUpdatedRecordingToSections(
      recordToSelectedSection
    );
    const recordingSectionUuids = Object.keys(updatedRecordingToSections);
    let errorMessage = "";
    if (!is_empty(recordingSectionUuids)) {
      if (
        showBatchColumn &&
        recordingSectionUuids.some(
          (recordingUuid) => !recordToSelectedBatch?.[recordingUuid]
        )
      ) {
        errorMessage = "Please select a batch alongside of the mapped section!";
      }

      if (
        showSessionColumn &&
        recordingSectionUuids.some(
          (recordingUuid) => !recordToSelectedSession?.[recordingUuid]
        )
      ) {
        errorMessage =
          "Please select a session alongside of the mapped section!";
      }

      if (
        showCustomerColumn &&
        recordingSectionUuids.some(
          (recordingUuid) => !recordToSelectedCustomer?.[recordingUuid]
        )
      ) {
        errorMessage =
          "Please select a customer alongside of the mapped section!";
      }

      if (!is_empty(errorMessage)) {
        notify(errorMessage, notification_color_keys.error);
        return;
      }
      toggleShowSaveSectionChangesModal();
    } else {
      notify(
        "Please map atleast one recording to a section to proceed!",
        notification_color_keys.error
      );
    }
  };

  const handleViewRecordingActionClick = (record) => {
    setViewRecordingModal(record);
    handleToggleShowViewRecordingModal();
  };

  const handleSelectedSectionMappingChange = (record, value) => {
    setRecordToSelectedSection((prev) => ({
      ...prev,
      [record[column_keys.recordingId.apiKey]]: value,
    }));
  };

  const handleSelectedBatchMappingChange = (record, value) => {
    setRecordToSelectedBatch((prev) => ({
      ...prev,
      [record[column_keys.recordingId.apiKey]]: value,
    }));
  };

  const handleSelectedSessionMappingChange = (record, value) => {
    setRecordToSelectedSession((prev) => ({
      ...prev,
      [record[column_keys.recordingId.apiKey]]: value,
    }));
  };

  const handleSelectedCustomerMappingChange = (record, value) => {
    setRecordToSelectedCustomer((prev) => ({
      ...prev,
      [record[column_keys.recordingId.apiKey]]: value,
    }));
  };

  const handleClearMappingStates = () => {
    setRecordToSelectedSection({});
    setRecordToSelectedBatch({});
    setRecordToSelectedSession({});
    setRecordToSelectedCustomer({});
  };

  return {
    listingUuid,
    parentOffering,
    showViewRecordingModal,
    viewRecordingModalData,
    handleToggleShowViewRecordingModal,
    handleViewRecordingActionClick,
    recordToSelectedSection,
    handleSelectedSectionMappingChange,
    courseSectionOptions,
    setCourseSectionOptions,
    showSectionChangesModal,
    toggleShowSaveSectionChangesModal,
    handleSaveChnagesClick,
    zoomRcDetails,
    batchesProps: {
      showBatchesColumn: showBatchColumn,
      options: batches,
      recordToSelectedBatch,
      handleBatchChange: handleSelectedBatchMappingChange,
    },
    sessionsProps: {
      showSessionsColumn: showSessionColumn,
      options: sessions,
      recordToSelectedSession,
      handleSessionChange: handleSelectedSessionMappingChange,
    },
    customersProps: {
      showCustomersColumn: showCustomerColumn,
      options: customers,
      recordToSelectedCustomer,
      handleCustomerChange: handleSelectedCustomerMappingChange,
    },
    isPageDataLoaing,
    getZoomRcOfferingDetails,
    handleClearMappingStates,
  };
};

export default useManageRecordings;
