import api from "data/APIs";
import dataProvider from "data/dataProvider";
import { put, takeEvery, call, select, takeLatest } from "redux-saga/effects";
import { is_empty } from "utils/validations";
import {
  EXLY_SCHEDULE__GET_ALL_OFFERINGS,
  EXLY_SCHEDULE__SET_ALL_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS_TYPE,
  EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__REQUEST_DELETE_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS_TYPE,
  EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__REQUEST_DELETE_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS_TYPE,
  EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS_TYPE,
  EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING,
  EXLY_SCHEDULELIST__REQUEST_PLAN_OFFERINGS,
  EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_MEMBERS,
  EXLY_SCHEDULELIST__SET_MEMBERS,
  EXLY_SCHEDULE__RESET_ALL_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_DELETE_PLANS,
} from "./actions";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import { PLANS_APIS } from "features/Listings/modules/Plans/constants/Plans.constants";
const status = offering_availability_statuses;

/**
 * Worker Saga: to get all offerings data
 */
function* getAllOfferings({ payload = {} }) {
  let state = yield select((state) => state.schedule.allOfferings);
  const allPagesFetched =
    (state.total_pages > 0 && state.pages_fetched >= state.total_pages) ||
    state.pages_fetched === "all";
  try {
    if (!allPagesFetched && (state.isFiltering === true || payload.fetchAll)) {
      yield put({ type: EXLY_SCHEDULE__RESET_ALL_OFFERINGS });
      state = yield select((state) => state.schedule.allOfferings);
    }
    // Checking if all pages fetched
    if (allPagesFetched) {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULE__SET_ALL_OFFERINGS,
        payload: {
          isLoading: true,
          data: [...state.data],
          error: {},
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
        },
      });
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_offerings,
        "GET",
        {
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULE__SET_ALL_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...state.data, ...response.data.listings],
            total_pages: response.data.total_pages,
            pages_fetched: payload.fetchAll ? "all" : state.pages_fetched + 1,
            isFiltering: false,
            noData: is_empty(response.data.listings) && is_empty(state.data),
            error: {},
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULE__SET_ALL_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get Live offerings data
 */
function* getLiveOfferings({ payload = {} }) {
  let state = yield select((state) => state.schedule.live);
  try {
    const allPagesFetched =
      (state.total_pages > 0 &&
        state.pages_fetched >= state.total_pages &&
        state.filtered_total_items === state.total_items) ||
      state.pages_fetched === "all";
    if (!allPagesFetched && (state.isFiltering === true || payload.fetchAll)) {
      yield put({ type: EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS });
      state = yield select((state) => state.schedule.live);
    }
    // Checking if all pages fetched
    if (allPagesFetched) {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
        payload: {
          isLoading: true,
          data: [...state.data],
          error: {},
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
          total_items: 0,
        },
      });
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_offerings,
        "GET",
        {
          status: status.live,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...state.data, ...response.data.listings],
            total_pages: response.data.total_pages,
            pages_fetched: payload.fetchAll ? "all" : state.pages_fetched + 1,
            isFiltering: false,
            noData: is_empty(response.data.listings) && is_empty(state.data),
            error: {},
            total_items: response.data.total || 0,
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
        total_items: 0,
      },
    });
  }
}

/**
 * Worker Saga: to get LIVE offerings with Type Filter
 */
function* getLiveFilteredOfferings({ payload }) {
  let state = yield select((state) => state.schedule.live);
  try {
    if (!state.isLoading && payload) {
      // member Or Sku
      const memberFilter = "org_uuid" in payload;
      const filterType = memberFilter ? payload.org_uuid : payload.sku;
      const total_items_state = state.total_items;
      if (!state.isFiltering || state.filteringType !== filterType) {
        yield put({ type: EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS });
        state = yield select((state) => state.schedule.live);
      }

      if (state.total_pages > 0 && state.pages_fetched >= state.total_pages) {
        // throw `Fetch: All Pages Fetched`;
        return;
      }

      yield put({ type: EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING });

      let _api = api.fetch_offerings;
      let queryPayload = {
        status: payload.status || status.live,
      };
      if (memberFilter) {
        queryPayload = { ...queryPayload, org_uuid: payload.org_uuid };
      } else {
        queryPayload = {
          ...queryPayload,
          type: payload.sku,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
          show_upsell_allowed: payload.show_upsell_allowed || false,
        };
      }
      const response = yield call(dataProvider.custom_request, _api, "GET", {
        ...queryPayload,
      });
      if (response.status === 200) {
        const data =
          state.pages_fetched === 0
            ? [...response.data.listings]
            : [...state.data, ...response.data.listings];

        const total_pages = memberFilter ? 1 : response.data.total_pages;

        const dataSorted = payload?.status
          ? data.sort((a, b) => a.status - b.status)
          : data;

        yield put({
          type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
          payload: {
            isLoading: false,
            data: dataSorted,
            total_pages: total_pages,
            pages_fetched: state.pages_fetched + 1,
            error: {},
            isFiltering: true,
            filteringType: filterType,
            noData: is_empty(response.data.listings) && is_empty(state.data),
            filtered_total_items: response.data.total || 0,
            total_items: total_items_state,
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({ type: EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS });
  }
}

/**
 * Worker Saga: to delete live offerings with uuid
 */
function* deleteLiveOffering({ payload }) {
  let state = yield select((state) => state.schedule.live);
  try {
    if (!is_empty(payload.uuid)) {
      const updatedList = state.data.filter(
        (item) => item.uuid !== payload.uuid
      );

      yield put({
        type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
        payload: {
          isLoading: false,
          data: updatedList,
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
          error: {},
          isFiltering: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get Draft offerings data
 */
function* getDraftOfferings({ payload = {} }) {
  let state = yield select((state) => state.schedule.draft);
  const allPagesFetched =
    (state.total_pages > 0 && state.pages_fetched >= state.total_pages) ||
    state.pages_fetched === "all";
  try {
    if (!allPagesFetched && (state.isFiltering === true || payload.fetchAll)) {
      yield put({ type: EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS });
      state = yield select((state) => state.schedule.draft);
    }

    // Checking if all pages fetched
    if (allPagesFetched) {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
        payload: {
          isLoading: true,
          data: [...state.data],
          error: {},
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
        },
      });
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_offerings,
        "GET",
        {
          status: status.draft,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...state.data, ...response.data.listings],
            total_pages: response.data.total_pages,
            pages_fetched: payload.fetchAll ? "all" : state.pages_fetched + 1,
            isFiltering: false,
            error: {},
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get Draft offerings with Type Filter
 */
function* getDraftFilteredOfferings({ payload }) {
  let state = yield select((state) => state.schedule.draft);
  try {
    if (!state.isLoading && payload) {
      // member Or Sku
      const memberFilter = "org_uuid" in payload;
      const filterType = memberFilter ? payload.org_uuid : payload.sku;

      if (!state.isFiltering || state.filteringType !== filterType) {
        yield put({ type: EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS });
        state = yield select((state) => state.schedule.draft);
      }

      if (state.total_pages > 0 && state.pages_fetched >= state.total_pages)
        throw `Fetch: All Pages Fetched`;

      yield put({ type: EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING });

      let _api = api.fetch_offerings;
      let queryPayload = {
        status: status.draft,
      };
      if (memberFilter) {
        queryPayload = {
          ...queryPayload,
          org_uuid: payload.org_uuid,
        };
      } else {
        queryPayload = {
          ...queryPayload,
          type: payload.sku,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        };
      }

      const response = yield call(dataProvider.custom_request, _api, "GET", {
        ...queryPayload,
      });
      if (response.status === 200) {
        const data =
          state.pages_fetched === 0
            ? [...response.data.listings]
            : [...state.data, ...response.data.listings];
        const total_pages = memberFilter ? 1 : response.data.total_pages;

        yield put({
          type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
          payload: {
            isLoading: false,
            data: data,
            total_pages: total_pages,
            pages_fetched: state.pages_fetched + 1,
            error: {},
            isFiltering: true,
            filteringType: filterType,
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({ type: EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS });
  }
}

/**
 * Worker Saga: to delete draft offerings with uuid
 */
function* deleteDraftOffering({ payload }) {
  let state = yield select((state) => state.schedule.draft);
  try {
    if (!is_empty(payload.uuid)) {
      const updatedList = state.data.filter(
        (item) => item.uuid !== payload.uuid
      );

      yield put({
        type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
        payload: {
          isLoading: false,
          data: updatedList,
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
          error: {},
          isFiltering: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get SoldOut offerings data
 */
function* getSoldOutOfferings({ payload = {} }) {
  let state = yield select((state) => state.schedule.soldout);
  const allPagesFetched =
    (state.total_pages > 0 && state.pages_fetched >= state.total_pages) ||
    state.pages_fetched === "all";
  try {
    if (!allPagesFetched && (state.isFiltering === true || payload.fetchAll)) {
      yield put({ type: EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS });
      state = yield select((state) => state.schedule.soldout);
    }
    // Checking if all pages fetched
    if (allPagesFetched) {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
        payload: {
          isLoading: true,
          data: [...state.data],
          error: {},
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
        },
      });
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_offerings,
        "GET",
        {
          status: status.sold_out,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...state.data, ...response.data.listings],
            total_pages: response.data.total_pages,
            pages_fetched: payload.fetchAll ? "all" : state.pages_fetched + 1,
            isFiltering: false,
            error: {},
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get SoldOut offerings with Type Filter
 */
function* getSoldOutFilteredOfferings({ payload }) {
  let state = yield select((state) => state.schedule.soldout);
  try {
    if (!state.isLoading && payload) {
      // member Or Sku
      const memberFilter = "org_uuid" in payload;
      const filterType = memberFilter ? payload.org_uuid : payload.sku;

      if (!state.isFiltering || state.filteringType !== filterType) {
        yield put({ type: EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS });
        state = yield select((state) => state.schedule.soldout);
      }

      if (state.total_pages > 0 && state.pages_fetched >= state.total_pages)
        throw `Fetch: All Pages Fetched`;

      yield put({ type: EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING });

      let _api = api.fetch_offerings;
      let queryPayload = {
        status: status.sold_out,
      };
      if (memberFilter) {
        queryPayload = {
          ...queryPayload,
          org_uuid: payload.org_uuid,
        };
      } else {
        queryPayload = {
          ...queryPayload,
          type: payload.sku,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        };
      }

      const response = yield call(dataProvider.custom_request, _api, "GET", {
        ...queryPayload,
      });
      if (response.status === 200) {
        const data =
          state.pages_fetched === 0
            ? [...response.data.listings]
            : [...state.data, ...response.data.listings];

        const total_pages = memberFilter ? 1 : response.data.total_pages;

        yield put({
          type: EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
          payload: {
            isLoading: false,
            data: data,
            total_pages: total_pages,
            pages_fetched: state.pages_fetched + 1,
            error: {},
            isFiltering: true,
            filteringType: filterType,
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({ type: EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS });
  }
}

/**
 * Worker Saga: to get Past offerings data
 */
function* getPastOfferings({ payload = {} }) {
  let state = yield select((state) => state.schedule.past);
  const allPagesFetched =
    (state.total_pages > 0 && state.pages_fetched >= state.total_pages) ||
    state.pages_fetched === "all";
  try {
    if (!allPagesFetched && (state.isFiltering === true || payload.fetchAll)) {
      yield put({ type: EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS });
      state = yield select((state) => state.schedule.past);
    }
    // Checking if all pages fetched
    if (allPagesFetched) {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
        payload: {
          isLoading: true,
          data: [...state.data],
          error: {},
          total_pages: state.total_pages,
          pages_fetched: state.pages_fetched,
        },
      });
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_offerings,
        "GET",
        {
          status: status.expired,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...state.data, ...response.data.listings],
            total_pages: response.data.total_pages,
            pages_fetched: payload.fetchAll ? "all" : state.pages_fetched + 1,
            isFiltering: false,
            error: {},
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        total_pages: state.total_pages,
        pages_fetched: state.pages_fetched,
        isFiltering: false,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get Past offerings with Type Filter
 */
function* getPastFilteredOfferings({ payload }) {
  let state = yield select((state) => state.schedule.past);
  try {
    if (!state.isLoading && payload) {
      // member Or Sku
      const memberFilter = "org_uuid" in payload;
      const filterType = memberFilter ? payload.org_uuid : payload.sku;

      if (!state.isFiltering || state.filteringType !== filterType) {
        yield put({ type: EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS });
        state = yield select((state) => state.schedule.past);
      }

      if (state.total_pages > 0 && state.pages_fetched >= state.total_pages)
        throw `Fetch: All Pages Fetched`;

      yield put({ type: EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING });

      let _api = api.fetch_offerings;
      let queryPayload = {
        status: status.expired,
      };
      if (memberFilter) {
        queryPayload = {
          ...queryPayload,
          org_uuid: payload.org_uuid,
        };
      } else {
        queryPayload = {
          ...queryPayload,
          type: payload.sku,
          page: payload.fetchAll ? "all" : state.pages_fetched + 1,
        };
      }

      const response = yield call(dataProvider.custom_request, _api, "GET", {
        ...queryPayload,
      });
      if (response.status === 200) {
        const data =
          state.pages_fetched === 0
            ? [...response.data.listings]
            : [...state.data, ...response.data.listings];
        const total_pages = memberFilter ? 1 : response.data.total_pages;

        yield put({
          type: EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
          payload: {
            isLoading: false,
            data: data,
            total_pages: total_pages,
            pages_fetched: state.pages_fetched + 1,
            error: {},
            isFiltering: true,
            filteringType: filterType,
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.fetch_offerings} FAIL`;
      }
    }
  } catch (error) {
    yield put({ type: EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS });
  }
}

/**
Generator function for deleting a plan.
@param {Object} payload - The payload object.
*/
function* deletePlan({ payload }) {
  let state = yield select((state) => state.schedule.plan);
  try {
    if (!is_empty(payload.uuid)) {
      const response = yield call(
        dataProvider.custom_request,
        PLANS_APIS.delete_plan,
        "POST",
        { plan_uuid: payload.uuid }
      );

      if (response.status === 200) {
        const updatedList = state.data.filter(
          (item) => item.uuid !== payload.uuid
        );

        yield put({
          type: EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
          payload: {
            isLoading: false,
            data: updatedList,
            error: {},
            noData: is_empty(updatedList) && is_empty(state.data),
          },
        });

        payload.notify(response.message, "info");
      } else {
        throw `API: ${api.get_plans} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get Plan offerings data
 */
function* getPlanOfferings() {
  const state = yield select((state) => state.schedule.plan);
  try {
    if (!state.isLoading) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
        payload: { isLoading: true, data: [...state.data], error: {} },
      });
      const response = yield call(
        dataProvider.custom_request,
        `${PLANS_APIS.get_plans}`,
        "GET"
      );

      if (response.status === 200) {
        yield put({
          type: EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...response.data.dropdown_plans],
            error: {},
            noData: is_empty(response.data.listings) && is_empty(state.data),
          },
        });
      } else {
        throw `API: ${api.get_plans} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
      payload: {
        isLoading: false,
        data: [...state.data],
        error: error,
        noData: state.noData,
      },
    });
  }
}

/**
 * Worker Saga: to get members data
 */
function* getMembers() {
  const state = yield select((state) => state.schedule.members);
  try {
    yield put({
      type: EXLY_SCHEDULELIST__SET_MEMBERS,
      payload: { data: [...state.data], error: {} },
    });
    const response = yield call(
      dataProvider.custom_request,
      api.get_assignee_list,
      "GET",
      { page: "all" }
    );
    if (response.status === 200) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_MEMBERS,
        payload: {
          data: response.data.assignment_members,
          error: {},
        },
      });
    } else {
      throw `API: ${api.fetch_offerings} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_MEMBERS,
      payload: {
        data: [...state.data],
        error: error,
      },
    });
  }
}

function* scheduleSaga() {
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS, getLiveOfferings);
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS_TYPE,
    getLiveFilteredOfferings
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_DELETE_LIVE_OFFERINGS,
    deleteLiveOffering
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
    getDraftOfferings
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS_TYPE,
    getDraftFilteredOfferings
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_DELETE_DRAFT_OFFERINGS,
    deleteDraftOffering
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS,
    getSoldOutOfferings
  );
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS_TYPE,
    getSoldOutFilteredOfferings
  );
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS, getPastOfferings);
  yield takeEvery(
    EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS_TYPE,
    getPastFilteredOfferings
  );
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_PLAN_OFFERINGS, getPlanOfferings);
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_DELETE_PLANS, deletePlan);
  yield takeLatest(EXLY_SCHEDULE__GET_ALL_OFFERINGS, getAllOfferings);
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_MEMBERS, getMembers);
}

export default scheduleSaga;
