import { sales_page_stages } from "../../constants";

export const sales_page_home_tab_ids = {
  live: `${sales_page_stages.published}`,
  drafts: [
    sales_page_stages.template_selecting_stage,
    sales_page_stages.details_editing_stage,
  ].join(","),
};
export const sales_page_home_tabs = {
  [sales_page_home_tab_ids.live]: {
    label: "Published",
    id: sales_page_home_tab_ids.live,
    emptyTitle: "Create a Landing Page",
    emptySubtitle:
      "As soon as we have a new landing page, it will show up here. View the video to learn more.",
    priority: 0,
  },
  [sales_page_home_tab_ids.drafts]: {
    label: "Drafts",
    id: sales_page_home_tab_ids.drafts,
    emptyTitle: "No drafts yet",
    emptySubtitle: "All your drafts will be displayed here.",
    priority: 1,
  },
};

export const default_sales_page_stage_id = sales_page_home_tab_ids.live;

export const sales_page_home_tabs_list = Object.values(
  sales_page_home_tabs
).sort((a, b) => a.priority - b.priority);

export const filterConfig = [
  {
    source: "title",
    placeholder: "Name",
    type: "input",
  },
  {
    source: "listing__title",
    placeholder: "Offering Name",
    type: "input",
  },
];

export const filterChipFormatter = (filter, value) => {
  switch (filter) {
    case "title__icontains":
      return `Name: ${value}`;
    case "listing__title__icontains":
      return `Offering Name: ${value}`;
    default:
      return null;
  }
};

export const sales_page_editing_stages_keys = {
  selecting_template: 1,
  editing_details: 2,
  published: 3,
};

export const sales_page_editing_stages = {
  [sales_page_editing_stages_keys.selecting_template]: {
    stageText: "On step 1: Select Template",
  },
  [sales_page_editing_stages_keys.editing_details]: {
    stageText: "On step 2: Customization",
  },
  [sales_page_editing_stages_keys.published]: {
    stageText: "Template published",
  },
};
