import React, { useRef, useEffect, useState } from "react";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import classnames from "classnames";
import $ from "jquery";
import "timepicker/jquery.timepicker.min";
import "./DesktopTimePicker.css";
import useStyles from "./DesktopTimePicker.styles";
import { ClearRounded } from "@material-ui/icons";

const empty_options = {};

const DesktopTimePicker = ({
  value,
  onChange,
  desktop_width,
  label,
  helperText,
  placeholder,
  customClasses = {},
  desktop_options = empty_options,
  error: externalError,
  onBlur,
  isClearable,
  disabled = false,
  dropdownWrapperClassName,
}) => {
  const inputContainerRef = useRef(null);
  const inputRef = useRef();
  const [error, setError] = useState(false);
  const classes = useStyles({
    width: desktop_width,
    error,
    showPlaceholder: !value,
  });

  useEffect(() => {
    $(inputRef.current).timepicker({
      className: classnames(classes.dropdown, customClasses.dropdown),
      timeFormat: "h:i A",
      step: 15,
      scrollDefault: "12:00 PM",
    });

    // Dynamically apply width to the dropdown
    $(inputRef.current).on("showTimepicker", () => {
      const dropdown = document.querySelector(`.${classes.dropdown}`);
      const popperWidth =
        inputContainerRef.current?.getBoundingClientRect()?.width;
      if (dropdown && popperWidth) dropdown.style.width = `${popperWidth}px`;
    });
  }, []);

  useEffect(() => {
    $(inputRef.current).timepicker("option", {
      className: classnames(classes.dropdown, customClasses.dropdown),
      step: 15,
      timeFormat: "h:i A",
      scrollDefault: "12:00 PM",
      ...desktop_options,
    });
  }, [desktop_options]);

  useEffect(() => {
    const regex = /^(0*[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i;
    const isValid = !value || regex.test(value);
    const hasError = !isValid || externalError;
    setError(hasError);
  }, [value, externalError]);

  const openDropdown = () => {
    if (!disabled) $(inputRef.current).timepicker("show");
  };

  return (
    <div className={classes.container}>
      {label && <div className={classes.label}>{label}</div>}
      <div
        ref={inputContainerRef}
        className={classnames(classes.wrapper, dropdownWrapperClassName)}
        onClick={openDropdown}
      >
        <QueryBuilderIcon className={classes.clock_icon} />
        <input
          className={classnames(
            "timepicker",
            classes.input,
            customClasses.input
          )}
          type="text"
          ref={inputRef}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => {
            onChange(e.target.value);
            if (onBlur) onBlur(e);
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div className={classes.spacer} />
        {isClearable && (
          <ClearRounded
            className={classnames(classes.clearIcon, !value && classes.hide)}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          />
        )}
        <ArrowDropDownRoundedIcon />
      </div>
      {error && (
        <div className={classnames(classes.error_wrapper, customClasses.error)}>
          <ReportProblemRoundedIcon />
          <span>{helperText || "Invalid time"}</span>
        </div>
      )}
    </div>
  );
};

export default DesktopTimePicker;

/**
 * desktop_options
 * Please check  https://github.com/jonthornton/jquery-timepicker
 */

/*
Usage Example

// NOTE - this useMemo is important

const options = React.useMemo(() => {
    return { minTime: start_time, showDuration: start_time };
}, [start_time]);

<ExlyTimePicker
  value={start_time}
  onChange={set_start_time}
  desktop_width="180px"
  label="Start Time"
  placeholder="Start Time"
/>

<ExlyTimePicker
  value={time}
  onChange={set_time}
  desktop_width="180px"
  label="Select Time"
  placeholder="Session Time"
  desktop_options={options}
/>
*/
