import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  RichTextEditorModal,
  RichTextEditorUtils,
} from "@my-scoot/component-library-legacy";
import { handleFileUpload } from "features/Common/modules/File/utils/File";
import React from "react";
import { FormLabel } from "../FormLabel/FormLabel";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import styles from "./index.module.css";

const { emptyEditorState } = RichTextEditorUtils;

const ExlyRichTextEditorModal = (props) => {
  const { input, meta, fieldLabel = {}, ...rest } = props;
  const isDesktop = useMediaQuery("(min-width: 767px)");

  return (
    <div>
      <FormLabel {...fieldLabel} />

      <RichTextEditorModal
        fullScreen={!isDesktop}
        placeholderWrapperClassName={
          meta.error && meta.touched ? styles.error : ""
        }
        editorState={input.value || emptyEditorState}
        onEditorStateChange={input.onChange}
        minHeight={isDesktop && "50vh"}
        maxHeight={isDesktop && "50vh"}
        imageUploadCallback={handleFileUpload}
        BoxRTEProps={input}
        {...rest}
      />

      {meta.error && meta.touched && (
        <div className={styles.errorText}>
          <WarningRoundedIcon /> {meta.error}
        </div>
      )}
    </div>
  );
};

export default ExlyRichTextEditorModal;
