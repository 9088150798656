import { makeStyles } from "@material-ui/core/styles";
import {
  MobileModal,
  Modal,
  Input,
  Dropdown,
} from "@my-scoot/component-library-legacy";
import OfferingSelect from "common/Components/OfferingSelect";
import { isDeviceIOS } from "common/util";
import constants from "constants/constants";
import * as React from "react";
import { is_desktop } from "utils/Utils";
import { is_empty } from "utils/validations";
import { TRAFFIC_LINK_REDIRECT_TYPE } from "../affiliates.constants";

const useStyles = makeStyles(() => ({
  formWrapper: {
    padding: "24px",
  },
  formTitle: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#272522",
  },
  formDesc: {
    marginTop: "16px",
    marginBottom: "20px",
    fontSize: "18px",
    fontWeight: 300,
    color: "#272522",
  },
  modalPaperClassName: {
    maxWidth: "800px",
  },
  input: {
    marginBottom: "15px",
  },
}));

const TrackingLinkForm = ({
  open,
  onClose,
  onSubmit,
  plansData,
  listingData,
  salesPageData,
  isSubmissionDisabled,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [focusName, setFocusName] = React.useState(false);
  const [focusTarget, setFocusTarget] = React.useState(false);
  const [focusURL, setFocusURL] = React.useState(false);

  let error = "";
  let errorURL = "";
  let errorTarget = "";

  if (focusName && !state.affiliateName) error = "Required";
  else if (
    focusName &&
    !/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/.test(state.affiliateName) &&
    (state.affiliateName.trim().length === 0 ||
      !state.affiliateName.endsWith(" "))
  )
    error = "Invalid Name";
  else error = "";

  errorTarget = focusTarget && !state.target ? "Required" : "";
  errorURL = focusURL && !state.url ? "Required" : "";

  const isDesktop = window.screen.width > constants.mobile_width;

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    error = "";
    errorTarget = "";
    errorURL = "";
    setState({});
    onClose && onClose();
  };

  React.useEffect(() => {
    if (!isDeviceIOS() || is_desktop) return;

    let prevScrollPos = window.scrollY;
    document.body.style.marginTop = `${-window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.top = 0;
    document.body.style.bottom = 0;
    document.body.style.left = 0;
    document.body.style.right = 0;

    return () => {
      document.body.style.position = "static";
      document.body.style.top = "auto";
      document.body.style.bottom = "auto";
      document.body.style.left = "auto";
      document.body.style.right = "auto";
      document.body.style.marginTop = 0;
      window.scrollTo(0, prevScrollPos);
    };
  }, []);

  const Form = (
    <div className={classes.formWrapper}>
      <div className={classes.formTitle}>
        Create a unique link that directs users to your Homepage (Webpage) or
        Offering pages.
      </div>
      <div className={classes.formDesc}>
        Share this tracking link on a platform, in promotional messages,
        marketing campaigns, or with an affiliate to track the source traffic.
      </div>

      <Input
        wrapperClassName={classes.input}
        label="Source Name"
        placeholder="Source Name*"
        value={state.affiliateName}
        onChange={(e) => handleChange("affiliateName", e.currentTarget.value)}
        error={!is_empty(error)}
        helperText={error}
        fullWidth
        onBlur={() => setFocusName(true)}
      />

      <Dropdown
        size="small"
        formControlClassName={classes.input}
        placeholder="Link directs to*"
        value={state.target}
        handleChange={(value) => handleChange("target", value)}
        dropdownLabel={{
          label: "Link directs to",
        }}
        options={Object.values(TRAFFIC_LINK_REDIRECT_TYPE).filter(
          (item) =>
            item.value !== TRAFFIC_LINK_REDIRECT_TYPE.SALES_PAGE.value ||
            !is_empty(salesPageData)
        )}
        fullWidth
        error={!is_empty(errorTarget)}
        helperText={errorTarget}
        onBlur={() => {
          setFocusTarget(true);
        }}
      />

      {state.target &&
        state.target === TRAFFIC_LINK_REDIRECT_TYPE.PLAN.value && (
          <Dropdown
            size="small"
            placeholder="Plan"
            value={state.url}
            handleChange={(value) => handleChange("url", value)}
            dropdownLabel={{ label: "Plan" }}
            options={(plansData || []).map((item, index) => ({
              ...item,
              id: index,
              label: item.title,
              value: item.uuid,
            }))}
            fullWidth
            error={!is_empty(errorURL)}
            helperText={errorURL}
            onBlur={() => setFocusURL(true)}
          />
        )}

      {state.target &&
        state.target === TRAFFIC_LINK_REDIRECT_TYPE.LISTING.value && (
          <OfferingSelect
            placeholder="Offering"
            value={state.url}
            onChange={(value) => handleChange("url", value)}
            label="Offering"
            mobileModalTitle="Select Offerings"
            options={(listingData || []).map((item, index) => ({
              ...item,
              id: index,
              label: item.title,
              value: item.title,
            }))}
            fullWidth
            error={!is_empty(errorURL)}
            helperText={errorURL}
            onBlur={() => setFocusURL(true)}
            showStatus
          />
        )}

      {state.target === TRAFFIC_LINK_REDIRECT_TYPE.SALES_PAGE.value && (
        <Dropdown
          size="small"
          placeholder="Select"
          value={state.url}
          onChange={(e) => {
            const value = e.target.value;
            handleChange("url", value);
          }}
          dropdownLabel={{
            label: "Select landing page",
          }}
          mobileModalTitle="Select Offerings"
          options={(salesPageData || []).map((item, index) => ({
            ...item,
            id: index,
            label: item.title,
            value: item,
          }))}
          fullWidth
          error={!is_empty(errorURL)}
          helperText={errorURL}
          onBlur={() => setFocusURL(true)}
        />
      )}
    </div>
  );

  return isDesktop ? (
    <Modal
      title="Create a tracking link"
      primaryBtnText="Create"
      open={open}
      onClose={handleClose}
      showSecondaryBtn={false}
      onPrimaryBtnClick={() => onSubmit(state)}
      primaryBtnProps={{ disabled: isSubmissionDisabled }}
      modalPaperClassName={classes.modalPaperClassName}
    >
      {Form}
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={handleClose}
      primaryAction={{
        label: "Create",
        onClick: () => onSubmit(state),
      }}
      header="Create a tracking link"
    >
      {Form}
    </MobileModal>
  );
};

export default TrackingLinkForm;
