import {
  DEFAULT_DARK_TEXT_COLOR,
  DEFAULT_LIGHT_TEXT_COLOR,
  DEFAULT_TEXT_COLOR,
} from "../constants/Colors.constants";

/**
 * This util converts any hex code color e.g. #fff or #ffffff into its
 * respective RGB color and returns the rgb string of the color
 * @param hexArg The hex based color string
 * @returns The rgb color of the hex color
 */
export const hexToRgb = (hexArg: string): string => {
  const hex = hexArg || "";
  // Check if the input is already in RGB format
  const rgbPattern = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*/;
  if (rgbPattern.test(hex)) {
    // If already in RGB format, return it as is
    return hex;
  }

  // Remove the hash at the start if it's there
  const newHex = hex.replace(/^#/, "");

  // Parse the hex string
  let r: number;
  let g: number;
  let b: number;

  if (newHex.length === 3) {
    // If the newHex is in shorthand format (e.g., #fff)
    r = parseInt(newHex[0] + newHex[0], 16);
    g = parseInt(newHex[1] + newHex[1], 16);
    b = parseInt(newHex[2] + newHex[2], 16);
  } else if (newHex.length === 6) {
    // If the newHex is in full format (e.g., #ffffff)
    r = parseInt(newHex.slice(0, 2), 16);
    g = parseInt(newHex.slice(2, 4), 16);
    b = parseInt(newHex.slice(4, 6), 16);
  } else {
    return "";
  }

  // Return the RGB string in the desired format
  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * This function returns rgb color data
 * @param rgbCssString rgb string
 * @returns rgb color data
 * {
 *  isRgb: if rgb string provided is rgb or not using regex matching,
 *  rgbColorObj: { r,g,b }
 * }
 *  @summary
 *  where
 *  r: red value b/w 0-255
 *  g: red value b/w 0-255
 *  b: red value b/w 0-255
 */
export const getRgbCssStringColorData = (rgbCssString: string) => {
  const rgbRegexPattern = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
  const match = rgbCssString.match(rgbRegexPattern);
  const [, r, g, b] = match || [];
  return {
    isRgb: !!match,
    rgbColorObj: { r: +r, g: +g, b: +b },
  };
};

/**
 * This calculates the complimentary color based on the
 * percieved brightness of the rgb color of that section
 * percievedBrightness = (299R+587G+114B)/1000
 * if(percievedBrightness > 128) `darkColorHex` returned else `lightColorHex`
 * if not rgb string then `defaultColorHex` is returned
 * @param arg => { rgbCssString, lightColorHex, darkColorHex, defaultColorHex }
 * rgbCssString is rgb css string,
 * lightColorHex is  light text color for darker backgrounds in hex,
 * darkColorHex is dark text color for lighter backgrounds in hex,
 * defaultColorHex is default text color in hex,
 * @returns complimentary hex color string based on above calculation
 */
export const getComplimentaryHexColorFromRgbCssString = ({
  rgbCssString,
  lightColorHex = DEFAULT_LIGHT_TEXT_COLOR,
  darkColorHex = DEFAULT_DARK_TEXT_COLOR,
  defaultColorHex = DEFAULT_TEXT_COLOR,
}: {
  rgbCssString: string;
  lightColorHex?: string;
  darkColorHex?: string;
  defaultColorHex?: string;
}) => {
  const rgbString = hexToRgb(rgbCssString);
  const { isRgb, rgbColorObj } = getRgbCssStringColorData(rgbString);
  if (isRgb) {
    const { r, g, b } = rgbColorObj;
    const percievedBrightness = (299 * r + 587 * g + 114 * b) / 1000;
    if (percievedBrightness > 128) return darkColorHex;
    else return lightColorHex;
  } else return defaultColorHex;
};

/**
 * This util check if the given color is hex or not
 * @param color string - given a color
 * @returns boolean check if the color is hex
 */
export const isHexColor = (color: string) => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/.test(color);
};

/**
 * This util convers RGBA color to respective Hex color
 * @param r red component of the RGBA color
 * @param g green component of the RGBA color
 * @param b blue component of the RGBA color
 * @param a alpha component of the RGBA color
 * @returns The corresponding Hex color
 */
export const rgbaToHex = (r: number, g: number, b: number, a: number = 1) => {
  const toHex = (n: number) => n.toString(16).padStart(2, "0").toUpperCase();
  const alpha = Math.round(a * 255);
  const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

  // Only add alpha if it's less than full opacity
  return a < 1 ? `${hex}${toHex(alpha)}` : hex;
};

export const rgbaStringToRgba = (rgba: string) => {
  const rgbaMatch = rgba.match(
    /^rgba?\((\d{1,3}), (\d{1,3}), (\d{1,3})(?:, (\d?\.?\d+))?\)$/,
  );
  if (rgbaMatch) {
    const [, r, g, b, a = "1"] = rgbaMatch;
    return { r: parseInt(r), g: parseInt(g), b: parseInt(b), a: parseFloat(a) };
  }
  return null;
};

/**
 * Converts a RGB color to hex
 * This util first checks if the color is hex already, if so, then it returns the hex
 * else converts and returns it.
 * Incase color is invalid it return empty string
 * Refer to this url - https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 * @param color string in rgb
 * @returns color string in hex
 */
export const convertColorRGBToHex = (color: string) => {
  if (isHexColor(color)) {
    return color;
  }

  const rgba = rgbaStringToRgba(color);
  if (rgba) {
    const { r, g, b, a } = rgba;
    return rgbaToHex(r, g, b, a);
  }

  return "";
};
