import React from "react";
import styles from "./FormLabel.module.css";
import { checkIsStringEmpty } from "features/Common/modules/DataTypes/Strings.utils";
import classnames from "classnames";

export const FormLabel = ({
  className = "",
  textClass = "",
  text,
  required,
}) => {
  if (checkIsStringEmpty(text)) return null;

  return (
    <div className={classnames(`mb-1`, className)}>
      <span className={classnames(styles.labelField, textClass)}>{text}</span>
      {required && <span className={`text-danger`}>*</span>}
    </div>
  );
};
