import {
  ActionField,
  DateTimeField,
  TooltipHeaderField,
  WhatsAppPhoneField,
} from "common/Components/TableFields/TableFields";
import constants from "constants/constants";
import { DATE_WITH_FULL_MONTH_FORMAT } from "constants/date-fns/dateTime.constant";
import { RECEIPT_URL } from "constants/schedule";
import { format } from "date-fns";
import { TRANSACTION_TYPES } from "features/Crm/modules/Transactions/Transactions.constants";
import { getTransactionTypeConfig } from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import React from "react";
import MoneyField from "ui/modules/MoneyField";
import { getSignedS3DownlaodURL } from "utils/getAccessS3Url";
import { is_empty } from "utils/validations";
import { listingTypeOptions } from "../AllTransaction/AllTransactionTableConfig";
import module_styles from "../Style.module.css";
import { RefundActionField, StatusField } from "./TableFields/TableFields";
import {
  FIELD_DATA,
  FORM_DATA,
  HEADER_NOTE,
  REFUND_STATUS_ENUM,
} from "./refund.constant";

export const getTableConfig = [
  {
    field: FIELD_DATA.customer_name,
    headerName: FORM_DATA.customer_name,
    avatar: true,
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
    sortable: false,
    fixed: "left",
    width: "200px",
  },
  {
    field: FIELD_DATA.customer_phone,
    headerName: FORM_DATA.customer_phone,
    valueFormatter: (record) => (
      <WhatsAppPhoneField record={record} source={FIELD_DATA.customer_phone} />
    ),
    sortable: false,
    isCollapsed: true,
    width: "140px",
  },
  {
    field: FIELD_DATA.customer_email,
    headerName: FORM_DATA.customer_email,
    emptyField: "N/A",
    sortable: false,
    width: "160px",
    columnClassName: "word_break",
    isCollapsed: true,
  },
  {
    field: FIELD_DATA.listing_title,
    headerName: FORM_DATA.listing_title,
    sortable: false,
    isCollapsed: true,
    width: "120px",
  },
  {
    field: FIELD_DATA.listing_type,
    headerName: FORM_DATA.listing_type,
    valueFormatter: (record) =>
      constants.schedule_type[record.listing_type].name ?? "N/A",
    sortable: false,
    isCollapsed: true,
    width: "150px",
  },
  {
    field: FIELD_DATA.transaction_amount,
    headerName: FORM_DATA.transaction_amount,
    valueFormatter: (record) => (
      <MoneyField
        record={record}
        source={FIELD_DATA.transaction_amount}
        creator_sales={false}
      />
    ),
    sortable: false,
    width: "120px",
  },
  {
    field: FIELD_DATA.transaction_id,
    headerName: FORM_DATA.transaction_id,
    emptyField: "N/A",
    sortable: false,
    isCollapsed: true,
    width: "120px",
  },
  {
    field: FIELD_DATA.transaction_date,
    headerName: FORM_DATA.transaction_date,
    valueFormatter: (record) => (
      <DateTimeField record={record} source={FIELD_DATA.transaction_date} />
    ),
    sortable: false,
    noWrap: true,
  },
  {
    field: FIELD_DATA.refund_amount,
    headerName: FORM_DATA.refund_amount,
    valueFormatter: (record) => (
      <MoneyField
        record={record}
        source={FIELD_DATA.refund_amount}
        creator_sales={false}
      />
    ),
    width: "120px",
    sortable: false,
  },
  {
    field: FIELD_DATA.refund_date,
    headerName: FORM_DATA.refund_date,
    valueFormatter: (record) => (
      <DateTimeField record={record} source={FIELD_DATA.refund_date} />
    ),
    sortable: false,
    noWrap: true,
  },
  {
    field: FIELD_DATA.reference_no,
    headerName: FORM_DATA.arn_info,
    emptyField: "N/A",
    sortable: false,
    isCollapsed: true,
  },
  {
    field: FIELD_DATA.credit_note_link,
    headerName: FORM_DATA.credit_invoice,
    renderHeader: () => (
      <TooltipHeaderField
        label={FORM_DATA.credit_invoice}
        note={HEADER_NOTE.credit_note}
      />
    ),
    valueFormatter: (record) => (
      <ActionField
        label={
          is_empty(record[FIELD_DATA.credit_note_link])
            ? "N/A"
            : "View Credit Invoice"
        }
        className={
          is_empty(record[FIELD_DATA.credit_note_link]) &&
          module_styles.refund_history_credit_note
        }
        record={record}
        onClick={({ record }) =>
          getSignedS3DownlaodURL(record, FIELD_DATA.credit_note_link)
        }
      />
    ),
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    isCollapsed: true,
    width: "160px",
  },
  {
    field: FIELD_DATA.receipt_url,
    headerName: FORM_DATA.refund_payment_invoice,
    renderHeader: () => (
      <TooltipHeaderField
        label={FORM_DATA.refund_payment_invoice}
        note={HEADER_NOTE.refund_payment_invoice}
      />
    ),
    valueFormatter: (record) =>
      is_empty(record[RECEIPT_URL]) ? (
        "N/A"
      ) : (
        <ActionField
          label="View Invoice"
          record={record}
          onClick={({ record }) => getSignedS3DownlaodURL(record)}
        />
      ),
    noWrap: true,
    sortable: false,
    isCollapsed: true,
    width: "200px",
  },
  {
    field: FIELD_DATA.status,
    headerName: FORM_DATA.refund_status,
    valueFormatter: (record) => (
      <StatusField record={record} source={FIELD_DATA.status} />
    ),
    sortable: false,
    isCollapsed: true,
    width: "120px",
  },
  getTransactionTypeConfig(),
  {
    field: "",
    headerName: FORM_DATA.action,
    valueFormatter: (record) => <RefundActionField record={record} />,
    sortable: false,
    isCollapsed: true,
    noWrap: true,
    fixed: "right",
  },
];

export const filterConfig = () => [
  {
    source: FIELD_DATA.customer_name,
    placeholder: FORM_DATA.customer_name,
    type: "input",
    disableContains: true,
  },
  {
    source: FIELD_DATA.customer_email,
    placeholder: FORM_DATA.customer_email,
    type: "input",
    disableContains: true,
  },
  {
    source: FIELD_DATA.customer_phone_number,
    placeholder: FORM_DATA.customer_phone,
    type: "input",
    disableContains: true,
  },
  {
    source: FIELD_DATA.listing_title,
    placeholder: FORM_DATA.listing_title,
    type: "input",
    disableContains: true,
  },
  {
    source: FIELD_DATA.listing_type,
    placeholder: FORM_DATA.listing_type,
    options: listingTypeOptions,
    type: "dropdown",
  },
  {
    source: FIELD_DATA.status,
    placeholder: FORM_DATA.refund_status,
    options: Object.entries(REFUND_STATUS_ENUM).map(([key, value]) => ({
      label: value.toLowerCase(),
      value: key,
    })),
    type: "dropdown",
  },
  // getTransactionTypeFilter(), commenting for now to be added later by 1/5/24
  {
    source: `${FIELD_DATA.created_at}__start_range`,
    placeholder: `${FORM_DATA.refund_date}(FROM)`,
    type: "date",
  },
  {
    source: `${FIELD_DATA.created_at}__end_range`,
    placeholder: `${FORM_DATA.refund_date}(To)`,
    type: "date",
  },
];

export const tableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case `${FIELD_DATA.customer_name}__icontains`:
      return `${FORM_DATA.customer_name}: ${value}`;
    case `${FIELD_DATA.customer_email}__icontains`:
      return `${FORM_DATA.customer_email}: ${value}`;
    case `${FIELD_DATA.customer_phone_number}__icontains`:
      return `${FORM_DATA.customer_phone}: ${value}`;
    case `${FIELD_DATA.listing_title}__icontains`:
      return `${FORM_DATA.listing_title}: ${value}`;
    case FIELD_DATA.listing_type: {
      const listing = listingTypeOptions?.find((item) => item.id == value);
      return `${FORM_DATA.listing_type}: ${listing?.name}`;
    }
    case FIELD_DATA.status: {
      const status = Object.entries(REFUND_STATUS_ENUM)?.find(
        ([key]) => key == value
      );
      return `${FORM_DATA.refund_status}: ${status[1].toLowerCase()}`;
    }
    case FIELD_DATA.transaction_type: {
      const label = TRANSACTION_TYPES[value].label;
      return `${FORM_DATA.transaction_type}: ${label}`;
    }
    case `${FIELD_DATA.created_at}__start_range`:
      return `${FORM_DATA.refund_date}(FROM): ${format(
        new Date(value),
        DATE_WITH_FULL_MONTH_FORMAT
      )}`;
    case `${FIELD_DATA.created_at}__end_range`:
      return `${FORM_DATA.refund_date}(FROM): ${format(
        new Date(value),
        DATE_WITH_FULL_MONTH_FORMAT
      )}`;
    default:
      return;
  }
};
