import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { formatDistance, isSameDay, isSameYear } from "date-fns";
import React from "react";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { LazyLoadedPollVotersModal } from "../../../features/BrandedCommunity/modules/PollVotersModal/LazyLoadedPollVotersModal";
import { usePollVotersModal } from "../../../features/BrandedCommunity/modules/PollVotersModal/utils/usePollVotersModal";
import { DEFAULT_CREATOR_THEME } from "../../../features/Common/modules/Theme/constants/Theme.constants";
import { CreatorThemeContext } from "../../../features/Common/modules/Theme/CreatorThemeContext";
import { pluralise } from "../../../utils/common/dataTypes/string";
import { formatDateTime } from "../../../utils/common/dateTime";
import { classNames } from "../../../utils/common/styling";
import Button from "../../common/Button/Button";
import ReadMoreWrapper from "../../common/ReadMoreWrapper/ReadMoreWrapper";
import LazyLoadedLikedByMembersListModal from "../LikedByMembersListModal/LazyLoadedLikedByMembersListModal";
import { LazyLoadedPostDocs } from "../PostDocs/LazyLoadedPostDocs";
import LazyLoadedPostImage from "../PostImage/LazyLoadedPostImage";
import LazyLoadedPostOffering from "../PostOffering/LazyLoadedPostOffering";
import LazyLoadedPostVideo from "../PostVideo/LazyLoadedPostVideo";
import LazyLoadedCommentEditor from "./components/CommentEditor/LazyLoadedCommentEditor";
import { USER_AVATAR_PREFERENCE } from "./components/CommenterImg/constants/CommenterImg.constants";
import LazyLoadedCommentSection from "./components/CommentSection/LazyLoadedCommentSection";
import LinkPreview from "./components/LinkPreview/LinkPreview";
import MoreButtonAndMenu from "./components/MoreButtonAndMenu/MoreButtonAndMenu";
import PinnedStatusIcon from "./components/PinnedStatusIcon/PinnedStatusIcon";
import { FlaggedTag } from "./modules/FlaggedTag/FlaggedTag";
import { LazyLoadedPoll } from "./modules/Poll/LazyLoadedPoll";
import { getThemedStyles, styles } from "./Post.styles";
import { IPostProps } from "./Post.types";
import { POST_SHARING_TYPES } from "../../../features/BrandedCommunity/constants/BrandedCommunity.constants";
import { PostShareModal } from "./modules/PostShareModal/PostShareModal";

const Post = ({
  // props from hook, used by Post directly
  data,
  commentCount,
  onLikeClick,
  openLikedByMembersListModal,
  lazyLoadedLikedByMembersListModalProps,
  onPinClick,
  enableComments = false,
  canEdit,
  onEditClick,
  canDelete,
  onDeleteClick,
  currentUserIsPostAuthor,
  canClickOnPostAuthor,
  onPostAuthorClick,
  canViewVoters,
  notify,
  votersFetcher,
  canReport,
  onReport,
  canReportComment,
  onReportComment,
  showReportedContent,
  canDuplicatePost,
  onDuplicatePostClick,

  // props from hook, used by components inside Post
  postOfferingProps,
  postImgProps,
  postVideoProps,
  postDocProps,
  postPollProps,
  onShowCommentInputClick,
  onShowCommentsClick,
  lazyLoadedCommentEditorProps,
  lazyLoadedCommentSectionProps,
  onSharePost,
  postShareModalProps,

  // props from parent component, used by Post directly
  classes = {},
  userImgSrc,
  collapsedHeight = 50,
  theme = DEFAULT_CREATOR_THEME,
  linkPreviewApiKey,
  errorLogger,
}: IPostProps) => {
  const themedStyles = getThemedStyles({ theme });
  const now = new Date();
  const createdAt = new Date(data.created_at);
  const isSameDayAsToday = isSameDay(now, createdAt);
  const isSameYearAsCurrent = isSameYear(now, createdAt);
  const createdAtString = isSameDayAsToday
    ? formatDistance(createdAt, now, { addSuffix: true })
    : formatDateTime(createdAt, {
        showTime: true,
        showYear: !isSameYearAsCurrent,
      });
  const {
    isOpen: isPollVotersModalOpen,
    open: openPollVotersModal,
    close: closePollVotersModal,
    data: pollVotersModalData,
  } = usePollVotersModal(data);

  const { metadata, is_flagged: isFlagged } = data;
  const {
    preview_urls: previewUrls,
    post_sharing_type: postSharingType,
    auto_expand: autoExpand = true,
  } = metadata ?? {};
  const canSharePost =
    postSharingType === POST_SHARING_TYPES.PUBLIC ||
    postSharingType === POST_SHARING_TYPES.WITHIN_COMMUNITY;
  const [firstUrl] = previewUrls ?? []; // only showing first link preview for now
  const showLikeAndCommentCountContainer = data.likes_count || commentCount;

  const postTextProps = {
    id: `post-text--${data.post_uuid}`,
    className: styles.postText,
    dangerouslySetInnerHTML: { __html: data.post_body ?? "" },
  };
  const isPollPost = data.post_type === POST_TYPE_IDS_ENUM.POLL;

  const { commenterDetails } = lazyLoadedCommentEditorProps;

  /**
   * Option for share needs to be added over here
   * i.e isSingleOptionAvailable = !enableSharing && !enableComments;
   */
  const isSingleOptionAvailable = !enableComments;

  return (
    <CreatorThemeContext.Provider value={theme}>
      <div
        className={classNames(
          styles.container,
          showReportedContent && isFlagged && styles.flaggedContainer,
        )}
      >
        <div className={styles.header}>
          <div className={styles.postAuthorDetails}>
            <img
              className={styles.postAuthorPic}
              alt={data.name}
              src={data.profile_pic}
              width={46}
              height={46}
            />
            <div className={styles.authorNameAndCreatedAtDateContainer}>
              <div
                className={classNames(
                  styles.authorName,
                  canClickOnPostAuthor && styles.authorNameClickable,
                )}
                onClick={onPostAuthorClick}
              >
                {data.name}
                {currentUserIsPostAuthor ? (
                  <span className={styles.authorIsCurrentUserIndicator}>
                    {" (You)"}
                  </span>
                ) : null}
              </div>
              <div className={styles.createdAtDate}>{createdAtString}</div>
            </div>
          </div>
          <div className={styles.pinAndMoreMenuBtnContainer}>
            {showReportedContent && isFlagged && (
              <FlaggedTag label="Post flagged" className={styles.flaggedTag} />
            )}
            {onPinClick ? (
              <PinnedStatusIcon
                onClick={onPinClick}
                isPinned={data.is_pinned}
              />
            ) : null}
            <MoreButtonAndMenu
              MoreButtonComponent={MoreHorizIcon}
              canEdit={canEdit}
              onEditClick={onEditClick}
              canDelete={canDelete}
              onDeleteClick={onDeleteClick}
              canViewVoters={canViewVoters}
              onViewVotes={openPollVotersModal}
              canReport={canReport}
              onReport={onReport}
              canDuplicatePost={canDuplicatePost}
              onDuplicatePostClick={onDuplicatePostClick}
              deleteCtaText={isPollPost ? "Delete poll" : "Delete"}
            />
          </div>
        </div>
        {data.post_body && (
          <div className={styles.postTextContainer}>
            {data.is_pinned || autoExpand ? (
              <div {...postTextProps} />
            ) : (
              <ReadMoreWrapper
                childDivProps={postTextProps}
                collapsedHeight={collapsedHeight}
                ExpandToggleButton={({ onClick, isExpanded }) =>
                  isExpanded ? null : (
                    <div className={styles.seeMoreButtonContainer}>
                      <div
                        onClick={onClick}
                        className={classNames(
                          styles.seeMoreButton,
                          classes.seeMoreButton,
                        )}
                      >
                        ...see more
                      </div>
                    </div>
                  )
                }
              />
            )}
          </div>
        )}
        <LazyLoadedPostOffering {...postOfferingProps} />
        <LazyLoadedPostImage {...postImgProps} />
        <LazyLoadedPostVideo {...postVideoProps} />
        <LazyLoadedPoll {...postPollProps} onClickVotes={openPollVotersModal} />
        <LazyLoadedPostDocs
          {...postDocProps}
          classes={{
            container: classNames(
              styles.postDocContainer,
              !showLikeAndCommentCountContainer &&
                styles.postDocContainerMargin,
            ),
          }}
        />

        {firstUrl ? (
          <LinkPreview
            url={firstUrl}
            apiKey={linkPreviewApiKey}
            errorLogger={errorLogger}
          />
        ) : null}

        {showLikeAndCommentCountContainer ? (
          <div className={styles.likeAndCommentCountContainer}>
            {data.likes_count ? (
              <div
                className={styles.likeCountContainer}
                onClick={openLikedByMembersListModal}
              >
                <div
                  className={classNames(
                    styles.likesCountIconContainer,
                    themedStyles.likesCountIconContainer,
                    "d-flex align-items-center justify-content-center rounded-circle mr-2",
                  )}
                >
                  <FavoriteRoundedIcon
                    htmlColor="white"
                    className={styles.heartIcon}
                  />
                </div>
                <div
                  className={classNames(
                    styles.likesCount,
                    themedStyles.likesCount,
                  )}
                >
                  {data.likes_count}
                </div>
              </div>
            ) : (
              <div></div>
              // empty div, so that `justify-content: space-between` works properly
            )}
            {enableComments && commentCount ? (
              <Button
                color="unset"
                size="unset"
                className={styles.commentsCount}
                onClick={onShowCommentsClick}
              >
                {commentCount} {pluralise("comment", commentCount)}
              </Button>
            ) : (
              <div></div>
              // empty div, so that `justify-content: space-between` works properly
            )}
          </div>
        ) : null}
        <div className={styles.reactionStripContainer}>
          <div
            className={classNames(
              styles.reactionBtn,
              /**
               * justifyContent - flex-start for like button
               * in case its the only option available.
               * otherwise its center justified as others.
               **/
              isSingleOptionAvailable
                ? styles.justifyContentStart
                : styles.justifyContentCenter,
            )}
            onClick={onLikeClick}
          >
            {data.has_liked_post ? (
              <FavoriteRoundedIcon
                className={classNames(
                  styles.likedByUserIcon,
                  themedStyles.likedByUserIcon,
                )}
              />
            ) : (
              <FavoriteBorderRoundedIcon />
            )}
            Like
          </div>
          {enableComments && (
            <>
              <div className={styles.verticalSeparator} />
              <div
                className={classNames(
                  styles.reactionBtn,
                  styles.justifyContentCenter,
                )}
                onClick={onShowCommentInputClick}
              >
                <AddCommentOutlinedIcon
                  className={styles.showCommentInputIcon}
                />
                Comment
              </div>
            </>
          )}
          {canSharePost && (
            <>
              <div className={styles.verticalSeparator} />
              <div
                className={classNames(
                  styles.reactionBtn,
                  styles.justifyContentCenter,
                )}
                onClick={onSharePost}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M15.8846 8V13.25C15.8846 14.4907 14.8728 15.5 13.6291 15.5H3.1032C1.85942 15.5 0.847656 14.4907 0.847656 13.25V2.75C0.847656 1.50928 1.85942 0.5 3.1032 0.5H8.36613C8.7817 0.5 9.11798 0.835449 9.11798 1.25C9.11798 1.66455 8.7817 2 8.36613 2H3.1032C2.68836 2 2.35135 2.33618 2.35135 2.75V13.25C2.35135 13.6638 2.68836 14 3.1032 14H13.6291C14.0439 14 14.3809 13.6638 14.3809 13.25V8C14.3809 7.58545 14.7172 7.25 15.1328 7.25C15.5483 7.25 15.8846 7.58545 15.8846 8ZM15.8267 0.963669C15.7506 0.779877 15.604 0.633713 15.4198 0.557724C15.328 0.519867 15.2305 0.5 15.1328 0.5H11.3735C10.9579 0.5 10.6217 0.835449 10.6217 1.25C10.6217 1.66455 10.9579 2 11.3735 2H13.3177L6.33085 8.96973C6.03716 9.2627 6.03716 9.7373 6.33085 10.0303C6.4777 10.1768 6.67007 10.25 6.86243 10.25C7.0548 10.25 7.24717 10.1768 7.39401 10.0303L14.3809 3.06055V5C14.3809 5.41455 14.7172 5.75 15.1328 5.75C15.5483 5.75 15.8846 5.41455 15.8846 5V1.25C15.8846 1.15245 15.8647 1.05522 15.8267 0.963669Z"
                    fill="#686664"
                  />
                </svg>
                Share
              </div>
            </>
          )}
        </div>
        {enableComments && (
          <>
            <LazyLoadedCommentEditor
              {...lazyLoadedCommentEditorProps}
              commenterDetails={{
                ...commenterDetails,
                profilePicUrl: userImgSrc,
              }}
              creatorTheme={theme}
              classes={{ container: styles.replyTextAreaContainer }}
              userAvatarPreference={USER_AVATAR_PREFERENCE.PROFILE_PICTURE}
            />
            <LazyLoadedCommentSection
              {...lazyLoadedCommentSectionProps}
              classes={{ container: styles.commentSectionContainer }}
              userAvatarPreference={USER_AVATAR_PREFERENCE.PROFILE_PICTURE}
              creatorTheme={theme}
              userImgSrc={userImgSrc}
              canReport={canReportComment}
              onReport={onReportComment}
              showReported={showReportedContent}
            />
          </>
        )}
        <LazyLoadedLikedByMembersListModal
          {...lazyLoadedLikedByMembersListModalProps}
        />
        <LazyLoadedPollVotersModal
          data={pollVotersModalData}
          open={isPollVotersModalOpen}
          onClose={closePollVotersModal}
          errorLogger={errorLogger}
          notify={notify}
          votersFetcher={votersFetcher}
        />
        <PostShareModal {...postShareModalProps} />
      </div>
    </CreatorThemeContext.Provider>
  );
};

export default Post;
