// @ts-nocheck
import React from "react";
import { FC, useState, useEffect, useRef, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useTranslate, setSidebarVisibility } from "react-admin";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import { Tooltip } from "@my-scoot/component-library-legacy";
import SubMenu from "../SubMenu/SubMenu";

import useStyles from "./ParentMenu.styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import classnames from "classnames";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import moduleStyles from "./ParentMenu.module.css";

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  rightIcon?: ReactElement;
  sidebarIsOpen: boolean;
  initialState?: any;
}

const ParentMenu: FC<Props> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  rightIcon,
  children,
  dense,
  initialState = {},
  menuItemClassName,
}) => {
  const translate = useTranslate();
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const [state, setState] = useState(initialState);
  const parentRef = useRef();
  const classes = useStyles({ isActive: isOpen, isDesktop, sidebarIsOpen });

  const toggleState = (id) =>
    setState((prevState: any) => ({ ...initialState, [id]: !prevState[id] }));

  const dispatch = useDispatch();

  const onToggle = () => {
    if (!sidebarIsOpen) dispatch(setSidebarVisibility(true));
    handleToggle();
  };

  useEffect(() => {
    if (!isOpen) setState(initialState);
  }, [isOpen]);

  const updatedChildren = React.Children.map(children, (child) => {
    if (
      child &&
      (child.type === SubMenu || child.type?.displayName === "SubMenu")
    ) {
      return React.cloneElement(child, { state, toggleState });
    }
    return child;
  });

  const header = (
    <MenuItem
      dense={dense}
      button
      onClick={onToggle}
      disableRipple
      classes={{ root: classnames(classes.menuItem, menuItemClassName) }}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      {sidebarIsOpen && (
        <div className={classes.menuItemContent}>
          <div className={moduleStyles.textContentContainer}>
            <div>{translate(name)}</div>
            {rightIcon && rightIcon}
          </div>
          <ListItemIcon className={classes.icon}>
            {!isOpen ? <ExpandMore /> : <ExpandLess />}
          </ListItemIcon>
        </div>
      )}
    </MenuItem>
  );

  return (
    <div ref={parentRef}>
      {isOpen ? (
        header
      ) : (
        <Tooltip
          title={translate(name)}
          placement="right"
          color="primary"
          arrow
        >
          {header}
        </Tooltip>
      )}
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        classes={{ wrapperInner: styles.wrapperInner }}
      >
        <List
          className={classes.collapseList}
          dense={dense}
          component="div"
          disablePadding
        >
          {updatedChildren}
        </List>
      </Collapse>
    </div>
  );
};

export default withComponentLibraryTheme(ParentMenu);
