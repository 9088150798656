import constants from "constants/constants";
import { api, dataProvider } from "data";
import { initiateJuspay } from "features/Payment/utils/Payment.utils";
import { is_empty } from "utils/validations";
import {
  EXLY_MARKETING__ADS__CHANGE_AD_CREATION_STEP,
  EXLY_MARKETING__ADS__GET_CAMPAIGNS,
  EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL,
  EXLY_MARKETING__ADS__GET_PERSONAS,
  EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
  EXLY_MARKETING__ADS__SET_API_PAYLOAD,
  EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
  EXLY_MARKETING__ADS__SET_CAMPAIGN_UUID,
  EXLY_MARKETING__ADS__SET_PERSONA_REACH,
  EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
  EXLY_MARKETING__ADS__SET_PROCESSING,
  EXLY_MARKETING__ADS__SET_ACTIVE_EDIT_CREATIVE_TAB,
} from "../redux/actions";

import { TopLeft } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/TopLeft";
import { TopRight } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/TopRight";
import { Dialogue } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/Dialogue";
import { TopLeftCut } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/TopLeftCut";
import { RightRibbon } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/RightRibbon";
import { TopRibbon } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/TopRibbon";
import { RightPointer } from "ui/pages/marketing/MarketingCreatives/assets/Stickers/dynamic/RightPointer";
import { marketingCreativeStickersKeys } from "../MarketingCreatives/data";
import { getAuthData } from "utils/AuthUtil";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { CTA_OPTIONS } from "features/Ads/constants/Ads.constants";
import { formatNumber } from "features/Common/modules/DataTypes/utils/strings";

export const changeAdCreationStep = (dispatch, value) => {
  dispatch({
    type: EXLY_MARKETING__ADS__CHANGE_AD_CREATION_STEP,
    payload: value,
  });
};

export const CAMPAIGN_TABS = [
  { label: "Live", value: 4 },
  { label: "Drafts", value: 1 },
  { label: `Under${"\u00A0"}review`, value: 2 },
  { label: "Scheduled", value: 3 },
  { label: "Ended", value: 5 },
];

export const TABS = {
  1: { title: "Drafts", value: 1 },
  2: { title: `Under${"\u00A0"}review`, value: 2 },
  3: { title: "Scheduled", value: 3 },
  4: { title: "Live", value: 4 },
  5: { title: "Ended", value: 5 },
};

export const CREATIVES_TABS = {
  basic: { title: "Basic", value: 1, slug: "basic" },
  detailed: { title: `Detailed`, value: 2, slug: "detailed" },
  consumed: { title: `My${"\u00A0"}templates`, value: 3, slug: "consumed" },
};

export const CREATIVES_TABS_LIST = [
  { label: "Basic", value: "basic" },
  { label: `Detailed`, value: "detailed" },
  { label: `My${"\u00A0"}templates`, value: "consumed" },
];

export const PERSONA_TABS_LIST = [
  { label: `Default${"\u00A0"}Personas`, value: "default" },
  { label: `Custom${"\u00A0"}Personas`, value: "creator" },
];

export const PERSONA_TABS = {
  default: { title: `Default${"\u00A0"}Personas`, value: 1, slug: "default" },
  creator: { title: `My${"\u00A0"}Personas`, value: 2, slug: "creator" },
};

export const EDIT_CREATIVE_TABS = {
  template_content: {
    title: `Template${"\u00A0"}Content`,
    value: 1,
    slug: "template_content",
  },
  ad_content: { title: `Ad${"\u00A0"}Content`, value: 2, slug: "ad_content" },
};

export const GENDERS = {
  1: { label: `Male`, value: 1 },
  2: { label: `Female`, value: 2 },
};

export const AGE_RANGE = [20, 60];

export const STEPS = [
  {
    step: ["edit_creative", "select_creative"],
    navbar_title: "Design",
    value: "has_ad_content",
    review_title: "Ad preview",
    title: "Design your Ad template",
    completed: false,
    handleClick: (dispatch) => {
      changeAdCreationStep(dispatch, AD_CREATION_STEPS.select_creative.value);
    },
  },
  {
    step: ["select_persona"],
    navbar_title: "Persona",
    value: "has_persona",
    review_title: "Audience Persona",
    title: "Choose your audience",
    completed: false,
    handleClick: (dispatch) => {
      changeAdCreationStep(dispatch, AD_CREATION_STEPS.select_persona.value);
    },
  },
  {
    step: ["set_budget"],
    navbar_title: "Budget",
    value: "has_budget",
    review_title: "Budget",
    title: "Setup your budget",
    completed: false,
    handleClick: (dispatch) => {
      changeAdCreationStep(dispatch, AD_CREATION_STEPS.set_budget.value);
    },
  },
  {
    step: ["set_schedule"],
    navbar_title: "Schedule",
    value: "has_schedule",
    review_title: "Scheduled release",
    title: "Schedule release date & time",
    completed: false,
    handleClick: (dispatch) => {
      changeAdCreationStep(dispatch, AD_CREATION_STEPS.set_schedule.value);
    },
  },
];

export const STEPS_VALUES = {
  has_ad_content: "has_ad_content",
  has_persona: "has_persona",
  has_budget: "has_budget",
  has_schedule: "has_schedule",
};

export const AD_CREATION_STEPS = {
  select_listing: { header: "Ad Setup", value: "select_listing" },
  select_creative: { header: "Ad Setup", value: "select_creative" },
  select_persona: { header: "Ad Setup", value: "select_persona" },
  set_budget: { header: "Ad Setup", value: "set_budget" },
  set_schedule: { header: "Ad Setup", value: "set_schedule" },
  show_steps: { header: "Ad Setup", value: "show_steps" },
  edit_creative: { header: "Ad Setup", value: "edit_creative" },
  review_ad: { header: "Review your Setup", value: "review_ad" },
};

export const TEMPLATE_CONTENT_STEPS = {
  update_text: { title: "Add/Edit Text", value: "update_text", type: "text" },
  upload_image: { title: "Upload Image", value: "upload_image", type: "image" },
  edit_color_scheme: {
    title: "Edit colour scheme",
    value: "edit_color_scheme",
    type: "palatte_color",
  },
  add_stickers: {
    title: "Add stickers/banners",
    value: "add_stickers",
    type: "color",
  },
};

export const optionsForAds = (isLiveSalePage) => [
  { id: "1", label: "Offering page", value: "offering" },
  ...(!is_empty(isLiveSalePage)
    ? [{ id: "2", label: "Landing page", value: "sales_page" }]
    : []),
];
export const ADS_REDIRECTION_PAGE_VALUES = {
  offering: 1,
  sales_page: 2,
};
export const CAMPAIGN_CARD_NAME = {
  offeringAd: "Offering Page Ad",
  salesPageAd: "Landing Page Ad",
};
export const createCampaign = async (api_payload, dispatch, notify) => {
  try {
    if (
      is_empty(api_payload) ||
      (is_empty(api_payload?.listing_uid) &&
        is_empty(api_payload?.sales_page_uuid)) ||
      is_empty(api_payload?.title)
    ) {
      return notify("Invalid form values", "error");
    }
    const status = await dataProvider.custom_request(
      api.ad_creation.campaigns.create_campaign,
      "POST",
      api_payload
    );
    notify(status.message);
    if (status.status === 200) {
      dispatch({
        type: EXLY_MARKETING__ADS__SET_CAMPAIGN_UUID,
        payload: status?.data?.ad_campaign_uid,
      });
      changeAdCreationStep(dispatch, AD_CREATION_STEPS.show_steps.value);
      dispatch({
        type: EXLY_MARKETING__ADS__GET_CAMPAIGNS,
        params: { status: TABS["1"].value },
        force_update: true,
      });
      dispatch({
        type: EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
        payload: true,
      });
      dispatch({ type: EXLY_MARKETING__ADS__SET_API_PAYLOAD, payload: {} });
      dispatch({ type: EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL });
      dispatch({
        type: EXLY_MARKETING__ADS__SET_ACTIVE_EDIT_CREATIVE_TAB,
        payload: EDIT_CREATIVE_TABS.template_content.slug,
      });
    }
  } catch (err) {
    console.error("err", err);
    notify(
      err?.body?.message ? err?.body?.message : constants.error_message,
      "error"
    );
  }
};

const setCreatedPerson = ({ set_created_persona, personaId }) => {
  set_created_persona(personaId);
};

export const addPersona = async (
  api_payload,
  api_resource,
  dispatch,
  notify,
  set_created_persona,
  is_draft = false,
  is_review = false
) => {
  try {
    if (is_empty(api_payload)) {
      return notify("Invalid form values", "error");
    }
    const status = await dataProvider.custom_request(
      api_resource,
      "POST",
      api_payload
    );
    notify(status.message);
    if (status.status === 200) {
      dispatch({
        type: EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL,
        payload: { has_persona: true },
      });
      dispatch({ type: EXLY_MARKETING__ADS__SET_API_PAYLOAD, payload: {} });
      dispatch({
        type: EXLY_MARKETING__ADS__GET_PERSONAS,
        force_update: true,
        successCallback: setCreatedPerson({
          set_created_persona,
          personaId: status?.data?.persona_uid,
        }),
      });

      if (!is_draft && !is_review) {
        changeAdCreationStep(dispatch, AD_CREATION_STEPS.set_budget.value);
      } else if (is_review) {
        changeAdCreationStep(dispatch, AD_CREATION_STEPS.review_ad.value);
      }
    }
  } catch (err) {
    console.error("err", err);
    notify(
      err?.body?.message ? err?.body?.message : constants.error_message,
      "error"
    );
  }
};

export const COLOR_TYPES = { palatte: "palatte", customize: "customise" };

export const LOCATION_TYPES = {
  city: { title: "city", key: "cities" },
  region: { title: "region", key: "regions" },
  country: { title: "country", key: "countries" },
  zip: { title: "zip", key: "zips" },
  neighborhood: { title: "neighborhood", key: "neighborhoods" },
};

export const updateCampaign = async (api_payload, notify, onSuccess) => {
  try {
    if (
      is_empty(api_payload) ||
      is_empty(api_payload?.campaign_uuid) ||
      is_empty(api_payload?.updated_values)
    ) {
      return notify("Invalid form values", "error");
    }
    const status = await dataProvider.custom_request(
      `${api.ad_creation.campaigns.update_campaign}/${api_payload.campaign_uuid}`,
      "POST",
      { updated_values: api_payload.updated_values }
    );
    notify(status.message);
    if (status.status === 200) {
      onSuccess();
    }
  } catch (err) {
    console.error("err", err);
    notify(
      err?.body?.message ? err?.body?.message : constants.error_message,
      "error"
    );
  }
};

export const getSelectedCampaign = (campaign_uuid, campaigns) => {
  for (let key in campaigns) {
    for (let item of campaigns[key]) {
      if (item.uuid === campaign_uuid) {
        return item;
      }
    }
  }
};

export const getActiveCreative = (selected_creatives) => {
  for (let item of selected_creatives) {
    if (item.active) {
      return item;
    }
  }
};

export const addDesign = async (api_payload, notify, onSuccess, onFailure) => {
  try {
    if (
      is_empty(api_payload) ||
      is_empty(api_payload?.campaign_uid) ||
      is_empty(api_payload?.creatives) ||
      is_empty(api_payload?.ad_content)
    ) {
      return notify("Invalid form values", "error");
    }
    const status = await dataProvider.custom_request(
      `${api.ad_creation.campaigns.design_add}`,
      "POST",
      api_payload
    );
    notify(status.message);
    if (status.status === 200) {
      onSuccess();
    }
  } catch (err) {
    console.error("err", err);
    notify(
      err?.body?.message ? err?.body?.message : constants.error_message,
      "error"
    );
    onFailure();
  }
};

export const createOrder = async (api_payload, notify) => {
  try {
    if (is_empty(api_payload) || is_empty(api_payload?.campaign_uid)) {
      return notify("Invalid form values", "error");
    }
    const status = await dataProvider.custom_request(
      `${api.ad_creation.order.payment_initiate}`,
      "POST",
      {
        campaign_uid: api_payload?.campaign_uid,
        gateways_supported: ["NewJuspay"],
        return_url: `${window.location.origin}/#/ads?payment=true`,
      }
    );
    if (status.status === 200 && !is_empty(status?.data?.order)) {
      initiateJuspay(status.data.order);
    } else {
      notify(constants.error_message, "error");
    }
  } catch (err) {
    console.error("err", err);
    notify(
      err?.body?.message ? err?.body?.message : constants.error_message,
      "error"
    );
  }
};

export const CREATIVE_TYPES = {
  basic: 1,
  detailed: 2,
  uploaded: 3,
};

export const isDefaultCreative = (creative) => {
  return [CREATIVE_TYPES.detailed, CREATIVE_TYPES.basic].includes(
    creative?.type
  );
};

export const getStickerData = (sticker_name) => {
  const creative_stickers = {
    [marketingCreativeStickersKeys.TopLeft]: {
      component: TopLeft,
      name: marketingCreativeStickersKeys.TopLeft,
      image: "TopLeft.png",
      default_text: "50% Off!",
      text_style: {
        position: "absolute",
        top: 3,
        left: 6,
        fontWeight: 500,
        fontSize: 41,
        width: 100,
        height: 100,
      },
    },
    [marketingCreativeStickersKeys.TopRight]: {
      component: TopRight,
      name: marketingCreativeStickersKeys.TopRight,
      image: "TopRight.png",
      default_text: "50% Off!",
      text_style: {
        position: "absolute",
        top: 3,
        right: 6,
        textAlign: "right",
        fontWeight: 500,
        fontSize: 41,
        width: 100,
        height: 100,
      },
    },
    [marketingCreativeStickersKeys.Dialogue]: {
      component: Dialogue,
      name: marketingCreativeStickersKeys.Dialogue,
      image: "Dialogue.png",
      default_text: "Admissions Open",
      text_style: {
        position: "absolute",
        top: 6,
        left: 6,
        fontWeight: 600,
        fontSize: 28,
        width: 222,
        height: 120,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
    },
    [marketingCreativeStickersKeys.TopLeftCut]: {
      component: TopLeftCut,
      name: marketingCreativeStickersKeys.TopLeftCut,
      image: "TopLeftCut.png",
      default_text: "Coming Soon",
      text_style: {
        position: "absolute",
        top: 78,
        left: -3,
        fontWeight: 600,
        fontSize: 30,
        width: 244,
        height: 45,
        textAlign: "center",
        transform: "rotate(-44.93deg)",
      },
    },
    [marketingCreativeStickersKeys.RightRibbon]: {
      component: RightRibbon,
      name: marketingCreativeStickersKeys.RightRibbon,
      image: "RightRibbon.png",
      default_text: "Grab your seats",
      text_style: {
        position: "absolute",
        top: 7,
        left: 22,
        fontWeight: 600,
        fontSize: 30,
        width: 278,
        height: 48,
        textAlign: "center",
      },
    },
    [marketingCreativeStickersKeys.TopRibbon]: {
      component: TopRibbon,
      name: marketingCreativeStickersKeys.TopRibbon,
      image: "TopRibbon.png",
      default_text: "Coming Soon",
      text_style: {
        position: "absolute",
        top: 10,
        left: 6,
        textAlign: "center",
        fontWeight: 500,
        fontSize: 25,
        width: 143,
        height: 180,
      },
    },
    [marketingCreativeStickersKeys.RightPointer]: {
      component: RightPointer,
      name: marketingCreativeStickersKeys.RightPointer,
      image: "RightPointer.png",
      default_text: "Join Now!",
      text_style: {
        position: "absolute",
        top: 10,
        left: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: 500,
        fontSize: 30,
        width: 192,
        height: 108,
      },
    },
  };
  return creative_stickers[sticker_name];
};

export const MIN_DAILY_BUDGET = 100;
export const DEFAULT_DAILY_BUDGET = 1000;
export const ADS_MIN_DAYS = 7;
export const MIN_BUDGET = ADS_MIN_DAYS * MIN_DAILY_BUDGET;

export const changeActiveCreative = (index, selected_creatives, dispatch) => {
  if (is_empty(selected_creatives)) return;
  let temp = [];
  selected_creatives.forEach((creative, i) => {
    creative.active = i === index;
    temp.push({ ...creative });
  });
  dispatch({
    type: EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
    payload: temp,
  });
};

export const getPersonaReach = async (reach_payload, dispatch) => {
  try {
    const reach_status = await dataProvider.custom_request(
      `${api.ad_creation.personas.fetch_persona_reach}?target=${JSON.stringify(
        reach_payload
      )}`
    );
    if (
      reach_status.status === 200 &&
      !is_empty(reach_status?.data?.data) &&
      !is_empty(reach_status?.data?.data[0]?.estimate_mau_lower_bound)
    ) {
      const lowerBound = reach_status?.data?.data[0]?.estimate_mau_lower_bound;
      const upperBound = reach_status?.data?.data[0]?.estimate_mau_upper_bound;
      dispatch({
        type: EXLY_MARKETING__ADS__SET_PERSONA_REACH,
        payload: formatNumber(lowerBound) + " - " + formatNumber(upperBound),
      });
      dispatch({ type: EXLY_MARKETING__ADS__SET_PROCESSING, payload: false });
    }
  } catch (err) {
    console.error("err", err);
    // @dev
    // Setting the persona reach to 0 because the location is a compulsory field for the API
    // that calculates the audience size. When all locations are removed, the API returns an error,
    // so we set the audience size to 0 to handle this scenario gracefully.
    dispatch({
      type: EXLY_MARKETING__ADS__SET_PERSONA_REACH,
      payload: 0,
    });
    dispatch({ type: EXLY_MARKETING__ADS__SET_PROCESSING, payload: false });
  }
};

export const selectCreative = (creative, ads_data, notify, dispatch) => {
  const selected_creatives = ads_data?.selected_creatives;
  if (selected_creatives.length === 4) {
    return notify("Max 4 templates!", "error");
  }

  if (
    !is_empty(selected_creatives) &&
    creative.type !== selected_creatives[0].type
  ) {
    return notify(
      `Error: You can only select template(s) of the same type. In this case '${
        CREATIVES_TABS_LIST[selected_creatives[0].type - 1].label
      }'`,
      "error"
    );
  }

  let temp = selected_creatives;
  let link = getCreatorHostsiteURL().url;
  creative.index = temp.length;
  creative.active = is_empty(selected_creatives);
  temp.push({ ...creative });
  dispatch({
    type: EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
    payload: temp,
  });

  let payload = {
    name: getAuthData()?.display_name,
    message: "It’s time to get fit!",
    link: link,
    caption: link,
    description: "Fitness Home Workout",
    cta_type: CTA_OPTIONS[0].value,
  };

  // add listing link
  const selected_campiagn = getSelectedCampaign(
    ads_data?.campaign_uuid,
    ads_data?.campaigns
  );
  if (!is_empty(selected_campiagn?.ad_content?.link)) {
    payload.link = selected_campiagn?.ad_content?.link;
  }
  if (!is_empty(selected_campiagn?.ad_content?.caption)) {
    payload.caption = selected_campiagn?.ad_content?.caption;
  }

  dispatch({
    type: EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
    payload: payload,
  });
};

export const deselectCreative = (creative, selected_creatives, dispatch) => {
  let temp = selected_creatives;
  for (let i = 0; i < temp.length; i++) {
    if (temp[i].uuid === creative.uuid) {
      temp.splice(i, 1);
      break;
    }
  }
  if (!is_empty(temp)) {
    for (let i = 0; i < temp.length; i++) {
      temp[i].active = i === 0;
      temp[i].index = i;
    }
  }
  dispatch({
    type: EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
    payload: temp,
  });

  if (is_empty(temp)) {
    dispatch({
      type: EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
      payload: null,
    });
    changeAdCreationStep(dispatch, AD_CREATION_STEPS.select_creative.value);
  }
};
