export const manage_branded_community_apis = {
  get_bonus_offerings: "community/get/community/offerings",
  get_community_members: "community/member/list",
  get_community_members_purchases: "community/member/offerings",
  delete_bonus_offerings: "community/delete/community/offering",
  add_bonus_offerings: "community/add/community/offering",
  update_bonus_offerings: "community/update/community/offering",

  create_community_post: "community/create/community/post",
  update_community_post: "community/edit/community/post",
  delete_community_post: "community/delete/community/post",
  get_community_posts_list: "community/get/normal/posts",
  get_community_pinned_posts_list: "community/get/pinned/posts",
  pin_community_post: "community/pin/community/post",
  like_community_post: "community/like/community/post",
  get_community_post_liked_by_users_list: "community/post/reaction/list",
  create_community_post_comment: "community/post/comment/create",
  get_community_post_comments: "community/post/comment/list",
  like_community_post_comment: "community/post/comment/reaction/save",
  get_community_post_comment_liked_by_users_list:
    "community/post/comment/reaction/users/list",
  edit_community_post_comment: "community/post/comment/edit",
  delete_community_post_comment: "community/post/comment/delete",
  get_community_post_comment_replies: "community/post/comment/replies/list",
  cast_poll_vote: "community/poll/vote",
  get_poll_votes: "community/poll/option/voters/paginated/list",
  get_post_share_link: "community/customer/post/link/get",

  create_community_channel_post: "community/create/exly/feed/post",
  get_community_channel_posts_list: "community/feed/channel/posts/get",
  update_community_channel_post: "community/edit/feed/post",
  delete_community_channel_post: "community/delete/feed/post",
  like_community_channel_post: "community/reaction/channel/post",
  get_community_channel_post_liked_by_users_list:
    "community/list/users/feed/channel/post/reaction",
  pin_community_channel_post: "community/pin/feed/post",
  get_community_channel_post_comments: "community/list/comment/channel/post",
  create_community_channel_post_comment:
    "community/create/comment/channel/post",
  get_community_channel_post_comment_liked_by_users_list:
    "community/list/users/reaction/feed/channel/post/comment",
  like_community_channel_post_comment:
    "community/save/reaction/comment/channel/post",
  edit_community_channel_post_comment: "community/edit/comment/channel/post",
  delete_community_channel_post_comment:
    "community/delete/comment/channel/post",
  get_community_channel_post_comment_replies:
    "community/list/replies/comment/channel/post",

  check_lto_exists: "community/check/lto/exists",

  get_groups: "community/list/groups/community",
  create_groups: "community/create/groups/community",
  update_group: "community/update/groups/community",
  delete_groups: "community/delete/groups/community",
  reorder_groups: "community/reorder/groups/community",

  create_post_channel: "community/create/post/channel",
  update_post_channel: "community/update/post/channel",

  create_chat_channel: "community/create/groupchat/channel",
  update_chat_channel: "community/update/groupchat/channel",
  delete_channel: "community/delete/channel",
  add_channel_members: "community/channel/members/add",
  delete_channel_members: "community/channel/members/remove",
  get_unread_messages_count: "community/creator/directmessage/unread/count",
  get_channel_total_members: "community/channel/members/count",

  get_direct_messages_channels: "community/list/directchat/channel",
  get_direct_chat_channel_details: "community/get/directchat/channel",
  update_direct_chat_channel_details: "community/update/directchat/channel",

  channel_view_action: "community/channel/view/action",

  get_community_profile: "community/get/community/profile",
  get_channels: "community/channel/all/list",

  // custom links apis start
  create_custom_link: "community/custom/link/add",
  get_custom_links_list: "community/custom/link/list",
  edit_custom_link: "community/custom/link/edit",
  delete_custom_link: "community/custom/link/remove",
  // custom links apis end

  get_preview_links: "community/post/link/preview/list",
};
