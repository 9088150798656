import React, { useState } from "react";
import { Card } from "@my-scoot/component-library-legacy";
import styles from "./SalesPagesListItemCard.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";
import ExlyPopover from "common/Components/ExlyPopover";
import {
  sales_page_editing_stages,
  sales_page_editing_stages_keys,
} from "ui/pages/SalesPages/pages/SalesPagesHome/constants";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ExlyImage from "common/Components/ExlyImage";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
} from "constants/urlPaths";
import { useHistory } from "react-router-dom";
import { LazyLoadedShareSalePageModal } from "ui/pages/SalesPages/pages/SalesPagesHome/components/ShareSalePageModal/LazyLoadedShareSalePageModal";
import { sales_pages_apis } from "ui/pages/SalesPages/API";
import { dataProvider } from "data";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { useRefresh } from "react-admin";
import { LazyLoadedDeleteSalePageModal } from "ui/pages/SalesPages/pages/SalesPagesHome/components/DeleteSalePageModal/LazyLoadedDeleteSalePageModal";
import { getSalePageUrl } from "features/SalesPages/utils/getSalePageUrl";
import { default_template_preview_image } from "ui/pages/SalesPages/constants";
import { LazyLoadedEditSalePageTitleModal } from "ui/pages/SalesPages/pages/SalesPagesHome/components/EditSalePageTitleModal/LazyLoadedEditSalePageTitleModal";
import { offering_status_labels } from "features/Listings/Listings.constants";
import ExlyTooltip from "ui/modules/Tooltip";
import { offering_status_tooltip_texts } from "features/SalesPages/modules/SalesPagesListItemCard/constants/SalesPagesListItemCard.constants";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import { ReactComponent as TemplateSelectingStageIcon } from "ui/pages/SalesPages/assets/draftStages/TemplateSelectingStageIcon.svg";
import { ReactComponent as DetailsEditingStageIcon } from "ui/pages/SalesPages/assets/draftStages/DetailsEditingStageIcon.svg";
import { ReactComponent as PublishedStageIcon } from "ui/pages/SalesPages/assets/draftStages/PublishedStageIcon.svg";
import {
  GJS_PAGE_BUILDER_ROUTE_GENERATORS,
  GJS_PAGE_BUILDER_ROUTE_KEYS,
} from "features/SalesPages/modules/GJSSalePageBuilder/constants/GJSSalePageBuilder.constants";
import { checkPgBuilderSourceIsGJS } from "features/SalesPages/utils/checkPgBuilderSourceIsGJS";
import { sales_page_detail_keys } from "features/SalesPages/constants/SalesPages.constants";
import { logError } from "utils/error";
import LazyLoadedChangeListingModal from "../ChangeListingModal/LazyLoadedChangeListingModal";
import { useFetchListingDetails } from "features/Listings/utils/useFetchListingDetails";
import { apiMethods } from "data/api.constants";
import { MenuButtonComponent } from "./modules/MenuButtonComponent";
import { MenuPopoverComponent } from "./modules/MenuPopoverComponent/MenuPopoverComponent";
import DesktopOnlyFeatureModal from "../DesktopOnlyFeatureModal/DesktopOnlyFeatureModal";
import debounce from "lodash/debounce";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

// TODO: breakdown this file
export const SalesPagesListItemCard = ({ data, templatesList }) => {
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  const refresh = useRefresh();
  const { notify } = useNotifications();
  const { fetchListingDetails } = useFetchListingDetails();
  const { isOnboarded: hasSharedSalesPageLink, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({
      featureKey: onboardingFeatureKeys.HAS_SHARED_SALES_PAGE_LINK,
    });

  const template =
    (data.template_uid &&
      templatesList.find((i) => i.uuid === data.template_uid)) ||
    {};

  const previewImage =
    (isDesktop
      ? template.desktop_preview_image
      : template.mobile_preview_image) || default_template_preview_image;

  const salePageStage = data.sales_page_stage;
  const isSalesPageLive =
    data[sales_page_detail_keys.sales_page_stage] ===
    sales_page_editing_stages_keys.published;

  const offeringAvailabilityStatus = data.listing_status;

  const [showShareSalePageModal, setShowShareSalePageModal] = useState();
  const onShareButtonClick = () => {
    setShowShareSalePageModal(true);
  };

  const [showDeleteSalePageModal, setShowDeleteSalePageModal] = useState();
  const [showChangeOfferingModal, setShowChangeOfferingModal] = useState();
  const [salePageListingDetails, setSalePageListingDetails] = useState({});
  const onDeletePageClick = () => {
    setShowDeleteSalePageModal(true);
  };
  const [showEditSalePageTitleModal, setShowEditSalePageTitleModal] =
    useState();
  const onEditSalePageTitleClick = () => {
    setShowEditSalePageTitleModal(true);
  };

  const onChangeOfferingClick = async (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    const listingDetails = await fetchListingDetails({
      listingUuid: data.listing_uuid,
    });
    setSalePageListingDetails(listingDetails);
    setShowChangeOfferingModal(true);
  };

  const onChangeOfferingClose = () => {
    setShowChangeOfferingModal(false);
  };

  const onGoToTrafficAnalysisClick = () => {
    history.push("/" + app_route_ids[app_route_keys.traffic_analysis]);
  };

  const [duplicating, setDuplicating] = useState(false);

  const onDuplicatePageClick = debounce(() => {
    if (duplicating) return;
    setDuplicating(true);
    dataProvider
      .custom_request(sales_pages_apis.duplicate_sale_page, apiMethods.POST, {
        sales_page_uuid: data.uuid,
      })
      .then(() => {
        notify(
          "Duplicate page saved as draft",
          notification_color_keys.success
        );
        refresh();
      })
      .catch((err) => {
        logError({
          error: err,
          occuredAt:
            "src/ui/pages/SalesPages/pages/SalesPagesHome/components/SalesPagesListItemCard/SalesPagesListItemCard.jsx",
          when: "calling onDuplicatePageClick",
        });
        notify(
          "An error occured. Please try again after some time.",
          notification_color_keys.error
        );
      })
      .finally(() => setDuplicating(false));
  }, 300);

  const onCopySalePageLinkButtonClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    if (navigator?.clipboard) {
      const salePageUrl = getSalePageUrl({ salePageDetail: data });
      navigator.clipboard.writeText(salePageUrl);
      notify("Link Copied!", notification_color_keys.info);
      updateClickedOnShare();
    }
  };

  const [
    desktopOnlyFeatureModalIsOpen,
    openDesktopOnlyFeatureModal,
    closeDesktopOnlyFeatureModal,
  ] = useToggleState(false);

  const isPgBuilderSourceGJS = checkPgBuilderSourceIsGJS(
    data?.metadata?.[sales_page_detail_keys.page_builder_source] ||
      data[sales_page_detail_keys.page_builder_source]
  );

  const onEditSalesPageClick = () => {
    if (isPgBuilderSourceGJS && !isDesktop) {
      openDesktopOnlyFeatureModal();
      return;
    }

    if (salePageStage === sales_page_editing_stages_keys.selecting_template) {
      history.push(
        app_pathnames[app_route_keys.sales_pages_update_template]({
          salePageUuid: data[sales_page_detail_keys.uuid],
        })
      );
    } else if (isPgBuilderSourceGJS) {
      history.push(
        GJS_PAGE_BUILDER_ROUTE_GENERATORS[GJS_PAGE_BUILDER_ROUTE_KEYS.update]({
          salePageUuid: data[sales_page_detail_keys.uuid],
          templateUuid: data[sales_page_detail_keys.template_uid],
        })
      );
    } else {
      history.push(
        app_pathnames[app_route_keys.sales_pages_update_details]({
          salePageUuid: data.uuid,
          templateUuid: data.template_uid,
        })
      );
    }
  };

  const onPreviewSalePageBtnClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    history.push(
      "/" +
        app_pathnames[app_route_keys.sales_pages_preview]({
          salePageUuid: data.uuid,
        })
    );
  };

  const previewSalePageButtonJSX = (
    <VisibilityOutlinedIcon
      className={styles.actionBtn}
      onClick={onPreviewSalePageBtnClick}
    />
  );

  const moreOptionsJSX = (
    <div>
      <ExlyPopover
        AnchorComponent={MenuButtonComponent}
        PopoverComponent={(popoverComponentProps) => (
          <MenuPopoverComponent
            {...popoverComponentProps}
            onEditSalesPageClick={onEditSalesPageClick}
            onEditSalePageTitleClick={onEditSalePageTitleClick}
            isSalesPageLive={isSalesPageLive}
            onGoToTrafficAnalysisClick={onGoToTrafficAnalysisClick}
            onChangeOfferingClick={onChangeOfferingClick}
            onDuplicatePageClick={onDuplicatePageClick}
            onCopySalePageLinkButtonClick={onCopySalePageLinkButtonClick}
            onShareButtonClick={onShareButtonClick}
            onPreviewSalePageBtnClick={onPreviewSalePageBtnClick}
            onDeletePageClick={onDeletePageClick}
          />
        )}
        popoverProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          classes: {
            paper: styles.menuPopoverContainer,
          },
        }}
        onClose={(e) => {
          // to stop triggering card click
          if (e?.stopPropagation) e.stopPropagation();
        }}
        forceDesktopDesign={false}
      />
    </div>
  );

  const listingTitleJSX = (
    <div
      className={classnames(styles.offeringName, "ellipsis")}
      title={data.listing_title}
    >
      {data.listing_title}
    </div>
  );

  const offeringAvailabilityStatusJSX = (
    <div className={styles.offeringAvailabilityStatusCtn}>
      <div
        className={classnames(
          styles.offeringAvailabilityStatus,
          styles[`offeringAvailabilityStatus_${offeringAvailabilityStatus}`]
        )}
      >
        {offering_status_labels[offeringAvailabilityStatus]}
      </div>
      <ExlyTooltip
        text={offering_status_tooltip_texts[offeringAvailabilityStatus]}
      />
    </div>
  );

  const horizontalBarJSX = (
    <div
      className={classnames(
        isDesktop ? "px-3" : "px-2",
        "mx-1 border-top border-bottom"
      )}
    />
  );

  const iconDimensionInPx = isDesktop ? 31 : 24;

  const salePageStatusJSX = isSalesPageLive ? (
    offeringAvailabilityStatusJSX
  ) : (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-between",
        isDesktop && "flex-column"
      )}
    >
      <div className="d-flex align-items-center">
        <TemplateSelectingStageIcon
          width={iconDimensionInPx}
          height={iconDimensionInPx}
          className={classnames(
            styles.templateSelectingStageIcon,
            salePageStage === sales_page_editing_stages_keys.selecting_template
              ? styles.on
              : styles.after
          )}
        />
        {horizontalBarJSX}
        <DetailsEditingStageIcon
          width={iconDimensionInPx}
          height={iconDimensionInPx}
          className={classnames(
            styles.detailsEditingStageIcon,
            salePageStage === sales_page_editing_stages_keys.selecting_template
              ? styles.before
              : "",
            salePageStage === sales_page_editing_stages_keys.editing_details
              ? styles.on
              : ""
          )}
        />
        {horizontalBarJSX}
        <PublishedStageIcon
          width={iconDimensionInPx}
          height={iconDimensionInPx}
          className={classnames(styles.publishedStageIcon)}
        />
      </div>
      {isDesktop ? (
        <div className={styles.stageText}>
          {sales_page_editing_stages[salePageStage].stageText}
        </div>
      ) : null}
    </div>
  );

  const copySalePageLinkButtonJSX = isSalesPageLive ? (
    <LinkIcon
      className={classnames(styles.copyButtonIcon, styles.actionBtn)}
      onClick={onCopySalePageLinkButtonClick}
    />
  ) : null;

  const editSalePageButtonJSX = <EditIcon className={styles.actionBtn} />;

  const updateClickedOnShare = () => {
    if (!hasSharedSalesPageLink) {
      handleFeatureOnboardChange(true);
    }
  };

  return (
    <>
      {isDesktop ? (
        <div className={styles.container} onClick={onEditSalesPageClick}>
          <div className={classnames(styles.leftContainer, "ellipsis")}>
            <ExlyImage
              src={previewImage}
              height={66}
              width={132}
              className={styles.previewImage}
            />
            <div className={classnames(styles.titleContainer, "ellipsis")}>
              <div
                className={classnames(styles.title, "ellipsis")}
                title={data.title}
              >
                {data.title}
              </div>
              {listingTitleJSX}
            </div>
          </div>
          {salePageStatusJSX}
          <div className={styles.actionsCtn}>
            <div className={styles.immediateActionsCtn}>
              {copySalePageLinkButtonJSX}
              {editSalePageButtonJSX}
              {previewSalePageButtonJSX}
            </div>
            {moreOptionsJSX}
          </div>
        </div>
      ) : (
        <Card
          shadow="none"
          className={styles.container}
          onClick={onEditSalesPageClick}
        >
          <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
            <div className={classnames(styles.title, "ellipsis")}>
              {data.title}
            </div>
            {moreOptionsJSX}
          </div>
          <div className="m-1 p-2 d-flex">
            <div
              className={classnames(
                styles.previewImageCtn,
                "rounded border mr-3"
              )}
            >
              <ExlyImage src={previewImage} className={styles.previewImage} />
            </div>
            <div
              className={classnames(
                styles.rightContainer,
                "d-flex flex-column w-100 ellipsis"
              )}
            >
              <div className="flex-grow-1 ellipsis">
                <div
                  className={classnames(
                    styles.createdForStaticText,
                    "text-muted"
                  )}
                >
                  Created for
                </div>
                {listingTitleJSX}
              </div>
              {salePageStatusJSX}
            </div>
          </div>
        </Card>
      )}
      <LazyLoadedShareSalePageModal
        open={showShareSalePageModal}
        salePageData={data}
        onClose={() => {
          setShowShareSalePageModal(false);
        }}
      />
      <LazyLoadedDeleteSalePageModal
        open={showDeleteSalePageModal}
        salePageData={data}
        onClose={() => {
          setShowDeleteSalePageModal(false);
        }}
      />
      <LazyLoadedEditSalePageTitleModal
        open={showEditSalePageTitleModal}
        salePageData={data}
        onClose={() => {
          setShowEditSalePageTitleModal(false);
        }}
      />
      <DesktopOnlyFeatureModal
        isOpen={desktopOnlyFeatureModalIsOpen}
        onClose={closeDesktopOnlyFeatureModal}
        description="Kindly use your desktop to edit this page as it was created using the Page Builder"
      />
      <LazyLoadedChangeListingModal
        open={showChangeOfferingModal}
        salePageData={data}
        onClose={onChangeOfferingClose}
        salePageListingDetails={salePageListingDetails}
      />
    </>
  );
};
