import React from "react";
import Modal from "@mui/material/Modal";
import { classNames } from "../../../../utils/common/styling";
import { styles } from "./DesktopModal.styles";
import { IDesktopModalProps } from "../Modal.types";

const DesktopModal = ({
  children,
  isOpen,
  onClose,
  classes,
  overrideClasses,
  keepMounted,
  disableAutoFocus,
}: IDesktopModalProps) => {
  const { root: rootClass } = classes || {};
  return (
    <Modal
      open={isOpen ?? false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClose={onClose}
      classes={{
        root: classNames(styles.root, rootClass),
        ...overrideClasses,
      }}
      keepMounted={keepMounted}
      disableAutoFocus={disableAutoFocus}
    >
      {children}
    </Modal>
  );
};

export default DesktopModal;
