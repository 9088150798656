import { offering_availability_statuses } from "features/Listings/Listings.constants";

export const offering_status_tooltip_texts = {
  [offering_availability_statuses.live]:
    "The offering is live. This Landing Page will lead to a payment page.",
  [offering_availability_statuses.sold_out]:
    "The offering is in waitlist. This Landing Page will lead to a page with Waitlist form.",
  [offering_availability_statuses.expired]:
    "The offering has expired. This Landing Page will lead to a Get in Touch form page.",
};
