import { MOBILE_SCREEN_WIDTH } from "../../../constants/common/screen";
import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    invisible: {
      visibility: "hidden",
    },
    contentRoot: {
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        maxHeight: "unset",
        height: "100vh",
      },
    },
    headerRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "18px",
      fontWeight: "700",
      padding: "8px 16px",
      borderBottom: "2px solid #e7e6e4",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    close: {
      transition: "0.1s ease-in all !important",
      cursor: "pointer",
      marginLeft: "-5px",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    body: {
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        flexGrow: 1,
      },
    },
    postCreatorDetailsContainer: {
      marginBottom: "20px",
      display: "flex",
      alignItems: "center",
    },
    authorImg: {
      borderRadius: "50%",
      marginRight: "8px",
    },
    authorName: {
      fontWeight: "500",
      fontSize: "18px",
    },
    mediaSeparator: {
      marginTop: "24px",
    },
    postTypeSelectorContainer: {
      display: "flex",
      alignItems: "center",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        justifyContent: "space-around",
        marginLeft: "16px",
        marginRight: "16px",
        marginBottom: "8px",
        borderTop: "2px solid #e7e6e4",
        padding: "12px 0",
      },
      [`@media(min-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        gap: "24px",
      },
    },
    offeringContainer: {
      border: "1px solid #e0e0e0",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.12)",
      borderRadius: "6px",
    },
    offeringImage: {
      borderRadius: "6px 6px 0px 0px",
    },
    footer: {
      gap: "24px",
      justifyContent: "space-between",
    },
  },
  componentName: "EditPostModal",
});
