import React, { FC, Suspense } from "react";
import { checkIsArrayEmpty } from "../../../../../utils/common/dataTypes/array";
import { IPostDocProps } from "../../../PostDoc/PostDoc.types";

const PostDocPicker = React.lazy(() => import("./PostDocPicker"));

export const LazyLoadedPostDocPicker: FC<IPostDocProps> = (props) => {
  const { docs, showDocs } = props;

  if (!showDocs || checkIsArrayEmpty(docs)) return null;

  return (
    <Suspense fallback={null}>
      <PostDocPicker {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostDocPicker;
