import { MOBILE_SCREEN_WIDTH } from "../../../../../constants/common/screen";
import { ICreatorTheme } from "../../../../../features/Common/modules/Theme/theme.interface";
import { getColorsFromCreatorTheme } from "../../../../../features/Common/modules/Theme/utils/Theme.utils";
import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    moreButton: {
      cursor: "pointer",
    },
    moreMenuContainer: {
      display: "flex",
      flexDirection: "column",
      background: "#E7E6E4",
      gap: 1,
      "& button": {
        background: "white",
        borderRadius: 0,
        textAlign: "start",
        textTransform: "none",
        justifyContent: "flex-start",
        fontSize: "1rem",
        fontWeight: "normal",
        color: "#272522",
        padding: "8px 16px",
      },
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        minHeight: 60,
        background: "white",
        display: "block",
        "& button": {
          padding: "8px 16px",
        },
      },
    },
    divider: {
      height: 1,
      background: "#E7E6E4",
      display: "none",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        display: "block",
      },
    },
    deteleCta: {
      color: "#AA2822 !important",
    },
    reportCta: {
      color: "#FF0303 !important",
    },
    moreMenuItem: {
      cursor: "pointer",
      paddingRight: "12px",
      paddingLeft: "12px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
    },
  },
  componentName: "MoreButtonAndMenu",
});

export const getThemedStyles = ({ theme }: { theme: ICreatorTheme }) => {
  const { color1 } = getColorsFromCreatorTheme(theme);
  return getStylesObjFromCss({
    styles: {
      editIcon: {
        color: color1 || "#493ab1",
      },
      votersCta: {
        color: `${color1 || "#493ab1"} !important`,
      },
    },
    componentName: "MoreButtonAndMenu",
  });
};
