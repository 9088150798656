import React from "react";
import styles from "./EmptySalesPageView.module.css";
import ExlyImage from "common/Components/ExlyImage";

const EmptySalesPageView = () => {
  return (
    <div className={styles.Wrapper}>
      <ExlyImage
        src="/assets/vectors/SalesPage/salesPageEmpty.svg"
        alt="empty-salespg-list"
        className={styles.EmptySvg}
      />
      <div className={styles.Title}>No Landing Pages found</div>
      <div className={styles.Description}>
        There are no landing pages created for the offerings you selected
      </div>
    </div>
  );
};

export default EmptySalesPageView;
