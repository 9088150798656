import { ExlyInput, ExlyTextarea } from "common/form";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import React from "react";
import { Field } from "react-final-form";
import { FORM_VALIDATORS } from "./AdvancedDetails.utils";
import FieldsContainer from "./modules/FieldsContainer/FieldsContainer";
import GSTSection from "./modules/GSTSection/GSTSection";
import PaymentModeFields from "./modules/PaymentModeFields/PaymentModeFields";
import TagsSection from "./modules/TagsSection/TagsSection";
import { PAYOUT_DETAILS_KEYS } from "./modules/hooks/usePayoutDetails/usePayoutDetails.constants";

const AdvancedDetails = ({ payoutDetails }) => {
  const {
    [PAYOUT_DETAILS_KEYS.get_gst_info]: getGstInfo,
    [PAYOUT_DETAILS_KEYS.is_creator_gst_registered]: isGstEnabled,
  } = payoutDetails ?? {};
  const shoudlGetGstInfo = isGstEnabled && getGstInfo;

  return (
    <FieldsContainer label="Advanced Details (Optional)">
      <PaymentModeFields />

      <Field
        name={ADD_CUSTOMER_FORM_KEYS.gateway_transaction_id}
        component={ExlyInput}
        label="Transaction ID"
        placeholder="Enter transaction ID"
        fullWidth
      />

      <GSTSection show={shoudlGetGstInfo} />

      <TagsSection />

      <Field
        name={ADD_CUSTOMER_FORM_KEYS.note}
        component={ExlyTextarea}
        label="Notes"
        placeholder="Any special requests or additional services w.r.t the customer? You can mention them here"
        minWidth="100%"
        maxWidth="100%"
        minHeight="88px"
        validate={FORM_VALIDATORS[ADD_CUSTOMER_FORM_KEYS.note]}
      />
    </FieldsContainer>
  );
};

export default AdvancedDetails;
