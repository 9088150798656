import email_drip_resource_apis from "ui/pages/EmailDrip/EmailDrip.ResourceApis";
import leaderboard_resource_apis from "ui/pages/Leaderboard/Leaderboard.ResourceApis";
import leaderboard_scoring_breakdown_resource_apis from "ui/pages/Leaderboard/components/pages/Scoreboard/components/BreakdownModal/BreakdownModal.ResourceApis";
import whatsapp_broadcast_resource_apis from "ui/pages/whatsappBroadcast/WhatsappBroadcast.ResourceApis";
import { MANAG_BOOKING_RESOURCE_API } from "ui/pages/customers/ManageBookings/moduleAPI";
import manage_bonus_offerings_resource_apis from "ui/pages/ManageBrandedCommunity/ManageCommunityHome/pages/ManageBonusOfferings/ManageBonusOfferings.ResourceApis";
import members_resource_apis from "ui/pages/ManageBrandedCommunity/ManageCommunityHome/pages/ManageMembers/Members.ResourceApis";
import member_purchases_resource_apis from "ui/pages/ManageBrandedCommunity/ManageCommunityHome/pages/ManageMembers/MemberPurchasesPopup/MemberPurchasesPopup.ResourceApis";
import manage_recordings_resourse_apis from "ui/pages/schedule/ManageOffering/pages/ManageRecordings/api/ManageRecordings.ResourseApis";
import { account_resource_apis } from "ui/pages/Account/account.constants";
import { offering_variations_resource_apis } from "features/OfferingVariations/OfferingVariations.constants";
import { exly_connect_resource_apis } from "features/ExlyConnect/ExlyConnect.constants";
import booking_questions_resource_apis from "ui/pages/QuestionsPage/BookedQuestions.ResourceApis";
import { REFUND_RESOURCE_APIS } from "features/Crm/modules/Transactions/modules/Refund/Refund.constants";
import { payment_links_resource_apis } from "features/PaymentLinks/modules/PaymentLinksTransactions/constants/PaymentLinksTransactions.ResourceApis";
import { SALES_PAGES_RESOURCE_APIS } from "features/SalesPages/constants/SalesPages.constants";
import { management_list_apis } from "features/AffiliateMarketing/constants/Affliates.api";
import { CHECKOUT_PAGE_RESOURCE_APIS } from "features/CheckoutPages/constants/CheckoutPages.constants";

export const resource_apis = {
  ...email_drip_resource_apis,
  ...whatsapp_broadcast_resource_apis,
  ...MANAG_BOOKING_RESOURCE_API,
  ...leaderboard_resource_apis,
  ...leaderboard_scoring_breakdown_resource_apis,
  ...account_resource_apis,
  ...offering_variations_resource_apis,
  ...manage_recordings_resourse_apis,
  ...exly_connect_resource_apis,

  // branded community apis
  ...manage_bonus_offerings_resource_apis,
  ...members_resource_apis,
  ...member_purchases_resource_apis,

  // booking_questions
  ...booking_questions_resource_apis,
  ...REFUND_RESOURCE_APIS,

  // affliates
  ...management_list_apis,

  // payment links
  ...payment_links_resource_apis,
  ...SALES_PAGES_RESOURCE_APIS,
  ...CHECKOUT_PAGE_RESOURCE_APIS,
};
