import moment from "moment";
import { logError } from "utils/error";
import { is_empty } from "utils/validations";
import {
  CREATE_PAYMENT_LINK_FORM_PAGES,
  LINK_TYPES,
} from "../constants/CreatePaymentLinkModal.constants";
import { ICreatePaymentLinkFormHoc } from "../modules/CreatePaymentLinkFormHoc/interfaces/ICreatePaymentLinkFormHoc";
import {
  createPaymentLink,
  updatePaymentLink,
} from "./CreatePaymentLinkModal.apiCalls";
import { checkIsDefined } from "features/Common/modules/DataTypes/utils/nanNullUndef";

export const generateLinkTypeOption = ({
  title,
  icon,
  description,
  value,
}: {
  title: string;
  description: string;
  icon: string;
  value: number;
}) => {
  return {
    title,
    description,
    icon,
    value,
  };
};

export const isLinkTypeSelectionPage = (page: number) =>
  page === CREATE_PAYMENT_LINK_FORM_PAGES.LINK_TYPE_SELECTION;

export const isLinkCreationFormInputsPage = (page: number) =>
  page === CREATE_PAYMENT_LINK_FORM_PAGES.FORM_INPUTS;

export const isCustomPaymentLinkType = (type: number) =>
  type === LINK_TYPES.CUSTOM_LINK;

export const isOpenPaymentLinkType = (type: number) =>
  type === LINK_TYPES.OPEN_LINK;

export const handleDateTime = (date: string, time: string) => {
  if (is_empty(date)) return undefined;
  const _date = moment(date).format("YYYY-MM-DD");
  const _time = is_empty(time)
    ? moment().startOf("day").format("HH:mm:ss")
    : time;
  return moment(_date + " " + _time).toISOString();
};

export const handleCreatePaymentLink = async (
  formData: ICreatePaymentLinkFormHoc.IFormState,
  onSuccess?: () => void,
  // eslint-disable-next-line no-unused-vars
  onError?: (error: any) => void
) => {
  const {
    link_price: linkPrice,
    currency,
    expiry_date,
    expiry_time,
    community_channels_payload,
    payment_link_type,
    title,
    selectedListing,
    record,
  } = formData;

  const commonPayload = {
    title: title?.trim(),
    expiry_date: handleDateTime(expiry_date, expiry_time),
  };

  try {
    let paymentLink;
    let listingUuid = "";
    let dynamicLinkUuid = "";

    if (record) {
      // editing payment link
      const { dynamic_link_id: dynamicLinkId } = record;
      await updatePaymentLink(dynamicLinkId, commonPayload);
    } else if (selectedListing) {
      // creating new payment link
      const response = await createPaymentLink({
        listing_id: selectedListing.id,
        link_price: checkIsDefined(linkPrice) ? +linkPrice! : linkPrice, // link price field is not available in Open Payment Links
        currency: isOpenPaymentLinkType(payment_link_type)
          ? undefined
          : currency,
        community_channels_payload,
        payment_link_type,
        ...commonPayload,
      });
      paymentLink = response.pricingLink;
      listingUuid = response.listingUuid || "";
      dynamicLinkUuid = response.dynamicLinkUuid || "";
    }

    if (onSuccess) onSuccess();

    return { paymentLink, listingUuid, dynamicLinkUuid };
  } catch (error) {
    logError({
      error,
      occuredAt:
        "src/features/Crm/modules/PaymentLinks/modules/CreatePaymentLinkModal/modules/CreatePaymentLinkFormHoc/utils/CreatePaymentLinkFormHoc.utils.ts",
      when: "calling createPaymentLink",
      severity: "blocker",
    });

    if (onError) onError(error);

    throw error;
  }
};
