import {
  getDesktopTooltipSequenceIndex,
  getMobileTooltipSequenceIndex,
} from "../utils/Menu.utils";

export const DESKTOP_SEQUENCE_KEY = "desktopTooltipSequence";
export const MOBILE_SEQUENCE_KEY = "mobileTooltipSequence";

// UNIQUE IDS FOR ALL POSITION MENU ITEMS
export const MENU_ITEM_IDS = {
  DASHBOARD: "dashboard",
  LISTINGS: "create_edit_listing",
  STOREFRONT: "storefront",
  YOUR_WEBSITE: "your_website",
  LANDING_PAGES: "landing_pages",
  PAYMENT_PAGES: "payment_pages",
  SEO: "seo",
  SALES_PAGES: "sales",
  TRANSACTIONS: "transactions",
  EXLY_CONNECT: "exly-connect",
  BOOKINGS: "bookings",
  LEADS: "webpage_leads",
  CUSTOMERS: "all_customers",
  SEGMENTS: "all_segments",
  MY_SCHEDULE: "myschedule",
  CERTIFICATES: "certificates",
  FEEDBACKS: "exly_feedbacks",
  MANAGE_STAFF: "org_logs",
  REPORTS: "reports",
  AUTOMATED_MESSAGES: "automated_message",
  BLOG: "blogs",
  MEDIA_LIBRARY: "exly_media_library",
  INTEGRATIONS: "integrations",
  GETTING_STARTED: "getting_started",
  REFER_A_FRND: "referrals",
  USER_REFERALS: "user_referrals",
  ATTENDANCE_REGISTER: "attendance-register",
  ZOOM_ATTENDANCE: "zoom-attendance",
};

// ORDER OF TOOLTIPS TO BE SHOWN IN DESKTOP (PLEASE CHANGE THE SEQUENCE MINDFULLY)
export const DESKTOP_MENU_TOOLTIP_CONFIG = [
  MENU_ITEM_IDS.DASHBOARD,
  MENU_ITEM_IDS.LISTINGS,
  MENU_ITEM_IDS.YOUR_WEBSITE,
  MENU_ITEM_IDS.LANDING_PAGES,
  MENU_ITEM_IDS.PAYMENT_PAGES,
  MENU_ITEM_IDS.SEO,
  MENU_ITEM_IDS.SALES_PAGES,
  MENU_ITEM_IDS.TRANSACTIONS,
  MENU_ITEM_IDS.BOOKINGS,
  MENU_ITEM_IDS.LEADS,
  MENU_ITEM_IDS.CUSTOMERS,
  MENU_ITEM_IDS.SEGMENTS,
  MENU_ITEM_IDS.ZOOM_ATTENDANCE,
  MENU_ITEM_IDS.MY_SCHEDULE,
  MENU_ITEM_IDS.ATTENDANCE_REGISTER,
  MENU_ITEM_IDS.CERTIFICATES,
  MENU_ITEM_IDS.FEEDBACKS,
  MENU_ITEM_IDS.EXLY_CONNECT,
  MENU_ITEM_IDS.MANAGE_STAFF,
  MENU_ITEM_IDS.REPORTS,
  MENU_ITEM_IDS.AUTOMATED_MESSAGES,
  MENU_ITEM_IDS.BLOG,
  MENU_ITEM_IDS.MEDIA_LIBRARY,
  MENU_ITEM_IDS.INTEGRATIONS,
  MENU_ITEM_IDS.REFER_A_FRND,
  MENU_ITEM_IDS.USER_REFERALS,
  MENU_ITEM_IDS.GETTING_STARTED,
];

// ORDER OF TOOLTIPS TO BE SHOWN IN MOBILE (PLEASE CHANGE THE SEQUENCE MINDFULLY)
export const MOBILE_MENU_TOOLTIP_CONFIG = [
  MENU_ITEM_IDS.LISTINGS,
  MENU_ITEM_IDS.DASHBOARD,
  MENU_ITEM_IDS.SALES_PAGES,
  MENU_ITEM_IDS.TRANSACTIONS,
  MENU_ITEM_IDS.YOUR_WEBSITE,
  MENU_ITEM_IDS.LANDING_PAGES,
  MENU_ITEM_IDS.PAYMENT_PAGES,
  MENU_ITEM_IDS.SEO,
  MENU_ITEM_IDS.BOOKINGS,
  MENU_ITEM_IDS.LEADS,
  MENU_ITEM_IDS.CUSTOMERS,
  MENU_ITEM_IDS.SEGMENTS,
  MENU_ITEM_IDS.ZOOM_ATTENDANCE,
  MENU_ITEM_IDS.MY_SCHEDULE,
  MENU_ITEM_IDS.ATTENDANCE_REGISTER,
  MENU_ITEM_IDS.CERTIFICATES,
  MENU_ITEM_IDS.FEEDBACKS,
  MENU_ITEM_IDS.EXLY_CONNECT,
  MENU_ITEM_IDS.MANAGE_STAFF,
  MENU_ITEM_IDS.REPORTS,
  MENU_ITEM_IDS.AUTOMATED_MESSAGES,
  MENU_ITEM_IDS.BLOG,
  MENU_ITEM_IDS.MEDIA_LIBRARY,
  MENU_ITEM_IDS.INTEGRATIONS,
  MENU_ITEM_IDS.REFER_A_FRND,
  MENU_ITEM_IDS.USER_REFERALS,
  MENU_ITEM_IDS.GETTING_STARTED,
];

// TOOLTIP CONFIG FOR ALL THE MENU ITEMS
export const DASHBOARD_CONFIG = {
  id: MENU_ITEM_IDS.DASHBOARD,
  tooltipTitle:
    "Get a comprehensive view of your business with analytics and insights displayed in a single, easy-to-read dashboard.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.DASHBOARD
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.DASHBOARD),
};

export const LISTING_CONFIG = {
  id: MENU_ITEM_IDS.LISTINGS,
  tooltipTitle:
    "Create, edit, and manage your listings effortlessly. Keep your offerings up-to-date and organized.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.LISTINGS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.LISTINGS),
};

export const WEBSITE_CONFIG = {
  id: MENU_ITEM_IDS.YOUR_WEBSITE,
  tooltipTitle:
    "Design, edit, and manage your website to ensure it perfectly represents your brand and offerings.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.YOUR_WEBSITE
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.YOUR_WEBSITE
  ),
};

export const LANDING_PAGES_CONFIG = {
  id: MENU_ITEM_IDS.LANDING_PAGES,
  tooltipTitle:
    "Design, edit and manage high-impact landing pages tailored for marketing your offerings and driving conversions",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.LANDING_PAGES
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.LANDING_PAGES
  ),
};

export const PAYMENT_PAGES_CONFIG = {
  id: MENU_ITEM_IDS.PAYMENT_PAGES,
  tooltipTitle:
    "Create custom checkout pages for your offerings to provide a smooth and secure checkout experience for your customers.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.PAYMENT_PAGES
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.PAYMENT_PAGES
  ),
};

export const SEO_CONFIG = {
  id: MENU_ITEM_IDS.SEO,
  tooltipTitle:
    "Boost trust and visibility by optimizing SEO and tracking performance to enhance engagement and discoverability.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(MENU_ITEM_IDS.SEO),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.SEO),
};

export const SALES_PAGES_CONFIG = {
  id: MENU_ITEM_IDS.SALES_PAGES,
  tooltipTitle:
    "Access powerful sales and marketing tools, including discount codes, landing pages, limited-time offers, email campaigns, and WhatsApp campaigns.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.SALES_PAGES
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.SALES_PAGES
  ),
};

export const TRANSACTIONS_CONFIG = {
  id: MENU_ITEM_IDS.TRANSACTIONS,
  tooltipTitle:
    "View and manage all transactions across all your listings in one place for seamless financial tracking.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.TRANSACTIONS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.TRANSACTIONS
  ),
};

export const EXLY_CONNECT_CONFIG = {
  id: MENU_ITEM_IDS.EXLY_CONNECT,
  tooltipTitle:
    "View and access all your scheduled live sessions for classes, workshops, and appointments conducted on Zoom via Exly.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.EXLY_CONNECT
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.EXLY_CONNECT
  ),
};

export const BOOKINGS_CONFIG = {
  id: MENU_ITEM_IDS.BOOKINGS,
  tooltipTitle:
    "View and manage all bookings across your listings to stay on top of your schedule.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.BOOKINGS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.BOOKINGS),
};

export const LEADS_CONFIG = {
  id: MENU_ITEM_IDS.LEADS,
  tooltipTitle:
    "See all leads collected from various mediums in one place, making it easy to follow up and convert.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(MENU_ITEM_IDS.LEADS),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.LEADS),
};

export const CUSTOMERS_CONFIG = {
  id: MENU_ITEM_IDS.CUSTOMERS,
  tooltipTitle:
    "Access and manage all your customers across all listings, keeping your customer database organized and up-to-date.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.CUSTOMERS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.CUSTOMERS),
};

export const SEGMENTS_CONFIG = {
  id: MENU_ITEM_IDS.SEGMENTS,
  tooltipTitle:
    "Create new customer segments or manage existing ones to tailor your marketing efforts effectively.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.SEGMENTS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.SEGMENTS),
};

export const MY_SCHEDULE_CONFIG = {
  id: MENU_ITEM_IDS.MY_SCHEDULE,
  tooltipTitle:
    "View and manage all your scheduled slots for classes, workshops, and appointments in your calendar.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.MY_SCHEDULE
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.MY_SCHEDULE
  ),
};

export const CERTIFICATES_CONFIG = {
  id: MENU_ITEM_IDS.CERTIFICATES,
  tooltipTitle:
    "Design new certificates and manage existing ones to reward and recognize your customers' achievements.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.CERTIFICATES
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.CERTIFICATES
  ),
};

export const FEEDBACKS_CONFIG = {
  id: MENU_ITEM_IDS.FEEDBACKS,
  tooltipTitle:
    "View all collected feedback from customers across all listings to gain insights and improve your services.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.FEEDBACKS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.FEEDBACKS),
};

export const MANAGE_STAFF_CONFIG = {
  id: MENU_ITEM_IDS.MANAGE_STAFF,
  tooltipTitle:
    "Add new staff members and manage permissions and roles of existing staff to streamline team collaboration.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.MANAGE_STAFF
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.MANAGE_STAFF
  ),
};

export const REPORTS_CONFIG = {
  id: MENU_ITEM_IDS.REPORTS,
  tooltipTitle:
    "Generate detailed reports on transactions, bookings, and more to analyze and optimize your business performance.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(MENU_ITEM_IDS.REPORTS),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.REPORTS),
};

export const AUTOMATED_MESSAGES_CONFIG = {
  id: MENU_ITEM_IDS.AUTOMATED_MESSAGES,
  tooltipTitle:
    "View, edit, and manage all automated messages sent to your customers and yourself for effective communication.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.AUTOMATED_MESSAGES
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.AUTOMATED_MESSAGES
  ),
};

export const BLOG_CONFIG = {
  id: MENU_ITEM_IDS.BLOG,
  tooltipTitle:
    "Publish new blogs and manage existing ones to keep your audience engaged and informed.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(MENU_ITEM_IDS.BLOG),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(MENU_ITEM_IDS.BLOG),
};

export const MEDIA_LIBRARY_CONFIG = {
  id: MENU_ITEM_IDS.MEDIA_LIBRARY,
  tooltipTitle:
    "Upload and manage media such as videos, images, and files in our unlimited storage media library.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.MEDIA_LIBRARY
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.MEDIA_LIBRARY
  ),
};

export const INTEGRATIONS_CONFIG = {
  id: MENU_ITEM_IDS.INTEGRATIONS,
  tooltipTitle:
    "Integrate external plugins with Exly to streamline your workflow and enhance functionality.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.INTEGRATIONS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.INTEGRATIONS
  ),
};

export const GETTING_STARTED_CONFIG = {
  id: MENU_ITEM_IDS.GETTING_STARTED,
  tooltipTitle:
    "Easily navigate back to your setup section through Getting Started for quick access and reference.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.GETTING_STARTED
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.GETTING_STARTED
  ),
};

export const REFER_A_CONFIG = {
  id: MENU_ITEM_IDS.REFER_A_FRND,
  tooltipTitle:
    "Refer Exly to your friends and get 50% of the revenue. Track all your referrals from one place including referral signups and earnings.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.REFER_A_FRND
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.REFER_A_FRND
  ),
};

export const USER_REFERALS_CONFIG = {
  id: MENU_ITEM_IDS.USER_REFERALS,
  tooltipTitle:
    "Allow your customers to refer you to their friends and increase sales. Track all referrals from one place.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.USER_REFERALS
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.USER_REFERALS
  ),
};

export const ATTENDANCE_REGISTER_CONFIG = {
  id: MENU_ITEM_IDS.ATTENDANCE_REGISTER,
  tooltipTitle:
    "View and manage attendance for each session by marking attendance for individual customers.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.ATTENDANCE_REGISTER
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.ATTENDANCE_REGISTER
  ),
};

export const ZOOM_ATTENDANCE_CONFIG = {
  id: MENU_ITEM_IDS.ZOOM_ATTENDANCE,
  tooltipTitle:
    "Track and manage attendance for your Zoom sessions with seamless and automatic attendance logging per session.",
  [DESKTOP_SEQUENCE_KEY]: getDesktopTooltipSequenceIndex(
    MENU_ITEM_IDS.ZOOM_ATTENDANCE
  ),
  [MOBILE_SEQUENCE_KEY]: getMobileTooltipSequenceIndex(
    MENU_ITEM_IDS.ZOOM_ATTENDANCE
  ),
};

// OBJECT FOR TOOLTIP VALUES CONFIG FOR EACH MENU ITEM
// TO ADD A NEW ITEM IN MENU , ADD THE CONFIG HERE
export const MENU_ITEM_VALUES_CONFIG = {
  [MENU_ITEM_IDS.DASHBOARD]: DASHBOARD_CONFIG,
  [MENU_ITEM_IDS.LISTINGS]: LISTING_CONFIG,
  [MENU_ITEM_IDS.YOUR_WEBSITE]: WEBSITE_CONFIG,
  [MENU_ITEM_IDS.LANDING_PAGES]: LANDING_PAGES_CONFIG,
  [MENU_ITEM_IDS.PAYMENT_PAGES]: PAYMENT_PAGES_CONFIG,
  [MENU_ITEM_IDS.SEO]: SEO_CONFIG,
  [MENU_ITEM_IDS.SALES_PAGES]: SALES_PAGES_CONFIG,
  [MENU_ITEM_IDS.TRANSACTIONS]: TRANSACTIONS_CONFIG,
  [MENU_ITEM_IDS.EXLY_CONNECT]: EXLY_CONNECT_CONFIG,
  [MENU_ITEM_IDS.BOOKINGS]: BOOKINGS_CONFIG,
  [MENU_ITEM_IDS.LEADS]: LEADS_CONFIG,
  [MENU_ITEM_IDS.CUSTOMERS]: CUSTOMERS_CONFIG,
  [MENU_ITEM_IDS.SEGMENTS]: SEGMENTS_CONFIG,
  [MENU_ITEM_IDS.MY_SCHEDULE]: MY_SCHEDULE_CONFIG,
  [MENU_ITEM_IDS.CERTIFICATES]: CERTIFICATES_CONFIG,
  [MENU_ITEM_IDS.FEEDBACKS]: FEEDBACKS_CONFIG,
  [MENU_ITEM_IDS.MANAGE_STAFF]: MANAGE_STAFF_CONFIG,
  [MENU_ITEM_IDS.REPORTS]: REPORTS_CONFIG,
  [MENU_ITEM_IDS.AUTOMATED_MESSAGES]: AUTOMATED_MESSAGES_CONFIG,
  [MENU_ITEM_IDS.BLOG]: BLOG_CONFIG,
  [MENU_ITEM_IDS.MEDIA_LIBRARY]: MEDIA_LIBRARY_CONFIG,
  [MENU_ITEM_IDS.INTEGRATIONS]: INTEGRATIONS_CONFIG,
  [MENU_ITEM_IDS.GETTING_STARTED]: GETTING_STARTED_CONFIG,
  [MENU_ITEM_IDS.REFER_A_FRND]: REFER_A_CONFIG,
  [MENU_ITEM_IDS.USER_REFERALS]: USER_REFERALS_CONFIG,
  [MENU_ITEM_IDS.ZOOM_ATTENDANCE]: ZOOM_ATTENDANCE_CONFIG,
  [MENU_ITEM_IDS.ATTENDANCE_REGISTER]: ATTENDANCE_REGISTER_CONFIG,
};

// PARENT MENU KEYS FOR MENU
export const TOOGLE_PARENT_MENU_KEYS = {
  CONTACTS: "menuCustomers",
  STOREFRONT: "menuStorefront",
  WORKFLOW_TOOLS: "menuWorkflowTools",
  EXLY_PLUS: "menuExlyPlus",
};

// SUB MENU CONFIG PER PARENT MENU
export const SUB_MENU_CONFIG = {
  [TOOGLE_PARENT_MENU_KEYS.CONTACTS]: [
    MENU_ITEM_IDS.BOOKINGS,
    MENU_ITEM_IDS.LEADS,
    MENU_ITEM_IDS.CUSTOMERS,
    MENU_ITEM_IDS.SEGMENTS,
  ],
  [TOOGLE_PARENT_MENU_KEYS.STOREFRONT]: [
    MENU_ITEM_IDS.YOUR_WEBSITE,
    MENU_ITEM_IDS.LANDING_PAGES,
    MENU_ITEM_IDS.PAYMENT_PAGES,
    MENU_ITEM_IDS.SEO,
  ],
  [TOOGLE_PARENT_MENU_KEYS.WORKFLOW_TOOLS]: [
    MENU_ITEM_IDS.ZOOM_ATTENDANCE,
    MENU_ITEM_IDS.MY_SCHEDULE,
    MENU_ITEM_IDS.ATTENDANCE_REGISTER,
    MENU_ITEM_IDS.CERTIFICATES,
    MENU_ITEM_IDS.FEEDBACKS,
    MENU_ITEM_IDS.EXLY_CONNECT,
    MENU_ITEM_IDS.MANAGE_STAFF,
    MENU_ITEM_IDS.REPORTS,
    MENU_ITEM_IDS.AUTOMATED_MESSAGES,
    MENU_ITEM_IDS.BLOG,
    MENU_ITEM_IDS.MEDIA_LIBRARY,
  ],
  [TOOGLE_PARENT_MENU_KEYS.EXLY_PLUS]: [
    MENU_ITEM_IDS.REFER_A_FRND,
    MENU_ITEM_IDS.USER_REFERALS,
  ],
};

export const MENU_SCROLLABLE_DIV_ID = "scroll-menu";
export const TRIGGER_RESIZE_THROTTLE_TIME = 300;
