import { Collapse } from "@material-ui/core";
import DownIcon from "@material-ui/icons/ArrowDropDownRounded";
import classnames from "classnames";
import React from "react";
import { useToggleState } from "utils/hooks";
import styles from "./FieldsContainer.module.css";

const FieldsContainer = ({
  label,
  classes = {},
  defaultExpanded = false,
  children,
}) => {
  const [isExpanded, , , toggleCollapse] = useToggleState(defaultExpanded);

  return (
    <div className={styles.root}>
      <div className={styles.header} onClick={toggleCollapse}>
        {label}
        <DownIcon
          className={classnames(
            styles.chevronDown,
            isExpanded && styles.chevronDownActive
          )}
        />
      </div>

      <Collapse in={isExpanded}>
        <div className={classnames(styles.body, classes.body)}>{children}</div>
      </Collapse>
    </div>
  );
};

export default FieldsContainer;
