import React, { useEffect, useState } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import classnames from "classnames";
import {
  app_route_ids,
  app_route_keys,
  app_route_titles,
} from "constants/urlPaths";
import { create_a_sales_page_cta_text } from "./SalesPagesHome.constants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import styles from "./SalesPagesHome.module.css";
import { useListController } from "react-admin";
import { Tabs } from "@my-scoot/component-library-legacy";
import ExlyTableFilters from "common/Components/ExlyTable/ExlyTableFilters";
import { is_empty } from "utils/validations";
import { MobileFooter } from "common/Components/MobileFooter";
import { SalesPagesListItemCard } from "features/SalesPages/modules/SalesPagesListItemCard/SalesPagesListItemCard";
import {
  default_sales_page_stage_id,
  filterChipFormatter,
  filterConfig,
  sales_page_home_tabs,
  sales_page_home_tabs_list,
} from "./constants";
import { SalesPagesListItemCardShimmer } from "features/SalesPages/modules/SalesPagesListItemCard/modules/SalesPagesListItemCardShimmer";
import { useFetchSalesPageTemplatesList } from "../../utils";
import { Pagination } from "common/Components/Pagination";
import { Header } from "common/Components/Header";
import { getParamValueFromRouteProps } from "utils/Utils";
import ExlyFilteredEmptyState from "common/Components/ExlyTable/ExlyTableFilters/ExlyTableFilterEmptyState";
import { sales_page_home_stage_filter_key } from "features/SalesPages/constants/SalesPages.constants";
import useHideNavAndAppBar from "hooks/useHideNavAndAppBar";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { SALES_PAGE_LEARN_MORE_HREFS } from "../../constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import EmptyState from "./components/EmptyState/EmptyState";

const SalesPagesHome = (props) => {
  const history = props.history;
  const defaultSalesPageStageFilterValue =
    JSON.parse(getParamValueFromRouteProps(props, "filter") || "{}")[
      sales_page_home_stage_filter_key
    ] ?? default_sales_page_stage_id;
  const listControllerData = useListController({
    ...props,
    filterDefaultValues: {
      [sales_page_home_stage_filter_key]: defaultSalesPageStageFilterValue,
    },
  });
  const { data, loading, page, total, setPage, setFilters, filterValues } =
    listControllerData;

  const [loadingWithDelay, setLoadingWithDelay] = useState();

  useEffect(() => {
    if (loading) {
      setLoadingWithDelay(true);
    } else {
      setTimeout(() => {
        setLoadingWithDelay(false);
      }, 300);
    }
  }, [loading]);

  const dataList = Object.keys(data).map((i) => data[i]);

  const isDesktop = useDesktopMediaQuery();

  const handleCreateSalesPage = () => {
    history.push(app_route_ids[app_route_keys.sales_pages_create]);
  };

  const CreateSalesPageButton = (props) => (
    <Button onClick={handleCreateSalesPage} {...props}>
      {create_a_sales_page_cta_text}
    </Button>
  );

  const [currentTab, setCurrentTab] = useState(
    sales_page_home_tabs[
      filterValues[sales_page_home_stage_filter_key] ??
        defaultSalesPageStageFilterValue
    ].priority
  );

  const handleTabChange = (e, index) => {
    const newVal = sales_page_home_tabs_list[index].id;
    if (currentTab !== index) {
      setFilters({ [sales_page_home_stage_filter_key]: newVal });
      setCurrentTab(index);
    }
  };

  const { templatesList } = useFetchSalesPageTemplatesList();

  const onBackClick = () => {
    history.push(app_route_ids[app_route_keys.app_home]);
  };

  useHideNavAndAppBar(
    app_route_titles[app_route_keys.sales_pages_home],
    onBackClick
  );
  useAppLayoutControl({
    values: { learnMoreHref: SALES_PAGE_LEARN_MORE_HREFS.SALES_PAGE },
  });

  const {
    // eslint-disable-next-line no-unused-vars
    [sales_page_home_stage_filter_key]: salesPageStage,
    ...filterValuesWOSalesPageStage
  } = filterValues;
  const hasFilterValuesWOStatusList = !is_empty(
    Object.keys(filterValuesWOSalesPageStage)
  );

  return (
    <div className={`dm_font`}>
      {isDesktop ? (
        <Header
          title={app_route_titles[app_route_keys.sales_pages_home]}
          classNames={{
            container: classnames(
              "component-wrapper",
              isDesktop ? "border-bottom-0" : ""
            ),
          }}
          onBackClick={onBackClick}
          hideBackButton={isDesktop}
          right={
            <div className="d-flex">
              <LearnMoreCta
                href={SALES_PAGE_LEARN_MORE_HREFS.SALES_PAGE}
                className="mr-3"
              />
              {isDesktop ? <CreateSalesPageButton /> : null}
            </div>
          }
        />
      ) : null}
      <div className="component-wrapper">
        <Tabs
          tabs={sales_page_home_tabs_list}
          value={currentTab}
          onChange={handleTabChange}
          classes={{ root: "mb-3" }}
        />
        {!is_empty(dataList) ||
        hasFilterValuesWOStatusList ||
        loadingWithDelay ? (
          <>
            <ExlyTableFilters
              filters={filterConfig}
              tableChipsFormatter={filterChipFormatter}
              setFilters={(value) => {
                const salesPageStage = sales_page_home_tabs_list[currentTab].id;
                setFilters(
                  Object.assign(value, {
                    [sales_page_home_stage_filter_key]: salesPageStage,
                  })
                );
              }}
              filterValues={filterValues}
              isDesktop={isDesktop}
              noExportButton
              className="mb-3"
            />
            {!loadingWithDelay && is_empty(dataList) ? (
              <ExlyFilteredEmptyState />
            ) : null}
            <div className={styles.cardsContainer}>
              {loadingWithDelay
                ? [1, 2, 3, 4].map((i) => (
                    <SalesPagesListItemCardShimmer key={i} />
                  ))
                : null}
              {!loadingWithDelay && !is_empty(dataList)
                ? dataList.map((i) => (
                    <SalesPagesListItemCard
                      key={i.uuid}
                      data={i}
                      templatesList={templatesList}
                    />
                  ))
                : null}
            </div>
          </>
        ) : null}

        {!loadingWithDelay &&
        is_empty(dataList) &&
        !hasFilterValuesWOStatusList ? (
          <EmptyState
            title={sales_page_home_tabs_list[currentTab].emptyTitle}
            description={sales_page_home_tabs_list[currentTab].emptySubtitle}
            videoSrc={SALES_PAGE_LEARN_MORE_HREFS.SALES_PAGE_VIDEO}
            primaryCtaProps={{
              title: "Create a Landing Page",
              onClick: handleCreateSalesPage,
            }}
            secondaryCtaProps={{
              variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
              learnMoreHref: SALES_PAGE_LEARN_MORE_HREFS.SALES_PAGE,
            }}
          />
        ) : null}
        <div className={"py-3"} />
        <Pagination page={page} onPageChange={setPage} total={total} />
        <div className={isDesktop ? "" : "my-4 py-3"} />
      </div>
      <MobileFooter>
        <CreateSalesPageButton fullWidth />
      </MobileFooter>
    </div>
  );
};

export default withComponentLibraryTheme(SalesPagesHome);
